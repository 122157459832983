import { FormGroup } from "@angular/forms";
import { Account, ExternalUser, InternalUser, ReferenceItem, SmartCode } from "../data-objects";
import { ExternalUserRoles, InternalUserRoles } from "../contants";
import { ElementRef } from "@angular/core";
import { HttpParams } from "@angular/common/http";


export function ShowValidationErrorCheck(formControlName: string, validation: string, form: FormGroup): boolean {
    const control = form.get(formControlName);
    if (control == null || control == undefined)
        return false;

    if (control.errors) {
        const isCustom = Object.keys(control.errors).find(k => k == "validationFieldName") != undefined;

        if (isCustom == true) {
            const errorFieldName = control.errors["validationFieldName"] as string;

            return (control.dirty || control.touched) && errorFieldName == validation;
        }
    }

    return control.errors?.[validation] && (control.dirty || control.touched);
}

export function getMentorType(user: InternalUser | null): string | null {
    return user?.roleAssignments.find(i => InternalUserRoles.getMentorRoles().includes(i.internalRole?.name ?? ''))?.internalRole?.name ?? null;
}

export function getTimeZoneById(timeZones: ReferenceItem[] | null, timeZoneId: string | null) {
    return timeZoneId != null ? timeZones?.find(i => i.id == timeZoneId)?.description ?? null : null;
}

export function getTimeZoneAbbreviationById(timeZones: ReferenceItem[] | null, timeZoneId: string | null): string | null {
    return timeZoneId != null ? timeZones?.find(i => i.id == timeZoneId)?.additionalData["abbreviation"] ?? null : null;
}

export function getTimeZoneOffsetById(timeZones: ReferenceItem[] | null, timeZoneId: string | null): number {
    return timeZoneId != null ? timeZones?.find(i => i.id == timeZoneId)?.additionalData["offset"] ?? 0 : 0;
}

export function selectFile(event: Event, selectedFiles: File[]): void {
    const element = event.currentTarget as HTMLInputElement;
    const files: FileList | null = element.files;

    if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const index = selectedFiles.findIndex(i => i.name === file.name);

            if (index !== -1) {
                selectedFiles.splice(index, 1);
            }

            selectedFiles.push(file);
        }
    }
}

export function removeFile(file: File, selectedFiles: File[], fileInput: ElementRef<any> | null) {
    if (fileInput) {
        const index = selectedFiles.findIndex(i => i.name === file.name);
        if (index !== -1) {
            selectedFiles.splice(index, 1);
        }

        fileInput.nativeElement.value = null;
    }
}

export function getUserType(user: ExternalUser | null, userTypes: Record<string, SmartCode>) {
    if (user == null) {
        return '';
    }

    if (userTypes[user.userTypeId].code === 'O') {
        return user.otherUserType;
    } else {
        return userTypes[user.userTypeId].label;
    }
}

export function getDataSourceLoaderParams(loadOptions: any) {
    function isNotEmpty(value: any): boolean {
        return value !== undefined && value !== null && value !== '';
    };

    let params: HttpParams = new HttpParams();
    [
        'skip',
        'take',
        'requireTotalCount',
        'requireGroupCount',
        'sort',
        'filter',
        'totalSummary',
        'group',
        'groupSummary',
    ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) { params = params.set(i, JSON.stringify(loadOptions[i])); }
    });

    return params;
}

export function getAccountCreator(account: Account | null): ExternalUser | null {
    return account?.accountAccesses.find(i => ExternalUserRoles.AccountCreator == i.externalRole?.name ?? '')?.externalUser ?? null;
}

export function getMentee(account: Account | null): ExternalUser | null {
    return account?.accountAccesses.find(i => ExternalUserRoles.Mentee == i.externalRole?.name ?? '')?.externalUser ?? null;
}

export function adjustDateByTimeZone(date: Date, timeZone: string, timeZones: ReferenceItem[]): Date {
    var offset = timeZones.find(i => i.id == timeZone)?.additionalData["offset"];
    return date.addMinutes(offset);
}
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG } from '@mya/configuration';
import { AddSupportUserToAccountRequest, AddSupportUserToAccountResult, EditExternalUserRequest, EditExternalUserResult } from '@mya/models';
import { catchError, Observable } from 'rxjs';
import { LoaderService } from './loader.service';
declare const toastr: any;

@Injectable({
  providedIn: 'root'
})
export class ExternalUserService {
  baseApiUrl = '';

  constructor(private http: HttpClient, private router: Router, private loaderService: LoaderService,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.externalUser}api/externalUser`
  }

  public addSupportUserToAccount(request: AddSupportUserToAccountRequest, loaderIdentifier: string): Observable<AddSupportUserToAccountResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post<AddSupportUserToAccountResult>(`${this.baseApiUrl}/add-support-user-to-account`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        if (error.status == HttpStatusCode.Conflict) {
          toastr.error(error.error);
        }
        throw error;
      }));
  }

  public deleteSupportUserFromAccount(userId: string, loaderIdentifier: string): Observable<any> {
    this.loaderService.show(loaderIdentifier);
    return this.http.delete<any>(`${this.baseApiUrl}/delete-support-user-from-account/${userId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        if (error.status == HttpStatusCode.Conflict) {
          toastr.error(error.error);
        }
        throw error;
      }));
  }

  public editExternalUser(request: EditExternalUserRequest, loaderIdentifier: string): Observable<EditExternalUserResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post<EditExternalUserResult>(`${this.baseApiUrl}/edit-external-user`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        if (error.status == HttpStatusCode.Conflict) {
          toastr.error(error.error);
        }
        throw error;
      }));
  }
}

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { SmartTypesService } from '../../../../services/smart-types.service';
import { Account, ChangePlanForAccountRequest, PlanPricing, SmartCode, SmartTypes } from '@mya/models';
import { v4 as uuid } from 'uuid';
import { LoaderService } from '../../../../services/loader.service';
import { AccountService } from '../../../../services/account.service';
import { PricingService } from 'apps/support/src/app/services/pricing.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-plan-selection',
  templateUrl: './plan-selection.component.html',
  styleUrls: ['./plan-selection.component.scss'],
})
export class PlanSelectionComponent implements OnInit, OnDestroy {
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  subscriptions: Subs[] = [];
  selectedPlanId: string | null = null;
  linkedAccount: Account | null = null;
  planPricings: PlanPricing[] = [];
  plans: SmartCode[] = [];

  get selectedPlan() {
    return this.plans.find(i => i.id == this.selectedPlanId) ?? null;
  }

  get availablePlans() {
    const planIds = this.planPricings.filter(i => i.isOnboardingPlan).map(i => i.mentoringPlanId);
    return this.plans.filter(i => planIds.findIndex(x => x == i.id) != -1);
  }

  constructor(private accountService: AccountService,
    private smartTypesService: SmartTypesService,
    private loaderService: LoaderService,
    private pricingService: PricingService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(i => {
      this.linkedAccount = i;
      if (this.linkedAccount?.mentoringPlanId) {
        this.selectedPlanId = this.linkedAccount.mentoringPlanId;
      }
    }));
    this.subscriptions.push(this.pricingService.PlanPricing$.subscribe(i => {
      this.planPricings = i;
    }));

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.MentoringPlan, loaderIdentifier).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier);
      smartCodes?.forEach(smartCode => {
        if (smartCode.code !== 'OP') {
          this.plans.push(smartCode);
          if (this.selectedPlanId == null) {
            this.selectedPlanId = smartCode.id;
          }
        }
      });
    }));
  }

  planSelected(planId: string) {
    this.selectedPlanId = planId;
  }

  back() {
    this.previous.emit();
  }

  submit() {
    if (this.selectedPlan) {
      const request: ChangePlanForAccountRequest = {
        planId: this.selectedPlan.id
      };

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.accountService.changePlanForAccount(request, loaderIdentifier).subscribe(() => {
        this.accountService.getAccount(true);
        this.loaderService.hide(loaderIdentifier);
        this.next.emit();
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

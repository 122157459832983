import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { GetParentTimePricingResult, GetPlanPricingsResult, ParentTimePricing, PlanPricing } from '@mya/models';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { LoaderService } from './loader.service';
import { APP_CONFIG } from '@mya/configuration';
import { v4 as uuid } from 'uuid';
import { Subscription as Subs } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PricingService implements OnDestroy {
  baseApiUrl = '';
  subscriptions: Subs[] = [];

  private readonly planPricing = new BehaviorSubject<PlanPricing[]>([]);
  public readonly PlanPricing$: Observable<PlanPricing[]> = this.planPricing;
  private readonly parentTimePricing = new BehaviorSubject<ParentTimePricing[]>([]);
  public readonly ParentTimePricing$: Observable<ParentTimePricing[]> = this.parentTimePricing;

  constructor(private http: HttpClient, private loaderService: LoaderService,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.pricing}api/pricing`
  }
  
  public fetchPlanPricings() {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetPlanPricingsResult>(`${this.baseApiUrl}/get-plan-pricing`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe((response) => {
        this.loaderService.hide(loaderIdentifier);
        this.planPricing.next(response.planPricings);
      }));
  }

  public fetchParentTimePricing() {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetParentTimePricingResult>(`${this.baseApiUrl}/get-parent-time-pricing`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe((response) => {
        this.loaderService.hide(loaderIdentifier);
        this.parentTimePricing.next(response.prices);
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

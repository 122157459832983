<div class="container-fluid px-5 pt-5 h-100 mya-notes overflow-y-auto">
  <div class="row h-100">
    <div class="col-12 mb-3 d-flex">
      <h1>Notes</h1>
    </div>
    <div class="col-12 p-0 tabs-container">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="all-notes-tab" data-bs-toggle="tab" data-bs-target="#all-notes"
            type="button" role="tab" aria-controls="all-notes" aria-selected="true"
            (click)="selectHistoryType(null)"><b>All
              Notes</b></button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="parent-notes-tab" data-bs-toggle="tab" data-bs-target="#parent-notes"
            type="button" role="tab" aria-controls="parent-notes" aria-selected="false"
            (click)="selectHistoryType(HistoryTypeEnum.Parent)"><b>Parent
              Notes</b></button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="quote-tab" data-bs-toggle="tab" data-bs-target="#quote" type="button" role="tab"
            aria-controls="quote" aria-selected="false"
            (click)="selectHistoryType(HistoryTypeEnum.Quote)"><b>Quote</b></button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="gcs-tab" data-bs-toggle="tab" data-bs-target="#gcs" type="button" role="tab"
            aria-controls="gcs" aria-selected="false"
            (click)="selectHistoryType(HistoryTypeEnum.Gcs)"><b>GCS</b></button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="col-tab" data-bs-toggle="tab" data-bs-target="#col" type="button" role="tab"
            aria-controls="col" aria-selected="false"
            (click)="selectHistoryType(HistoryTypeEnum.Col)"><b>COL</b></button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="som-tab" data-bs-toggle="tab" data-bs-target="#som" type="button" role="tab"
            aria-controls="som" aria-selected="false"
            (click)="selectHistoryType(HistoryTypeEnum.Som)"><b>SOM</b></button>
        </li>
      </ul>
      <div class="tab-content p-2">
        <div class="tab-pane fade show active p-3" id="all-notes" role="tabpanel" aria-labelledby="all-notes-tab">
          <div class="row" *ngIf="selectedHistoryType === null">
            <ng-container *ngFor="let historyItem of filteredHistoryItems">
              <div class="col-md-4 pb-2">
                <mya-note-card [historyItem]="historyItem" (viewHistoryItem)="viewNote($event)"></mya-note-card>
              </div>
            </ng-container>
            <div class="col-md-12" *ngIf="filteredHistoryItems.length === 0">
              <div class="no-records-card">
                <h4 class="py-3 text-center">
                  No Records
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade p-3" id="parent-notes" role="tabpanel" aria-labelledby="parent-notes-tab">
          <div class="row" *ngIf="selectedHistoryType === HistoryTypeEnum.Parent">
            <ng-container *ngFor="let historyItem of filteredHistoryItems">
              <div class="col-md-4 pb-2">
                <mya-note-card [historyItem]="historyItem" (viewHistoryItem)="viewNote($event)"></mya-note-card>
              </div>
            </ng-container>
            <div class="col-md-12" *ngIf="filteredHistoryItems.length === 0">
              <div class="no-records-card">
                <h4 class="py-3 text-center">
                  No Records
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade p-3" id="quote" role="tabpanel" aria-labelledby="quote-tab">
          <div class="row" *ngIf="selectedHistoryType === HistoryTypeEnum.Quote">
            <ng-container *ngFor="let historyItem of filteredHistoryItems">
              <div class="col-md-4 pb-2">
                <mya-note-card [historyItem]="historyItem" (viewHistoryItem)="viewNote($event)"></mya-note-card>
              </div>
            </ng-container>
            <div class="col-md-12" *ngIf="filteredHistoryItems.length === 0">
              <div class="no-records-card">
                <h4 class="py-3 text-center">
                  No Records
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade p-3" id="gcs" role="tabpanel" aria-labelledby="gcs-tab">
          <div class="row" *ngIf="selectedHistoryType === HistoryTypeEnum.Gcs">
            <ng-container *ngFor="let historyItem of filteredHistoryItems">
              <div class="col-md-4 pb-2">
                <mya-note-card [historyItem]="historyItem" (viewHistoryItem)="viewNote($event)"></mya-note-card>
              </div>
            </ng-container>
            <div class="col-md-12" *ngIf="filteredHistoryItems.length === 0">
              <div class="no-records-card">
                <h4 class="py-3 text-center">
                  No Records
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade p-3" id="col" role="tabpanel" aria-labelledby="col-tab">
          <div class="row" *ngIf="selectedHistoryType === HistoryTypeEnum.Col">
            <ng-container *ngFor="let historyItem of filteredHistoryItems">
              <div class="col-md-4 pb-2">
                <mya-note-card [historyItem]="historyItem" (viewHistoryItem)="viewNote($event)"></mya-note-card>
              </div>
            </ng-container>
            <div class="col-md-12" *ngIf="filteredHistoryItems.length === 0">
              <div class="no-records-card">
                <h4 class="py-3 text-center">
                  No Records
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade p-3" id="som" role="tabpanel" aria-labelledby="som-tab">
          <div class="row" *ngIf="selectedHistoryType === HistoryTypeEnum.Som">
            <ng-container *ngFor="let historyItem of filteredHistoryItems">
              <div class="col-md-4 pb-2">
                <mya-note-card [historyItem]="historyItem" (viewHistoryItem)="viewNote($event)"></mya-note-card>
              </div>
            </ng-container>
            <div class="col-md-12" *ngIf="filteredHistoryItems.length === 0">
              <div class="no-records-card">
                <h4 class="py-3 text-center">
                  No Records
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<mya-notes-view [historyItem]="selectedHistoryItem"></mya-notes-view>
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { LayoutModule } from './layout/layout.module';
import { APP_CONFIG } from '@mya/configuration';
import { environment as env } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';
import { InterceptorService } from './services/interceptor.service';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { NgxStripeModule } from 'ngx-stripe';
import { RoleGuard } from './guards/role.guard';
import { LoginComponent } from './components/pages/login/login.component';
import { ForgetPasswordComponent } from './components/pages/forget-password/forget-password.component';
import { PasswordResetComponent } from './components/pages/password-reset/password-reset.component';
import { SetPasswordComponent } from './components/pages/set-password/set-password.component';
import { OnboardingGuard } from './guards/onboarding.guard';
import { PortalGuard } from './guards/portal.guard';
import { DateCheckerComponent } from './components/pages/date-checker/date-checker.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgetPasswordComponent,
    PasswordResetComponent,
    SetPasswordComponent,
    DateCheckerComponent,
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    NgxStripeModule.forRoot(env.stripeKey),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: APP_CONFIG,
      useValue: env,
    },
    { provide: APP_BASE_HREF, useValue: '/support' },
    AuthGuard,
    RoleGuard,
    OnboardingGuard,
    PortalGuard,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

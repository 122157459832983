import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbDateStruct, NgbDatepicker, NgbDatepickerNavigateEvent } from '@ng-bootstrap/ng-bootstrap';
import { BookingAppointmentSlot, BookingServiceDto, BookingStaffAvailability, BookingStaffMember } from '../../models';
import { BookingService } from '../../services';
import * as moment from 'moment';
import { PickerProgressBarService } from '../../services/picker-progress-bar.service';
import { ViewportScroller } from '@angular/common';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-appointment-datepicker',
  templateUrl: './appointment-datepicker.component.html',
  styleUrls: ['./appointment-datepicker.component.scss'],
})
export class AppointmentDatepickerComponent implements OnInit, OnDestroy {
  @ViewChild(NgbDatepicker) datePicker!: NgbDatepicker;
  @Input() currentDate!: Date;
  @Input() serviceId = '';
  @Input() bookingStaffId: string | null = null;
  @Input() center = false;
  @Input() preSelectAppointment: BookingAppointmentSlot | null = null;
  @Output() appointmentSlotSelected = new EventEmitter<BookingAppointmentSlot>();
  subscriptions: Subs[] = [];
  currentStaffMember: BookingStaffMember | null = null;
  currentService: BookingServiceDto | null = null;
  staffAvailability: BookingStaffAvailability[] = [];
  appointmentSlots: BookingAppointmentSlot[] = [];
  selectedAppointmentSlot: BookingAppointmentSlot | null = null;
  timezoneOffset = 0;
  timezone = 'UTC';
  meetingDuration = 0;
  preBuffer = 0;
  postBuffer = 0;
  timeSlotInterval = 0;
  minimumLeadTime = 0;
  maximumAdvance = 0;
  selectedMonth: Date | null = null;
  selectedDate: Date | null = null;
  model: NgbDateStruct | null = null;
  isProgressBarVisible = false;

  get selectedDateAppointmentSlots() {
    return this.selectedDate !== null ? this.appointmentSlots.filter(i => this.isSameDay(this.selectedDate as Date, i.date)) : [];
  }

  constructor(private bookingService: BookingService,
    private pickerProgressBarService: PickerProgressBarService,
    private scroller: ViewportScroller,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.timezoneOffset = this.currentDate.getTimezoneOffset() * 60000;
    this.subscriptions.push(this.pickerProgressBarService.IsVisible$.subscribe(isVisible => {
      this.isProgressBarVisible = isVisible;
    }));

    if (this.bookingStaffId) {
      this.bookingService.GetStaffMember(this.bookingStaffId);
      this.subscriptions.push(this.bookingService.CurrentStaffMember$.subscribe(staffMember => {
        this.currentStaffMember = staffMember;
        this.getStaffAvailability(this.selectedMonth);
      }));
    }

    this.bookingService.GetService(this.serviceId);
    this.subscriptions.push(this.bookingService.CurrentBookingService$.subscribe(service => {
      this.currentService = service;
      if (this.currentService) {
        this.loadServiceDefaults(this.currentService);
      }
    }));

    this.subscriptions.push(this.bookingService.StaffAvailability$.subscribe(staffAvailability => {
      this.staffAvailability = staffAvailability;

      if (this.selectedMonth) {
        this.appointmentSlots = this.getAppointmentSlots(this.selectedMonth);
      }

      if (this.appointmentSlots.length > 0 && this.preSelectAppointment) {
        const preSelectAppointment = this.preSelectAppointment;
        this.preSelectAppointment = null;
        const selectedAppointmentSlot = this.appointmentSlots.find(i => i.date.getTime() === preSelectAppointment.date.getTime() && i.staffId == preSelectAppointment.staffId);
        if (selectedAppointmentSlot) {
          this.selectAppointmentSlot(selectedAppointmentSlot);
          this.model = new NgbDate(selectedAppointmentSlot.date.getFullYear(), selectedAppointmentSlot.date.getMonth() + 1, selectedAppointmentSlot.date.getDate());
        }
      }

      if (this.appointmentSlots.length == 0 && this.isProgressBarVisible && this.selectedMonth?.getMonth() == this.currentDate.getMonth()) {
        this.datePicker.navigateTo({ year: this.selectedMonth.getFullYear(), month: this.selectedMonth.getMonth() + 2 });
      }

      this.isDayDisabled = (date: NgbDate, current: { month: number; year: number } | undefined) => {
        return this.appointmentSlots ? !this.appointmentSlots.some(appointmentSlot => this.isSameDay(new Date(date.year, date.month - 1, date.day), appointmentSlot.date)) : true;
      }
    }));

    if (this.preSelectAppointment) {
      this.selectMonth(new Date(this.preSelectAppointment.date.getFullYear(), this.preSelectAppointment.date.getMonth(), 1));
      this.selectedDate = new Date(this.preSelectAppointment.date.getFullYear(), this.preSelectAppointment.date.getMonth(), this.preSelectAppointment.date.getDate());
    }
  }

  isDayDisabled = (date: NgbDate, current: { month: number; year: number } | undefined) => {
    return this.appointmentSlots ? !this.appointmentSlots.some(appointmentSlot => this.isSameDay(new Date(date.year, date.month - 1, date.day), appointmentSlot.date)) : true;
  }

  loadServiceDefaults(bookingServiceDto: BookingServiceDto) {
    this.meetingDuration = moment.duration(bookingServiceDto.defaultDuration).asMilliseconds();
    this.preBuffer = moment.duration(bookingServiceDto.preBuffer).asMilliseconds();
    this.postBuffer = moment.duration(bookingServiceDto.postBuffer).asMilliseconds();
    this.timeSlotInterval = moment.duration(bookingServiceDto.schedulingPolicy?.timeSlotInterval).asMilliseconds();
    this.minimumLeadTime = moment.duration(bookingServiceDto.schedulingPolicy?.minimumLeadTime).asMilliseconds();
    this.maximumAdvance = moment.duration(bookingServiceDto.schedulingPolicy?.maximumAdvance).asMilliseconds();
  }

  onMonthChange(e: NgbDatepickerNavigateEvent) {
    this.selectMonth(new Date(e.next.year, e.next.month - 1, 1));
  }

  selectMonth(date: Date) {
    this.selectedMonth = date;
    this.getStaffAvailability(this.selectedMonth);
  }

  getStaffAvailability(date: Date | null) {
    if (date) {
      let staffIds: string[] = [];

      if (this.bookingStaffId && this.currentStaffMember?.id) {
        staffIds = [this.currentStaffMember.id];
      } else if (this.currentService?.staffMemberIds) {
        staffIds = this.currentService.staffMemberIds;
      }

      if (staffIds.length > 0) {
        this.appointmentSlots = [];
        this.bookingService.GetStaffAvailability(date, staffIds);
      }
    }
  }

  onDateSelected(date: NgbDate) {
    this.selectedDate = new Date(date.year, date.month - 1, date.day);
    this.changeDetectorRef.detectChanges();
    this.scroller.scrollToAnchor('slots-container');
  }

  isSameDay(dateA: Date, dateB: Date): boolean {
    return dateB.getFullYear() === dateA.getFullYear() && dateB.getMonth() === dateA.getMonth() && dateB.getDate() === dateA.getDate();
  }

  getAppointmentSlots(date: Date): BookingAppointmentSlot[] {
    const currentDate = this.toUTCDate(this.currentDate);
    this.selectedAppointmentSlot = null;
    let appointmentSlots: BookingAppointmentSlot[] = [];

    const minimumDateAllowed = new Date(currentDate.getTime() + this.minimumLeadTime);
    const maximumDateAllowed = new Date(currentDate.getTime() + this.maximumAdvance);

    let currentSlot = this.toUTCDate(date);
    const lastDayOfMonth = this.toUTCDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));

    while (currentSlot < lastDayOfMonth) {
      if (minimumDateAllowed <= this.addDuration(currentSlot, -1 * this.preBuffer) && this.addDuration(currentSlot, this.meetingDuration + this.preBuffer) <= maximumDateAllowed) {
        const staffAvailability = this.staffAvailability.find(bookingStaffAvailability => this.checkStaffAvailability(bookingStaffAvailability, currentSlot));
        if (staffAvailability) {
          appointmentSlots.push({
            staffId: staffAvailability.staffId,
            date: this.toCurrentTimezone(currentSlot)
          });
        }
      }

      currentSlot = this.addDuration(new Date(currentSlot), this.timeSlotInterval);
    }

    appointmentSlots = appointmentSlots.sort((a: BookingAppointmentSlot, b: BookingAppointmentSlot) =>
      new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    return appointmentSlots;
  }

  checkStaffAvailability(bookingStaffAvailability: BookingStaffAvailability, currentSlot: Date): boolean {
    return (bookingStaffAvailability.startDateTime?.dateTime && bookingStaffAvailability.endDateTime?.dateTime
      && new Date(bookingStaffAvailability.startDateTime.dateTime) <= this.addDuration(currentSlot, -1 * this.preBuffer) &&
      this.addDuration(currentSlot, this.meetingDuration + this.preBuffer) <= new Date(bookingStaffAvailability.endDateTime.dateTime)) ?? false;
  }

  addDuration(date: Date, milliseconds: number): Date {
    return new Date(date.getTime() + milliseconds);
  }

  selectAppointmentSlot(appointmentSlot: BookingAppointmentSlot) {
    this.selectedAppointmentSlot = appointmentSlot;
    this.appointmentSlotSelected.emit(appointmentSlot);
  }

  toUTCDate(date: Date) {
    return new Date(date.getTime() + this.timezoneOffset);
  }

  toCurrentTimezone(date: Date) {
    return new Date(date.getTime() - this.timezoneOffset);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

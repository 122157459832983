import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { ESignatureContractDetails, GetContractFromAccountResult } from '@mya/models';
import { Observable, catchError, BehaviorSubject } from 'rxjs';
import { LoaderService } from './loader.service';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Injectable({
  providedIn: 'root'
})
export class ContractService implements OnDestroy {
  baseApiUrl = '';
  subscriptions: Subs[] = [];

  private readonly contract = new BehaviorSubject<ESignatureContractDetails | null>(null);
  public readonly Contract$: Observable<ESignatureContractDetails | null> = this.contract;

  constructor(private http: HttpClient, private loaderService: LoaderService,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.contract}api/contract`
  }

  public getContract(loaderIdentifier: string) {
    this.subscriptions.push(this.http.get<GetContractFromAccountResult>(`${this.baseApiUrl}/get-contract`)
      .pipe(catchError((error: HttpErrorResponse) => {
        toastr.error('An error has occured');
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe((response) => {
        this.contract.next(response.contractDetails);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

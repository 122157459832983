import { AfterContentChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Account, ChangePlanForAccountRequest, ExternalUser, ExternalUserClaimTypes, InternalUser, SmartCode, SmartTypes, getUserType } from '@mya/models';
import { AuthenticationService } from '../../../services/authentication.service';
import { ModalConstant } from '../../../common/constants/modal.constant';
import { ModalService } from '../../../services/modal.service';
import { ChangePasswordModalComponent } from '../../shared/modals/change-password-modal/change-password-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuid } from 'uuid';
import { AccountService } from '../../../services/account.service';
import { LoaderService } from '../../../services/loader.service';
import { SmartTypesService } from '../../../services/smart-types.service';
import { OnBoardingWizard, Steps } from '../../../common/constants/steps.constant';
import { SupportTeamComponent } from '../../shared/onboarding-steps/support-team/support-team.component';
import { HttpStatusCode } from '@angular/common/http';
import { SubscriptionService } from '../../../services/subscription.service';
import { PricingService } from '../../../services/pricing.service';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { JwtService } from '../../../services/jwt.service';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-onboarding-wizard',
  templateUrl: './onboarding-wizard.component.html',
  styleUrls: ['./onboarding-wizard.component.scss'],
})
export class OnboardingWizardComponent implements OnInit, AfterContentChecked, OnDestroy {
  @ViewChild(ChangePasswordModalComponent) ChangePasswordModalComponent!: ChangePasswordModalComponent;
  @ViewChild(SupportTeamComponent) SupportTeamComponent!: SupportTeamComponent;
  linkedAccount: Account | null = null;
  accountCreator: ExternalUser | null = null;
  mentee: ExternalUser | null = null;
  mentor: InternalUser | null = null;
  url: string | null = null;
  userTypes: Record<string, SmartCode> = {};
  currentPage: OnBoardingWizard = Steps.OnBoardingWizard.SupportUsers;
  selectedUser: ExternalUser | null = null;
  currentTenant: string | null = null;
  subscriptions: Subs[] = [];

  get OnBoardingWizard() {
    return OnBoardingWizard;
  }

  get initials() {
    return `${this.accountCreator?.firstName.charAt(0)}${this.accountCreator?.lastName.charAt(0)}`.toUpperCase();
  }

  constructor(public router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private accountService: AccountService,
    private modalService: ModalService,
    private subscriptionService: SubscriptionService,
    private loaderService: LoaderService,
    private pricingService: PricingService,
    private smartTypesService: SmartTypesService,
    private referenceDataService: ReferenceDataService,
    private jwtService: JwtService) {
    const token = this.authenticationService.getToken();
    if (token) {
      const decodedToken: any = this.jwtService.DecodeToken(token);
      this.currentTenant = decodedToken[ExternalUserClaimTypes.Tenant];
    }
  }

  ngOnInit(): void {
    this.pricingService.fetchPlanPricings();
    this.referenceDataService.fetchReferenceData();
    this.accountService.getAccount();
    this.accountService.getAccountCreator();
    this.accountService.getMentee();
    this.accountService.getMentor();
    this.subscriptions.push(this.accountService.AccountCreator$.subscribe(user => {
      this.accountCreator = user;
    }));
    this.subscriptions.push(this.accountService.Mentee$.subscribe(user => {
      this.mentee = user;
    }));
    this.subscriptions.push(this.accountService.Mentor$.subscribe(user => {
      this.mentor = user;
    }));

    if (this.route.snapshot.queryParams['payment_success']) {
      const paymentSuccess = this.route.snapshot.queryParams['payment_success'];
      if (paymentSuccess === true) {
        toastr.success("Payment Successful");
      } else {
        toastr.error("Payment failed. Please try again.");
      }
    }

    this.getActiveSubscription();
    this.updatePlan();
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(i => {
      this.linkedAccount = i;
    }));

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.UserType, loaderIdentifier).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier);
      smartCodes?.forEach(smartCode => {
        this.userTypes[smartCode.id] = smartCode;
      });
    }));
  }

  ngAfterContentChecked(): void {
    this.modalService.initialize(ModalConstant.CHANGE_PASSWORD_MODAL);
    this.modalService.initialize(ModalConstant.SUPPORT_MEMBER_VIEW_MODAL);
    this.modalService.initialize(ModalConstant.DELETE_USER_CONFIRMATION_MODAL);
  }

  getActiveSubscription() {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.subscriptionService.getActiveSubscriptionByAccount().subscribe({
      next: (response) => {
        this.loaderService.hide(loaderIdentifier);
        if (response.subscription) {
          this.currentPage = OnBoardingWizard.Scheduling;
        }
      },
      error: (err) => {
        this.loaderService.hide(loaderIdentifier);
        if (err.status != HttpStatusCode.Unauthorized) {
          toastr.error(`${err.message || 'Unknown Error'}`);
        }
      }
    }));
  }

  updatePlan() {
    const plan = localStorage.getItem('plan');
    const tenant = localStorage.getItem('tenant');
    localStorage.removeItem('plan');
    localStorage.removeItem('tenant');

    if (plan && tenant && this.currentTenant == tenant) {
      const request: ChangePlanForAccountRequest = {
        planId: plan
      };

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.accountService.changePlanForAccount(request, loaderIdentifier).subscribe(() => {
        this.loaderService.hide(loaderIdentifier);
      }));
    }
  }

  getUserType(user: ExternalUser | null) {
    return getUserType(user, this.userTypes);
  }

  changePassword() {
    this.modalService.show(ModalConstant.CHANGE_PASSWORD_MODAL);
    this.ChangePasswordModalComponent.passwordReset();
  }

  viewUser(user: ExternalUser) {
    this.selectedUser = user;
    this.modalService.show(ModalConstant.SUPPORT_MEMBER_VIEW_MODAL);
  }

  deleteUser(user: ExternalUser) {
    this.selectedUser = user;
    this.modalService.show(ModalConstant.DELETE_USER_CONFIRMATION_MODAL);
  }

  next() {
    const nextPage = this.currentPage + 1;
    if (Object.values(OnBoardingWizard).includes(nextPage)) {
      this.currentPage = nextPage;
    }
  }

  previous() {
    const previousPage = this.currentPage - 1;
    if (Object.values(OnBoardingWizard).includes(previousPage)) {
      this.currentPage = previousPage;
    }
  }

  logout() {
    this.authenticationService.logout();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

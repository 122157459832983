import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Account, SmartCode, SmartTypes } from '@mya/models';
import { v4 as uuid } from 'uuid';
import { AccountService } from '../../../services/account.service';
import { LoaderService } from '../../../services/loader.service';
import { SmartTypesService } from '../../../services/smart-types.service';
import { PaymentService } from '../../../services/payment.service';
import { Appearance, PaymentMethod, StripeElementsOptions } from '@stripe/stripe-js';
import { PricingService } from '../../../services/pricing.service';
import { StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, OnDestroy {
  @ViewChild(StripePaymentElementComponent) paymentElement: StripePaymentElementComponent | undefined;
  plans: SmartCode[] = [];
  subscriptions: Subs[] = [];
  account: Account | null = null;
  paymentMethod: PaymentMethod | null = null;
  updatingPaymentMethod = false;
  editMode = false;
  appearance: Appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      colorPrimary: '#673ab7',
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  get currentPlan(): SmartCode | null {
    return this.plans.find(plan => plan.id == this.account?.mentoringPlanId) ?? null;
  }

  constructor(private smartTypesService: SmartTypesService,
    private accountService: AccountService,
    private pricingService: PricingService,
    private loaderService: LoaderService,
    private paymentService: PaymentService,
    private stripeService: StripeService) { }

  ngOnInit(): void {
    this.pricingService.fetchPlanPricings();
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(account => {
      this.account = account;
    }));

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.MentoringPlan, loaderIdentifier).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier);
      smartCodes?.forEach(smartCode => {
        if (smartCode.code !== 'OP') {
          this.plans.push(smartCode);
        }
      });
    }));
  }

  updatePaymentMethod() {
    this.editMode = true;
    const loaderIdentifier = uuid();
    this.subscriptions.push(this.paymentService.createSetupIntent(loaderIdentifier).subscribe(result => {
      this.loaderService.hide(loaderIdentifier);
      if (this.elementsOptions && result.clientSecret) {
        this.elementsOptions.clientSecret = result.clientSecret;
      }
    }));
  }

  onUpdateCard() {
    if (this.updatingPaymentMethod || this.paymentElement == undefined)
      return;

    this.updatingPaymentMethod = true;
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);

    this.subscriptions.push(this.stripeService
      .confirmSetup({
        elements: this.paymentElement.elements,
        confirmParams: {
          return_url: `${location.origin}/support/payment-redirect`,
        },
        redirect: 'if_required',
      })
      .subscribe({
        next: (result) => {
          this.updatingPaymentMethod = false;
          this.loaderService.hide(loaderIdentifier);
          if (result.error) {
            toastr.error(`${result.error.message}`);
          } else if (result.setupIntent.status === 'succeeded') {
            if (result.setupIntent.payment_method as string) {
              this.detachOtherPaymentMethods(result.setupIntent.payment_method as string);
              this.editMode = false;
            } else {
              toastr.error('Payment method null error');
            }
          }
        },
        error: (err) => {
          this.updatingPaymentMethod = false;
          this.loaderService.hide(loaderIdentifier);
          toastr.error(`${err.message || 'Unknown Error'}`);
        },
      }));
  }

  detachOtherPaymentMethods(paymentMethodId: string) {
    this.paymentService.detachOtherPaymentMethods({
      paymentMethodId: paymentMethodId
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Account, ExternalUser, ExternalUserClaimTypes } from '@mya/models';
import { ModalConstant } from '../../../../common/constants/modal.constant';
import { AccountService } from '../../../../services/account.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { ExternalUserService } from '../../../../services/external-user.service';
import { JwtService } from '../../../../services/jwt.service';
import { LoaderService } from '../../../../services/loader.service';
import { ModalService } from '../../../../services/modal.service';
import { v4 as uuid } from 'uuid';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-delete-user-confirmation-modal',
  templateUrl: './delete-user-confirmation-modal.component.html',
  styleUrls: ['./delete-user-confirmation-modal.component.scss'],
})
export class DeleteUserConfirmationModalComponent implements OnInit, OnDestroy {
  @Input() user: ExternalUser | null = null;
  elementId = ModalConstant.DELETE_USER_CONFIRMATION_MODAL;
  linkedAccount: Account | null = null;
  email: string | null = null;
  subscriptions: Subs[] = [];

  constructor(private externalUserService: ExternalUserService,
    private accountService: AccountService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
    private jwtService: JwtService) { }

  ngOnInit(): void {
    const token = this.authenticationService.getToken();
    if (token) {
      const decodedToken: any = this.jwtService.DecodeToken(token);
      this.email = decodedToken[ExternalUserClaimTypes.Email];
    }
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(i => {
      this.linkedAccount = i;
    }));
  }

  deleteUser() {
    if (this.user == null) return;

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.externalUserService.deleteSupportUserFromAccount(this.user.id, loaderIdentifier).subscribe(() => {
        this.accountService.getAccount(true);
      toastr.success("User deleted");
      this.modalService.hide(ModalConstant.DELETE_USER_CONFIRMATION_MODAL);
      this.loaderService.hide(loaderIdentifier);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Account, CreateParentTimeTokenRequest, InternalUser, ParentTimePricing, ParentTimeToken, ParentTimeTypeLookup, ParentTimeTypes, getMentorType } from '@mya/models';
import { ModalConstant } from '../../../../common/constants/modal.constant';
import { AccountService } from '../../../../services/account.service';
import { LoaderService } from '../../../../services/loader.service';
import { ModalService } from '../../../../services/modal.service';
import { PaymentService } from '../../../../services/payment.service';
import { PricingService } from '../../../../services/pricing.service';
import { v4 as uuid } from 'uuid';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-parent-time-payment-modal',
  templateUrl: './parent-time-payment-modal.component.html',
  styleUrls: ['./parent-time-payment-modal.component.scss'],
})
export class ParentTimePaymentModalComponent implements OnInit, OnDestroy {
  @Input() parentTimeType: ParentTimeTypes | null = null;
  @Output() parentTimeTokenGenerated = new EventEmitter<ParentTimeToken>();
  elementId = ModalConstant.PARENT_TIME_PAYMENT_MODAL;
  parentTimePrices: ParentTimePricing[] = [];
  account: Account | null = null;
  mentor: InternalUser | null = null;
  subscriptions: Subs[] = [];

  get ParentTimeTypes() {
    return ParentTimeTypes;
  }

  get ParentTimeTypeLookup() {
    return ParentTimeTypeLookup;
  }

  get mentorName() {
    return this.account ? `${this.mentor?.firstName} ${this.mentor?.lastName}` : '-';
  }

  get parentTimePrice(): ParentTimePricing | null {
    return this.parentTimePrices.find(i => i.parentTimeTypeId == this.parentTimeType && i.mentorType == getMentorType(this.mentor ?? null)) ?? null;
  }

  constructor(private accountService: AccountService,
    private pricingService: PricingService,
    private paymentService: PaymentService,
    private modalService: ModalService,
    private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(account => {
      this.account = account;
    }));
    this.subscriptions.push(this.accountService.Mentor$.subscribe(user => {
      this.mentor = user;
    }));
    this.pricingService.fetchParentTimePricing();
    this.subscriptions.push(this.pricingService.ParentTimePricing$.subscribe(pricing => {
      this.parentTimePrices = pricing;
    }));
  }

  confirm() {
    if (this.parentTimeType) {
      const request: CreateParentTimeTokenRequest = {
        parentTimeTypeId: this.parentTimeType
      }

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.paymentService.createParentTimeToken(request, loaderIdentifier).subscribe(result => {
        this.loaderService.hide(loaderIdentifier);
        this.parentTimeTokenGenerated.emit(result.parentTimeToken);
        this.modalService.hide(ModalConstant.PARENT_TIME_PAYMENT_MODAL);
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

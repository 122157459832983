import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'mya-add-member-card',
  templateUrl: './add-member-card.component.html',
  styleUrls: ['./add-member-card.component.scss'],
})
export class AddMemberCardComponent {
  @Output() addSupportUser = new EventEmitter();
}

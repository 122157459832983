import { AfterContentChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { SmartTypesService } from '../../../services/smart-types.service';
import { Account, AccountStatus, AccountStatusLookup, SmartCode, SmartTypes } from '@mya/models';
import { PricingService } from '../../../services/pricing.service';
import { AccountService } from '../../../services/account.service';
import { v4 as uuid } from 'uuid';
import { LoaderService } from '../../../services/loader.service';
import { ModalService } from '../../../services/modal.service';
import { ModalConstant } from '../../../common/constants/modal.constant';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit, AfterContentChecked, OnDestroy {
  plans: SmartCode[] = [];
  account: Account | null = null;
  subscriptions: Subs[] = [];

  get AccountStatus() {
    return AccountStatus;
  }

  get currentPlan(): SmartCode | null {
    return this.plans.find(plan => plan.id == this.account?.mentoringPlanId) ?? null;
  }

  constructor(private smartTypesService: SmartTypesService,
    private pricingService: PricingService,
    private accountService: AccountService,
    private modalService: ModalService,
    private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.pricingService.fetchPlanPricings();
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(account => {
      this.account = account;
    }));

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.MentoringPlan, loaderIdentifier).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier);
      smartCodes?.forEach(smartCode => {
        if (smartCode.code !== 'OP') {
          this.plans.push(smartCode);
        }
      });
    }));
  }

  ngAfterContentChecked(): void {
    this.modalService.initialize(ModalConstant.CANCEL_PLAN_CONFIRMATION_MODAL);
  }

  cancelPlan() {
    this.modalService.show(ModalConstant.CANCEL_PLAN_CONFIRMATION_MODAL);
  }

  getAccountStatus(accountStatus: AccountStatus) {
    return AccountStatusLookup[accountStatus];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

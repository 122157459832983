<div [id]="elementId" class="modal fade mya-change-password-modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header d-flex align-items-center">
                <h4 class="modal-title">
                    Change Password
                </h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
                <div class="modal-body">
                    <div class="mb-3">
                        <label><b>Current Password</b></label>
                        <input type="password" class="form-control mt-1 mb-3" placeholder="●●●●●●●"
                            formControlName="currentPassword" (ngModelChange)="passwordChanged($event)"
                            [ngClass]="{ 'is-invalid': passwordForm.controls.currentPassword.touched && passwordForm.controls.currentPassword.errors}"
                            [ngClass]="{ 'is-valid': passwordForm.controls.currentPassword.touched && passwordForm.controls.currentPassword.errors === undefined }" />
                    </div>
                    <div class="mb-3">
                        <label><b>New Password</b></label>
                        <input type="password" class="form-control mt-1 mb-3" placeholder="●●●●●●●"
                            formControlName="newPassword" (ngModelChange)="passwordChanged($event)"
                            [ngClass]="{ 'is-invalid': passwordForm.controls.newPassword.touched && passwordForm.controls.newPassword.errors}"
                            [ngClass]="{ 'is-valid': passwordForm.controls.newPassword.touched && passwordForm.controls.newPassword.errors === undefined }" />
                    </div>
                    <div class="mb-3">
                        <label><b>Confirm Password</b></label>
                        <input type="password" class="form-control mt-1 mb-3" placeholder="●●●●●●●"
                            formControlName="confirmPassword" (ngModelChange)="passwordChanged($event)"
                            [ngClass]="{ 'is-invalid': passwordForm.controls.confirmPassword.touched && passwordForm.controls.confirmPassword.errors }"
                            [ngClass]="{ 'is-valid': passwordForm.controls.confirmPassword.touched && passwordForm.controls.confirmPassword.errors === undefined }" />
                    </div>
                    <p *ngIf="showValidationError('confirmPassword', 'passwordsMismatch')" class="text-danger">
                        {{passwordForm.controls.confirmPassword.errors?.["message"]}}
                    </p>
                    <p *ngIf="showValidationError('newPassword', 'pattern')" class="text-danger">New Password must
                        be at least 8
                        characters
                        in length and contain at least 3 of the 4 types of characters: Lower-case letters (a-z),
                        Upper-case
                        letters (A-Z), Numbers (0-9), Special characters (!@#$%^&*).</p>
                    <p *ngIf="customError" class="text-danger">{{customError}}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn waves-effect waves-light btn-outline-primary"
                        data-bs-dismiss="modal">
                        Cancel
                    </button>
                    <button class="btn waves-effect waves-light btn-primary" [disabled]="!passwordForm.valid">Change
                        Password</button>
                </div>
            </form>
        </div>
    </div>
</div>
import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, EventInput, EventMountArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import { AppointmentService } from '../../../services/appointment.service';
import { DatePipe } from '@angular/common';
import { Account, Appointment, AppointmentTypeEnum, ExternalUser, InternalUser, ParentTimeToken, ParentTimeTypes } from '@mya/models';
import { ModalService } from '../../../services/modal.service';
import { ModalConstant } from '../../../common/constants/modal.constant';
import { v4 as uuid } from 'uuid';
import { LoaderService } from '../../../services/loader.service';
import { AccountService } from '../../../services/account.service';
import { DateTimeService } from '../../../services/date-time.service';
import { Subscription as Subs } from 'rxjs';
declare const bootstrap: any;

@Component({
  selector: 'mya-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent implements OnInit, AfterContentChecked, OnDestroy {
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin],
    datesSet: this.handleDatesSet.bind(this),
    eventDidMount: this.handleEventDidMount.bind(this),
    initialView: 'timeGridWeek',
    contentHeight: "65vh",
    eventClassNames: ['px-1', 'text-white'],
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short'
    },
    headerToolbar: {
      start: 'title',
      center: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    allDaySlot: false,
    firstDay: 1,
    views: {
      timeGridDay: {
        nowIndicator: true,
        dayHeaders: false,
        titleFormat: {
          month: 'long',
          year: 'numeric',
          day: 'numeric',
          weekday: 'long'
        }
      }
    }
  };
  appointments: Appointment[] = [];
  account: Account | null = null;
  accountCreator: ExternalUser | null = null;
  mentee: ExternalUser | null = null;
  mentor: InternalUser | null = null;
  remainingAppointments = 0;
  overriddenDate: Date | null = null;
  timezoneOffset = 0;
  _parentTimeToken: ParentTimeToken | null = null;
  parentTimeTokenFreeTime30Mins: ParentTimeToken | null = null;
  parentTimeTokenPaidTime30Mins: ParentTimeToken | null = null;
  parentTimeType: ParentTimeTypes | null = null;
  freeParentTimeSessions = 0;
  paidParentTimeSessions = 0;
  subscriptions: Subs[] = [];

  get parentTimeToken(): ParentTimeToken | null {
    return this._parentTimeToken;
  }

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(private datePipe: DatePipe,
    private accountService: AccountService,
    private appointmentService: AppointmentService,
    private modalService: ModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private loaderService: LoaderService,
    dateTimeService: DateTimeService) {
      this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => {
      this.overriddenDate = date;
      this.timezoneOffset = this.currentDate.getTimezoneOffset() * 60000;
    }));
  }

  ngOnInit(): void {
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(account => {
      this.account = account;
      if (account?.id) {
        this.getRemainingAppointments(account.id);
      }
    }));
    this.subscriptions.push(this.accountService.AccountCreator$.subscribe(user => {
      this.accountCreator = user;
    }));
    this.subscriptions.push(this.accountService.Mentee$.subscribe(user => {
      this.mentee = user;
      this.generateEvents();
    }));
    this.subscriptions.push(this.accountService.Mentor$.subscribe(user => {
      this.mentor = user;
    }));
    this.subscriptions.push(this.appointmentService.Appointments$.subscribe(appointments => {
      this.appointments = appointments;
      this.generateEvents();
    }));
  }

  ngAfterContentChecked(): void {
    this.modalService.initialize(ModalConstant.PARENT_TIME_SELECTION_MODAL);
    this.modalService.initialize(ModalConstant.PARENT_TIME_SCHEDULING_MODAL);
    this.modalService.initialize(ModalConstant.PARENT_TIME_PAYMENT_MODAL);
  }

  getRemainingAppointments(accountId: string) {
    const loaderIdentifier = uuid();
    this.subscriptions.push(this.appointmentService.getUpcomingAppointmentDetails(loaderIdentifier).subscribe({
      next: (response) => {
        this.loaderService.hide(loaderIdentifier);
        this.remainingAppointments = response.remainingAppointments;
        this.freeParentTimeSessions = response.remainingFreeParentTimeAppointments;
        this.paidParentTimeSessions = response.remainingPaidParentTimeAppointments;
      },
      error: () => {
        this.loaderService.hide(loaderIdentifier);
      }
    }));
  }

  handleDatesSet() {
    this.getAppointments();
  }

  handleEventDidMount(eventInfo: EventMountArg) {
    new bootstrap.Tooltip(eventInfo.el, {
      title: eventInfo.event.title,
      delay: { "show": 500, "hide": 100 }
    });
  }

  generateEvents() {
    if (this.calendarComponent && this.mentee) {
      const events = this.appointments.map((appointment) =>
        <EventInput>{
          title: this.getAppointmentTitle(appointment),
          start: this.datePipe.transform(new Date(new Date(appointment.startTime).getTime() - this.timezoneOffset), 'YYYY-MM-ddTHH:mm:ss'),
          end: this.datePipe.transform(new Date(new Date(appointment.startTime).getTime() + (appointment.durationInMinutes * 60000) - this.timezoneOffset), 'YYYY-MM-ddTHH:mm:ss'),
        });

      this.calendarComponent.events = events;
    }
  }

  getAppointmentTitle(appointment: Appointment) {
    switch (appointment.appointmentTypeId) {
      case AppointmentTypeEnum.Consultant:
        return `${this.mentee?.firstName}${this.mentee?.lastName ? (' ' + this.mentee.lastName) : ''}'s First Consultation`;
      case AppointmentTypeEnum.Mentor:
        return `${this.mentee?.firstName}${this.mentee?.lastName ? (' ' + this.mentee.lastName) : ''}'s Appointment`;
      case AppointmentTypeEnum.ParentTime:
        return `Appointment with ${this.mentor?.firstName}`;

      default:
        return 'Appointment';
    }
  }

  getAppointments() {
    const calendarApi = this.calendarComponent.getApi();
    this.appointmentService.getAppointments(calendarApi.view.currentStart, calendarApi.view.currentEnd);
  }

  openParentTimeSelectionModal() {
    this.parentTimeType = null;
    this.setParentTime(null);
    const loaderIdentifier = uuid();
    this.subscriptions.push(this.appointmentService.getParentTimeToken(ParentTimeTypes.FreeTime30Mins, loaderIdentifier).subscribe(result => {
      this.loaderService.hide(loaderIdentifier);
      this.parentTimeTokenFreeTime30Mins = result.parentTimeToken;
      if (this.parentTimeTokenFreeTime30Mins) {
        this.modalService.show(ModalConstant.PARENT_TIME_SELECTION_MODAL);
      } else {
        this.fetchPaidParentTimeToken();
      }
    }));
  }

  fetchPaidParentTimeToken() {
    const loaderIdentifier = uuid();
    this.subscriptions.push(this.appointmentService.getParentTimeToken(ParentTimeTypes.PaidTime30Mins, loaderIdentifier).subscribe(result => {
      this.loaderService.hide(loaderIdentifier);
      this.parentTimeTokenPaidTime30Mins = result.parentTimeToken;
      if (this.parentTimeTokenPaidTime30Mins) {
        this.setParentTime(this.parentTimeTokenPaidTime30Mins);
        this.modalService.show(ModalConstant.PARENT_TIME_SCHEDULING_MODAL);
      } else {
        this.parentTimeType = ParentTimeTypes.PaidTime30Mins;
        this.modalService.show(ModalConstant.PARENT_TIME_PAYMENT_MODAL);
      }
    }));
  }

  parentTimeSelected(parentTimeType: ParentTimeTypes) {
    switch (parentTimeType) {
      case ParentTimeTypes.FreeTime30Mins:
        this.setParentTime(this.parentTimeTokenFreeTime30Mins);
        this.modalService.show(ModalConstant.PARENT_TIME_SCHEDULING_MODAL);
        break;
      case ParentTimeTypes.PaidTime30Mins:
        this.fetchPaidParentTimeToken();
        break;
    }
  }

  scheduleParentTime(parentTimeToken: ParentTimeToken) {
    this.setParentTime(parentTimeToken);
    this.modalService.show(ModalConstant.PARENT_TIME_SCHEDULING_MODAL);
  }

  parentTimeScheduled() {
    this.modalService.hide(ModalConstant.PARENT_TIME_SCHEDULING_MODAL);
    this.getAppointments();
  }

  setParentTime(parentTimeToken: ParentTimeToken | null) {
    this._parentTimeToken = null;
    this.changeDetectorRef.detectChanges();
    this._parentTimeToken = parentTimeToken;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

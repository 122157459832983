<!-- -------------------------------------------------------------- -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- -------------------------------------------------------------- -->
<div id="main-wrapper" class="h-100">
  <!-- -------------------------------------------------------------- -->
  <!-- Topbar header - style you can find in pages.scss -->
  <!-- -------------------------------------------------------------- -->
  <header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
      <div class="navbar-header">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a class="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)">
          <i class="ri-close-line fs-6 ri-menu-2-line"></i>
        </a>
        <!-- -------------------------------------------------------------- -->
        <!-- Logo -->
        <!-- -------------------------------------------------------------- -->
        <a class="navbar-brand" href="index.html">
          
          <b class="logo-icon  pt-3">
            <img src="./assets/images/mya-logo.png" class="light-logo d-block" alt="homepage" />
          </b>
          <!-- Logo text -->
        </a>
        <!-- -------------------------------------------------------------- -->
        <!-- End Logo -->
        <!-- -------------------------------------------------------------- -->
        <!-- -------------------------------------------------------------- -->
        <!-- Toggle which is visible on mobile only -->
        <!-- -------------------------------------------------------------- -->
        <a class="topbartoggler d-block d-md-none waves-effect waves-light" href="javascript:void(0)"
          data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
          aria-expanded="false" aria-label="Toggle navigation"><i class="ri-more-line fs-6"></i></a>
      </div>
      <!-- -------------------------------------------------------------- -->
      <!-- End Logo -->
      <!-- -------------------------------------------------------------- -->
      <div class="navbar-collapse collapse" id="navbarSupportedContent">
        <!-- -------------------------------------------------------------- -->
        <!-- toggle and nav items -->
        <!-- -------------------------------------------------------------- -->
        <div class="me-auto">

        </div>
        <!-- -------------------------------------------------------------- -->
        <!-- Right side toggle and nav items -->
        <!-- -------------------------------------------------------------- -->
        <ul class="navbar-nav me-5">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <div class="circle me-3">
                <span class="initials text-white">{{initials}}</span>
              </div>
              <span class="d-none d-md-block">
                <h5 class="mb-0">
                  <b>{{accountCreator?.firstName + ' ' + accountCreator?.lastName}}</b>

                  <i data-feather="chevron-down" class="feather-sm ms-2"></i>
                </h5>
              </span>
              <span class="d-block d-md-none"><i data-feather="plus" class="feather-sm"></i></span>
            </a>
            <div class="dropdown-menu dropdown-menu-animate-up dropdown-menu-end profile-dropdown"
              aria-labelledby="navbarDropdown">

              <h5>User Profile</h5>
              <div class="d-flex">
                <div class="circle me-3">
                  <span class="initials text-white">{{initials}}</span>
                </div>
                <span style="align-self: center;">
                  <h5 class="mb-0">
                    <b>{{accountCreator?.firstName + ' ' + accountCreator?.lastName}}</b>
                  </h5>
                  <h5 class="mb-0 text-muted">{{getUserType(accountCreator ??
                    null)}}</h5>

                </span>
              </div>

              <hr class="hr" />

              <button type="button" class="btn waves-effect waves-light btn-primary w-100 mt-2 btn-change-password"
                (click)="changePassword()"><b>Change Password</b></button>

              <button type="button" class="btn waves-effect waves-light btn-outline-primary w-100 mt-2 btn-logout"
                (click)="logout()"><b>Log Out</b></button>
            </div>
          </li>
          <!-- -------------------------------------------------------------- -->
          <!-- User profile and search -->
          <!-- -------------------------------------------------------------- -->
        </ul>
      </div>
    </nav>
  </header>
  <!-- -------------------------------------------------------------- -->
  <!-- End Topbar header -->
  <!-- -------------------------------------------------------------- -->
  <!-- -------------------------------------------------------------- -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- -------------------------------------------------------------- -->
  <aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav">
        <ul id="sidebarnav">
          <!-- User Profile-->
          <li class="sidebar-item px-2 pb-2" [ngClass]="{ 'selected': url === '/appointments'}">
            <a class="sidebar-link waves-effect waves-dark sidebar-link text-white" routerLink="/appointments"
              aria-expanded="false">
              <i class="far fa-calendar-alt fill-white"></i>
              <b>
                <span class="hide-menu ms-2">Appointments</span>
              </b>
            </a>
          </li>
          <li class="sidebar-item px-2 pb-2" [ngClass]="{ 'selected': url === '/notes'}">
            <a class="sidebar-link waves-effect waves-dark sidebar-link text-white" routerLink="/notes"
              aria-expanded="false">
              <i class="ri-draft-line fill-white"></i>
              <b>
                <span class="hide-menu ms-2">Notes</span>
              </b>
            </a>
          </li>
          <li class="sidebar-item px-2 pb-2" [ngClass]="{ 'selected': url === '/payment'}">
            <a class="sidebar-link waves-effect waves-dark sidebar-link text-white" routerLink="/payment"
              aria-expanded="false">
              <i class="far fa-credit-card fill-white"></i>
              <b>
                <span class="hide-menu ms-2">Payments</span>
              </b>
            </a>
          </li>
          <li class="sidebar-item px-2 pb-2" [ngClass]="{ 'selected': url === '/subscription'}">
            <a class="sidebar-link waves-effect waves-dark sidebar-link text-white" routerLink="/subscription"
              aria-expanded="false">
              <i class="far fa-gem fill-white"></i>
              <b>
                <span class="hide-menu ms-2">Subscription</span>
              </b>
            </a>
          </li>
          <li class="sidebar-item px-2 pb-2"  *ngIf="mentor">
            <a class="sidebar-link waves-effect waves-dark sidebar-link text-white" [href]="'mailto:'+ mentor.emailAddress"
              aria-expanded="false">
              <i class="fas fa-envelope fill-white"></i>
              <b>
                <span class="hide-menu ms-2">Contact {{mentor.firstName}}</span>
              </b>
            </a>
          </li>
        </ul>
      </nav>
      <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
  </aside>
  <!-- -------------------------------------------------------------- -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- -------------------------------------------------------------- -->
  <!-- -------------------------------------------------------------- -->
  <!-- Page wrapper  -->
  <!-- -------------------------------------------------------------- -->
  <div class="page-wrapper h-100 d-block">
    <router-outlet></router-outlet>
  </div>
  <!-- -------------------------------------------------------------- -->
  <!-- End Page wrapper  -->
  <!-- -------------------------------------------------------------- -->
</div>
<!-- -------------------------------------------------------------- -->
<!-- End Wrapper -->
<!-- -------------------------------------------------------------- -->
<mya-change-password-modal></mya-change-password-modal>
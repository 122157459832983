import { AfterContentChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { HistoryService } from '../../../services/history.service';
import { HistoryItem, HistoryTypeEnum } from '@mya/models';
import { OffcanvasService } from '../../../services/offcanvas.service';
import { OffCanvasConstant } from '../../../common/constants/offcanvas.constant';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit, AfterContentChecked, OnDestroy {
  historyItems: HistoryItem[] = [];
  selectedHistoryType: HistoryTypeEnum | null = null;
  selectedHistoryItem: HistoryItem | null = null;
  subscriptions: Subs[] = [];

  get HistoryTypeEnum() {
    return HistoryTypeEnum;
  }

  get filteredHistoryItems() {
    return this.historyItems.filter(i => this.selectedHistoryType == null || i.historyTypeId == this.selectedHistoryType);
  }

  constructor(private historyService: HistoryService, private offcanvasService: OffcanvasService) { }

  ngOnInit(): void {
    this.historyService.getHistoryItems();
    this.subscriptions.push(this.historyService.HistoryItems$.subscribe(historyItems => {
      this.historyItems = historyItems;
    }));
  }

  ngAfterContentChecked(): void {
    this.offcanvasService.initialize(OffCanvasConstant.NOTES_VIEW);
  }

  selectHistoryType(historyType: HistoryTypeEnum | null) {
    this.selectedHistoryType = historyType;
  }

  viewNote(historyItem: HistoryItem) {
    this.selectedHistoryItem = historyItem;
    this.offcanvasService.show(OffCanvasConstant.NOTES_VIEW);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

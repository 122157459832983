import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidationPattern, ResetPasswordRequest, ShowValidationErrorCheck, passwordMatchValidator, PasswordResetRequest } from '@mya/models';
import { AuthenticationService } from '../../../services/authentication.service';
import { v4 as uuid } from 'uuid';
import { LoaderService } from '../../../services/loader.service';
import { catchError } from 'rxjs';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  resetRequest: ResetPasswordRequest | null = null;
  customError: string | null = null;
  subscriptions: Subs[] = [];

  passwordForm = this.formBuilder.group({
    newPassword: ['', [Validators.required, Validators.pattern(ValidationPattern.PasswordPattern)]],
    confirmPassword: ['', [Validators.required, Validators.pattern(ValidationPattern.PasswordPattern)]]
  });

  constructor(private authenticationService: AuthenticationService,
    private loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        const loaderIdentifier = uuid();
        this.subscriptions.push(this.authenticationService.fetchPasswordResetRequest(id, loaderIdentifier)
          .subscribe(result => {
            this.resetRequest = result.resetRequest;
            this.loaderService.hide(loaderIdentifier);
            if (this.resetRequest == null) {
              this.router.navigateByUrl(`login`);
            }
          }));
      }
    }));

    this.passwordForm.controls.confirmPassword.addValidators(passwordMatchValidator(this.passwordForm.controls.newPassword));
  }

  passwordChanged(e: any) {
    this.passwordForm.controls.newPassword.updateValueAndValidity();
    this.passwordForm.controls.confirmPassword.updateValueAndValidity();
    this.customError = null;
  }

  showValidationError(formControlName: string, validation: string) {
    return ShowValidationErrorCheck(formControlName, validation, this.passwordForm);
  }

  onSubmit() {
    if (this.resetRequest == null || this.passwordForm.controls.newPassword.value == null)
      return;

    const request: PasswordResetRequest = {
      resetRequestId: this.resetRequest.id,
      newPassword: this.passwordForm.controls.newPassword.value
    };

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.authenticationService.setPassword(request, loaderIdentifier)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        if (error.status == HttpStatusCode.Conflict) {
          this.customError = error.error;
        } else {
          toastr.error('An error has occured');
        }

        throw error;
      })).subscribe(() => {
        this.loaderService.hide(loaderIdentifier);
        toastr.success('Password Change Successful');
        this.router.navigateByUrl(`login`);
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { Injectable } from '@angular/core';
declare const bootstrap: any;

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  public initialize(elementId: string){
    if (document.getElementById(elementId) && !bootstrap.Modal.getInstance(document.getElementById(elementId)))
      new bootstrap.Modal(document.getElementById(elementId));
  }

  public show(elementId: string) {
    const modal = bootstrap.Modal.getInstance(document.getElementById(elementId));
    modal.show();
  }

  public hide(elementId: string) {
    const modal = bootstrap.Modal.getInstance(document.getElementById(elementId));
    modal.hide();
  }
}

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Account, EditExternalUserRequest, ExternalUser, ExternalUserClaimTypes, GeocoderAddressTypes, ReferenceDataTypes, ReferenceItem, SmartCode, SmartTypes } from '@mya/models';
import { LoaderService } from '../../../../services/loader.service';
import { SmartTypesService } from '../../../../services/smart-types.service';
import { v4 as uuid } from 'uuid';
import { Loader } from '@googlemaps/js-api-loader';
import { APP_CONFIG } from '@mya/configuration';
import { AccountService } from '../../../../services/account.service';
import { ExternalUserService } from '../../../../services/external-user.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { JwtService } from '../../../../services/jwt.service';
import { ReferenceDataService } from '../../../../services/reference-data.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-mentee-details',
  templateUrl: './mentee-details.component.html',
  styleUrls: ['./mentee-details.component.scss'],
})
export class MenteeDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("addressLine1") addressLine1Field: ElementRef | null = null;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  subscriptions: Subs[] = [];
  userTypeLookup: SmartCode[] = [];
  states: SmartCode[] = [];
  countryLookup: SmartCode[] = [];
  countries: Record<string, SmartCode> = {};
  countryCodes: SmartCode[] = [];
  countryCodeLookup: Record<string, SmartCode> = {};
  pronounsLookup: SmartCode[] = [];
  placeLibrary: google.maps.PlacesLibrary | null = null;
  autoCompleteListener: google.maps.MapsEventListener | null = null;
  linkedAccount: Account | null = null;
  mentee: ExternalUser | null = null;
  email: string | null = null;
  timeZones: ReferenceItem[] = [];
  enableAddressField = true;
  phoneMask: any = {
    mask: '(000) 000-0000'
  };

  get stateLookup() {
    return this.states.filter(i => i.subTypeSmartCodeId == this.form.controls.country.value);
  }

  get isOtherPronoun() {
    return this.pronounsLookup.find(i => i.id == this.form.controls.pronouns.value)?.code === 'O';
  }

  get isNorthAmericanCountry() {
    return this.countries[this.form.controls.country.value ?? '']?.code?.toLowerCase() == 'ca' || this.countries[this.form.controls.country.value ?? '']?.code?.toLowerCase() == 'us';
  }

  get stateTitle() {
    return this.countries[this.form.controls.country.value ?? '']?.code?.toLowerCase() == 'ca' ? 'Province' : 'State';
  }

  get zipTitle() {
    return this.countries[this.form.controls.country.value ?? '']?.code?.toLowerCase() == 'ca' ? 'Postal Code' : 'ZIP Code';
  }

  form = this.formBuilder.group({
    firstName: ['', [Validators.required, Validators.minLength(2)]],
    lastName: ['', [Validators.required, Validators.minLength(2)]],
    age: [null as number | null, [Validators.required]],
    city: ['', [Validators.required]],
    state: ['', [Validators.required]],
    country: [null as string | null, []],
    zip: ['', []],
    address1: ['', []],
    address2: ['', []],
    timeZone: [null as string | null, [Validators.required]],
    phoneNumber: ['', [Validators.required]],
    countryCode: [null as string | null, [Validators.required]],
    receiveTexts: [true, [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    nickname: ['', []],
    pronouns: ['', []],
    otherPronouns: ['', []]
  })

  constructor(@Inject(APP_CONFIG) private appConfig: any,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private accountService: AccountService,
    private externalUserService: ExternalUserService,
    private smartTypesService: SmartTypesService,
    private authenticationService: AuthenticationService,
    private jwtService: JwtService,
    private referenceDataService: ReferenceDataService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscriptions.push(this.accountService.Mentee$.subscribe(user => {
      this.mentee = user;
      if (this.mentee) {
        this.form.patchValue({
          age: this.mentee.age,
          firstName: this.mentee.firstName,
          lastName: this.mentee.lastName,
          email: this.mentee.emailAddress,
          address1: this.mentee.mailingAddress?.addressLine1,
          address2: this.mentee.mailingAddress?.addressLine2,
          city: this.mentee.mailingAddress?.city,
          state: this.mentee.mailingAddress?.stateId,
          zip: this.mentee.mailingAddress?.zipCode,
          phoneNumber: this.mentee.phoneNumber,
          nickname: this.mentee.nickName,
          pronouns: this.mentee.pronounsId,
          otherPronouns: this.mentee.otherPronouns,
          countryCode: this.mentee.countryCodeId,
          receiveTexts: this.mentee.receiveTexts,
          timeZone: this.mentee.timeZone
        });

        if (this.mentee.mailingAddress?.countryId) {
          this.form.patchValue({
            country: this.mentee.mailingAddress.countryId
          });
        }
      }
    }));
    const token = this.authenticationService.getToken();
    if (token) {
      const decodedToken: any = this.jwtService.DecodeToken(token);
      this.email = decodedToken[ExternalUserClaimTypes.Email];
    }
    const loaderIdentifier1 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.UserType, loaderIdentifier1).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier1);
      this.userTypeLookup = smartCodes;
    }));
    const loaderIdentifier2 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.State, loaderIdentifier2).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier2);
      this.states = smartCodes;
    }));
    const loaderIdentifier3 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.Pronouns, loaderIdentifier3).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier3);
      this.pronounsLookup = smartCodes;
    }));
    const loaderIdentifier4 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.Country, loaderIdentifier4).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier4);
      this.countryLookup = smartCodes;
      smartCodes?.forEach(smartCode => {
        this.countries[smartCode.id] = smartCode;
      });

      const country = this.countryLookup.find(i => i.code.toLowerCase() == 'us');
      if (country && this.form.controls.country.value == null) {
        this.setCountryValue(country.id);
      }
      this.initializeGoogleAutocomplete();
    }));
    const loaderIdentifier5 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.CountryCode, loaderIdentifier5).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier5);
      this.countryCodes = smartCodes;
      smartCodes?.forEach(smartCode => {
        this.countryCodeLookup[smartCode.id] = smartCode;
      });

      const usCountryCode = this.countryCodes.find(i => i.code.toLowerCase() == 'us')?.id ?? null;
      if (this.form.controls.countryCode.value == null && usCountryCode) {
        this.form.controls.countryCode.setValue(usCountryCode);
        this.form.controls.phoneNumber.setValidators([Validators.required, Validators.minLength(14)]);
        this.form.controls.phoneNumber.updateValueAndValidity();
      }
    }));
    this.subscriptions.push(this.referenceDataService.ReferenceData$.subscribe(referenceData => {
      if (referenceData != null) {
        const references = JSON.parse(referenceData);
        this.timeZones = references[ReferenceDataTypes.TimeZones];
      }
    }));

    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(i => {
      this.linkedAccount = i;
    }));
  }

  ngAfterViewInit(): void {
    new Loader({
      apiKey: this.appConfig.googleMapsApiKey,
      version: "weekly",
      libraries: ["places"]
    })
      .importLibrary('places')
      .then((library) => {
        this.placeLibrary = library;
        this.initializeGoogleAutocomplete();
      })
      .catch((e) => {
        console.error(e);
      });
  }

  initializeGoogleAutocomplete() {
    const country = this.countryLookup.find(i => i.id == this.form.controls.country.value) ?? null;
    if (this.addressLine1Field && this.placeLibrary && country) {
      google.maps.event.clearListeners(this.addressLine1Field.nativeElement as HTMLInputElement, "place_changed");
      this.enableAddressField = false;
      this.changeDetectorRef.detectChanges();
      this.enableAddressField = true;
      this.changeDetectorRef.detectChanges();
      const autoCompleteField = new google.maps.places.Autocomplete(this.addressLine1Field.nativeElement as HTMLInputElement, {
        componentRestrictions: { country: [country.code] },
        fields: ["address_components", "formatted_address"],
        types: ["address"]
      });

      this.autoCompleteListener = autoCompleteField.addListener("place_changed", () => this.autocompleteAddress(autoCompleteField?.getPlace() ?? null));
    }
  };

  autocompleteAddress(place: google.maps.places.PlaceResult | null) {
    if (place) {
      if (place.address_components && place.address_components.length > 0) {
        place.address_components.forEach(addressComponent => this.loadAddressValues(addressComponent, this.form));
        this.autoCompleteAddressLine1(place);
      }
    }
  }

  loadAddressValues(addressComponent: google.maps.GeocoderAddressComponent, form: FormGroup) {
    if (addressComponent.types.includes(GeocoderAddressTypes.AdministrativeAreaLevel1)) {
      const state = this.stateLookup.find(s => s.code == addressComponent.short_name || s.label == addressComponent.long_name);
      if (state) {
        form.get('state')?.setValue(state.id);
      }
    }

    if (addressComponent.types.includes(GeocoderAddressTypes.PostalCode)) {
      form.get('zip')?.setValue(addressComponent.long_name);
    }

    if (addressComponent.types.includes(GeocoderAddressTypes.Locality)) {
      form.get('city')?.setValue(addressComponent.long_name);
    }
  }

  autoCompleteAddressLine1(place: google.maps.places.PlaceResult) {
    const line1 = place.formatted_address?.split(",")[0];
    if (line1) {
      this.form.controls.address1.setValue(line1);
    }
  }

  changePronoun(e: any) {
    this.form.controls.pronouns?.setValue(e.target.value, {
      onlySelf: true,
    });

    if (this.isOtherPronoun) {
      this.form.controls.otherPronouns.setValidators(Validators.required);
    } else {
      this.form.controls.otherPronouns.removeValidators(Validators.required);
    }

    this.form.controls.otherPronouns.updateValueAndValidity();
  }

  changeState(e: any) {
    this.form.controls.state?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  changeCountry(e: SmartCode) {
    this.setCountryValue(e.id);
    this.resetAddressFields();
    this.initializeGoogleAutocomplete();
  }

  changeCountryCode(countryCode: SmartCode) {
    this.form.controls.countryCode.setValue(countryCode.id, {
      onlySelf: true,
    });

    if (countryCode.label == "1") {
      this.phoneMask = {
        mask: '(000) 000-0000'
      };
      this.form.controls.phoneNumber.setValidators([Validators.required, Validators.minLength(14)]);
      this.form.controls.phoneNumber.updateValueAndValidity();
    } else {
      this.phoneMask = {
        mask: '0000000000000000'
      };
      this.changeDetectorRef.detectChanges();
      if (this.form.controls.phoneNumber.value) {
        this.form.controls.phoneNumber.setValue(this.form.controls.phoneNumber.value.replace(/[^0-9]/g, ''));
        this.form.controls.phoneNumber.setValidators([Validators.required]);
        this.form.controls.phoneNumber.updateValueAndValidity();
      }
    }
  }

  changeTimeZone(e: any) {
    this.form.controls.timeZone.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  resetAddressFields() {
    this.form.controls.address1?.setValue(null, { onlySelf: true });
    this.form.controls.address2?.setValue(null, { onlySelf: true });
    this.form.controls.city?.setValue(null, { onlySelf: true });
    this.form.controls.state?.setValue(null, { onlySelf: true });
    this.form.controls.zip?.setValue(null, { onlySelf: true });
  }

  setCountryValue(country: string) {
    this.form.controls.country.setValue(country);
    if (this.isNorthAmericanCountry) {
      this.form.controls.state.enable();
      this.form.controls.zip.enable();
    } else {
      this.form.controls.state.reset();
      this.form.controls.state.disable();
      this.form.controls.zip.reset();
      this.form.controls.zip.disable();
    }
  }

  back() {
    this.previous.emit();
  }

  submit() {
    if (this.form == null || this.mentee == null)
      return;

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    if (!this.form.dirty && !this.form.touched) {
      this.next.emit();
      return;
    }

    const user = this.mentee;
    user.age = this.form.controls.age.value ?? 0;
    user.firstName = this.form.controls.firstName.value as string;
    user.lastName = this.form.controls.lastName.value as string;
    user.emailAddress = this.form.controls.email.value as string;
    user.mailingAddress = {
      addressLine1: this.form.controls.address1.value,
      addressLine2: this.form.controls.address2.value,
      city: this.form.controls.city.value as string,
      stateId: this.isNorthAmericanCountry ? (this.stateLookup.find(i => i.id == this.form.controls.state.value)?.id ?? null) : null,
      countryId: this.countryLookup.find(i => i.id == this.form.controls.country.value)?.id ?? null,
      zipCode: this.isNorthAmericanCountry ? this.form.controls.zip.value : null,
    };
    user.phoneNumber = this.form.controls.phoneNumber.value?.replace(/[^0-9]/g, '') ?? null;
    user.countryCodeId = this.countryCodes.find(i => i.id == this.form.controls.countryCode.value)?.id ?? null;
    user.receiveTexts = this.form.controls.receiveTexts.value as boolean;
    user.timeZone = this.form.controls.timeZone.value;
    user.nickName = this.form.controls.nickname.value as string;
    user.pronounsId = this.pronounsLookup.find(i => i.id == this.form.controls.pronouns.value)?.id ?? null;
    user.otherPronouns = this.form.controls.otherPronouns.value as string;

    const request: EditExternalUserRequest = {
      user: user
    };

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.externalUserService.editExternalUser(request, loaderIdentifier).subscribe((i) => {
      this.accountService.getAccount(true);
      this.loaderService.hide(loaderIdentifier);
      this.next.emit();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

<div class="card card-hover shadow-sm cursor-pointer" *ngIf="user" (click)="viewUser.emit(user)">
    <div class="d-flex flex-column align-items-center justify-content-center title-container pt-4">
        <div class="circle mb-2">
            <span class="initials text-white">{{initials}}</span>
        </div>
        <h4>{{user.firstName + ' ' + user.lastName}}</h4>
        <h5>({{getUserType(user)}})</h5>
    </div>

    <span class="text-white p-1 is-you-status" *ngIf="isCurrentUser">THIS IS YOU</span>
    <i class="fas fa-ellipsis-h menu-icon cursor-pointer m-3 mb-0" data-bs-toggle="dropdown"
        (click)="$event.stopPropagation()"></i>
    <ul class="dropdown-menu dropdown-menu-end">
        <li>
            <a class="dropdown-item" href="javascript:void(0)" (click)="editUser.emit(user)"><i
                    class="fas fa-pencil-alt me-2"></i>Edit</a>
        </li>
        <li *ngIf="!isCurrentUser">
            <a class="dropdown-item" href="javascript:void(0)" (click)="delete(user, $event)"><i
                    class="fas fa-trash me-2"></i>Delete</a>
        </li>
    </ul>
</div>
import { BookingDatetime } from "./booking-datetime";

export class BookingStaffAvailabilityRequest {
    staffIds: string[] | null = null;
    startDateTime: BookingDatetime | null = null;
    endDateTime: BookingDatetime | null = null;

    constructor(startDateTime: BookingDatetime | null, endDateTime: BookingDatetime | null) {
        this.startDateTime = startDateTime;
        this.endDateTime = endDateTime;
    }
}

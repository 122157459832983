import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Account, ExternalUser, SmartCode, SmartTypes } from '@mya/models';
import { AccountService } from '../../../../services/account.service';
import { LoaderService } from '../../../../services/loader.service';
import { ModalService } from '../../../../services/modal.service';
import { SmartTypesService } from '../../../../services/smart-types.service';
import { v4 as uuid } from 'uuid';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-support-team',
  templateUrl: './support-team.component.html',
  styleUrls: ['./support-team.component.scss'],
})
export class SupportTeamComponent implements OnInit, OnDestroy {
  @Output() next = new EventEmitter();
  @Output() viewUser = new EventEmitter<ExternalUser>();
  @Output() deleteUser = new EventEmitter<ExternalUser>();
  subscriptions: Subs[] = [];
  selectedUser: ExternalUser | null = null;
  linkedAccount: Account | null = null;
  userTypes: Record<string, SmartCode> = {};
  editMode = false;

  constructor(private accountService: AccountService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private smartTypesService: SmartTypesService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(i => {
      this.linkedAccount = i;
    }));

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.UserType, loaderIdentifier).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier);
      smartCodes?.forEach(smartCode => {
        this.userTypes[smartCode.id] = smartCode;
      });
    }));
  }

  closeEditMode() {
    this.editMode = false;
    this.selectedUser = null;
  }

  editUser(user: ExternalUser) {
    this.selectedUser = user;
    this.editMode = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, first, mergeMap, map, catchError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.authenticationService.getToken();
    if (token) {
      const tokenizedReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
      return this.createHandle(tokenizedReq, next);
    }
    return this.createHandle(req, next);
  }

  createHandle(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse && event.status == HttpStatusCode.Unauthorized) {
        this.authenticationService.removeToken();
        this.router.navigate(['/login']);
      }
      return event;
    }), catchError((error: HttpErrorResponse) => {
      if (error.status == HttpStatusCode.Unauthorized) {
        this.authenticationService.removeToken();
        this.router.navigate(['/login']);
      }
      throw error;
    }));
  }
}

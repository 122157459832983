import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ExternalUserClaimTypes, ExternalUserRoles } from '@mya/models';
import { AuthenticationService } from '../services/authentication.service';
import { JwtService } from '../services/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService,
    private jwtService: JwtService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Observable<boolean>(observer => {
      const token = this.authenticationService.getToken();
      if (token) {
        const decodedToken: any = this.jwtService.DecodeToken(token);
        const roles = decodedToken[ExternalUserClaimTypes.Role];
        if ((Array.isArray(roles) && roles.some(i => i === ExternalUserRoles.AccountCreator)) || roles === ExternalUserRoles.AccountCreator) {
          observer.next(true);
        } else {
          this.authenticationService.logout();
        }
      }
    });
  }

}

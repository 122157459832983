import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG } from '@mya/configuration';
import { AuthenticateUserRequest, AuthenticateUserResult, ChangePasswordRequest, FetchPasswordResetRequestResult, FetchTenantResult, PasswordResetRequest, SendPasswordResetEmailRequest, SwitchTenantRequest, SwitchTenantResult } from '@mya/models';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { LoaderService } from './loader.service';
import { v4 as uuid } from 'uuid';
import { Subscription as Subs } from 'rxjs';
const tokenKey = 'support_token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
  baseApiUrl = '';
  subscriptions: Subs[] = [];

  private readonly isLoggedIn = new BehaviorSubject<boolean>(false);
  public readonly IsLoggedIn$: Observable<boolean> = this.isLoggedIn;

  private readonly credentialError = new BehaviorSubject<boolean>(false);
  public readonly CredentialError$: Observable<boolean> = this.credentialError;

  constructor(private http: HttpClient, private router: Router, private loaderService: LoaderService,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.authentication}api/auth`

    const token = this.getToken();
    this.isLoggedIn.next(token != null);
  }

  public login(request: AuthenticateUserRequest, returnUrl: string | null) {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.post<AuthenticateUserResult>(`${this.baseApiUrl}/authenticate`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        if (error.status == HttpStatusCode.Unauthorized) {
          this.isLoggedIn.next(false);
          this.credentialError.next(true);
        }
        throw error;
      }))
      .subscribe((response) => {
        this.loaderService.hide(loaderIdentifier);
        if (response.token){
          this.isLoggedIn.next(true);
          this.credentialError.next(false);
          this.setSession(response.token, response.isMultiTenant, returnUrl);
        }
      }));
  }

  public switchTenant(request: SwitchTenantRequest) {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.post<SwitchTenantResult>(`${this.baseApiUrl}/switch-tenant`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe((response) => {
        this.loaderService.hide(loaderIdentifier);
        if (response.token)
          this.setSession(response.token, false, null);
      }));
  }

  public sendPasswordResetRequest(request: SendPasswordResetEmailRequest, loaderIdentifier: string): Observable<any> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post(`${this.baseApiUrl}/send-password-reset-email`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  public fetchPasswordResetRequest(resetRequestId: string, loaderIdentifier: string): Observable<any> {
    this.loaderService.show(loaderIdentifier);
    return this.http.get<FetchPasswordResetRequestResult>(`${this.baseApiUrl}/fetch-password-reset-request/${resetRequestId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  public fetchTenants(loaderIdentifier: string): Observable<FetchTenantResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.get<FetchTenantResult>(`${this.baseApiUrl}/fetch-tenants`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  public setPassword(request: PasswordResetRequest, loaderIdentifier: string): Observable<any> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post(`${this.baseApiUrl}/password-reset`, request);
  }

  public changePassword(request: ChangePasswordRequest, loaderIdentifier: string): Observable<any> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post(`${this.baseApiUrl}/change-password`, request);
  }

  public logout() {
    localStorage.removeItem(tokenKey);
    this.router.navigate(['/login']);
  }

  private setSession(authResult: string, isMultiTenant: boolean, returnUrl: string | null) {
    localStorage.setItem(tokenKey, authResult);

    if (isMultiTenant) {
      this.router.navigate(['/tenant-selection']);
    }
    else if (returnUrl != null)
      window.location.href = returnUrl;
    else
      this.router.navigate(['/']);
  }

  public getToken() {
    return localStorage.getItem(tokenKey);
  }

  public isAuthenticated() {
    const token = this.getToken();
    return token != null;
  }

  public removeToken() {
    localStorage.removeItem(tokenKey);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

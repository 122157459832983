<div class="d-flex flex-column flex-sm-row">
    <h1 class="d-inline mb-0 me-2">Payments</h1>
    <h3 class="d-inline mb-0 align-self-sm-center step-heading">Step 4/5</h3>
</div>
<div class="mt-4">
    <div class="row">
        <form class="mb-3" [formGroup]="form" (ngSubmit)="submit()">
            <div class="col-12">
                <div class="form-box">
                    <div class="d-flex flex-column-reverse flex-md-row justify-content-center gap-3">
                        <div class="d-flex flex-column" *ngIf="elementsOptions && elementsOptions.clientSecret">
                            <input class="form-control payment-card-holder" type="text" placeholder="Name"
                                formControlName="name" />
                            <ng-container
                                *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)">
                                <div [ngClass]="{'d-block' : form.controls.name.errors?.['required']}"
                                    class="invalid-feedback">Name is required.</div>
                            </ng-container>
                            <ngx-stripe-payment class="mt-3" [appearance]="appearance"
                                [clientSecret]="elementsOptions.clientSecret"></ngx-stripe-payment>
                        </div>
                        <mya-plan-card [isCurrentPlanCard]="true" [plan]="selectedPlan"></mya-plan-card>
                    </div>

                </div>
            </div>

            <div class="col-12">
                <div class="d-flex flex-column-reverse flex-sm-row-reverse">
                    <button type="submit" class="btn waves-effect waves-light btn-primary btn-submit mt-4"
                        [disabled]="paymentInProgress || !form.valid">
                        Pay
                    </button>
                    <button type="button" class="btn waves-effect waves-light btn-outline-primary btn-back mt-4"
                        (click)="back()">
                        Back
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

export { }

declare global {
    interface Date {
        toNgbDate(): NgbDate;
        getUTCFullDate(): Date;
        getUTCFullDateInverse(): Date;
        addDays(days: number): Date;
        addHours(hours: number): Date;
        addMinutes(minutes: number): Date;
        addSeconds(seconds: number): Date;
        generateDate(hours: number, minutes: number, seconds: number): Date;
        isEqualDate(date: Date): number;
        isEqualTime(date: Date): number;
        isAMTime(): boolean;
        isPMTime(): boolean;
        getNextHourDate(): Date;
    }
}

Date.prototype.toNgbDate = function (): NgbDate {
    return new NgbDate(this.getFullYear(), this.getMonth() + 1, this.getDate());
};

Date.prototype.getUTCFullDate = function (): Date {
    return new Date(this.getTime() + (this.getTimezoneOffset() * 60 * 1000));
};

Date.prototype.getUTCFullDateInverse = function (): Date {
    return new Date(this.getTime() - (this.getTimezoneOffset() * 60 * 1000));
};

Date.prototype.addDays = function (days: number): Date {
    return new Date(this.getTime() + (days * 24 * 60 * 60 * 1000));
};

Date.prototype.addHours = function (hours: number): Date {
    return new Date(this.getTime() + (hours * 60 * 60 * 1000));
};

Date.prototype.addMinutes = function (minutes: number): Date {
    return new Date(this.getTime() + (minutes * 60 * 1000));
};

Date.prototype.addSeconds = function (seconds: number): Date {
    return new Date(this.getTime() + (seconds * 1000));
};

Date.prototype.generateDate = function (hours: number, minutes: number, seconds: number): Date {
    const date = new Date(this);
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date;
};

Date.prototype.isEqualDate = function (date: Date): number {
    const date1 = new Date(this.getFullYear(), this.getMonth(), this.getDate());
    const date2 = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    if (date1 < date2) {
        return -1;
    } else if (date1 > date2) {
        return 1;
    } else {
        return 0;
    }
};

Date.prototype.isEqualTime = function (date: Date): number {
    const time1 = this.getHours() * 60 + this.getMinutes();
    const time2 = date.getHours() * 60 + date.getMinutes();

    if (time1 < time2) {
        return -1;
    } else if (time1 > time2) {
        return 1;
    } else {
        return 0;
    }
};

Date.prototype.isAMTime = function (): boolean {
    return this.getHours() < 12;
};

Date.prototype.isPMTime = function (): boolean {
    return this.getHours() >= 12;
};

Date.prototype.getNextHourDate = function (): Date {
    let date = this.addHours(1);
    date = date.generateDate(date.getHours(), 0, 0);
    return date
};
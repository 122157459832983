import { AbstractControl, ValidatorFn, ValidationErrors } from "@angular/forms";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

export class CustomValidatorError {
    isError = false;
    message = "";
    validationFieldName = "";

    constructor(isError: boolean, message: string) {
        this.isError = isError;
        this.message = message;
    }
}


export function passwordMatchValidator(passwordToMatch: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const comparison = passwordToMatch.value as string;
        const inQuestion = control.value as string;

        if (inQuestion != comparison) {
            const errorObject = new CustomValidatorError(true, "New Password field and Confirm Password field do not match.");
            errorObject.validationFieldName = "passwordsMismatch";

            return errorObject;
        }

        return null;
    }
}


export function sameEmailValidator(firstControlName: string, secondControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const firstControl = control.get(firstControlName);
        const secondControl = control.get(secondControlName);

        return firstControl && secondControl &&
            (firstControl.value as string | null)?.trim().toLowerCase() === (secondControl.value as string | null)?.trim().toLowerCase() && (firstControl.value !== null && firstControl.value !== '') ? { sameEmail: true } : null;
    }
}

export function validateDateRangeRequired(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value as [NgbDate | null, NgbDate | null];
        return (value[0] && value[1]) ? null : { required: true };
    }
}
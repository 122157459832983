<div class="main-wrapper mya-password-reset h-100">
    <div class="auth-wrapper background-img h-100" *ngIf="resetRequest">
        <div class="row h-100">
            <div class="col-12 col-md-6 col-lg-7 col-xl-8 d-flex justify-content-center align-items-center">
                <div class="mx-2">
                    <h1 class="display-2 text-white text-center">Welcome Aboard!</h1>
                    <h2 class="text-center text-white">Welcome to {{firstName}}{{lastName == '' ? '' : ' '+ lastName}}'s Mentoring Program</h2>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-5 col-xl-4 px-5 login-box d-flex flex-column justify-content-center align-items-center">
                <img src="./assets/images/logos/mya-logo-colored.svg" alt="logo" />
                <h1 class="mt-4">Set a Password</h1>
                <h3 class="mb-4 text-muted">Create a Password to begin your registration process</h3>
                <div class="p-4 mb-5 bg-body rounded mt-2 form-box">
                    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
                        <div class="mb-3 mt-4">
                            <label for="password"><b>New Password</b></label>
                            <input type="password" id="password" name="password" class="form-control mt-1 mb-3"
                                placeholder="●●●●●●●" formControlName="newPassword"
                                (ngModelChange)="passwordChanged($event)"
                                [ngClass]="{ 'is-invalid': passwordForm.controls.newPassword.touched && passwordForm.controls.newPassword.errors}"
                                [ngClass]="{ 'is-valid': passwordForm.controls.newPassword.touched && passwordForm.controls.newPassword.errors === undefined }" />
                        </div>
                        <div class="mb-3">
                            <label for="confirm-password"><b>Confirm Password</b></label>
                            <input type="password" id="confirm-password" name="confirm-password"
                                class="form-control mt-1 mb-3" placeholder="●●●●●●●" formControlName="confirmPassword"
                                (ngModelChange)="passwordChanged($event)"
                                [ngClass]="{ 'is-invalid': passwordForm.controls.confirmPassword.touched && passwordForm.controls.confirmPassword.errors }"
                                [ngClass]="{ 'is-valid': passwordForm.controls.confirmPassword.touched && passwordForm.controls.confirmPassword.errors === undefined }" />
                        </div>

                        <p *ngIf="showValidationError('confirmPassword', 'passwordsMismatch')" class="text-danger">
                            {{passwordForm.controls.confirmPassword.errors?.["message"]}}
                        </p>
                        <p *ngIf="showValidationError('newPassword', 'pattern')" class="text-danger">New Password must
                            be at least 8
                            characters
                            in length and contain at least 3 of the 4 types of characters: Lower-case letters (a-z),
                            Upper-case
                            letters (A-Z), Numbers (0-9), Special characters (!@#$%^&*).</p>
                        <p *ngIf="customError" class="text-danger">{{customError}}</p>
                        <button type="submit" [disabled]="!passwordForm.valid"
                            class="btn waves-effect waves-light btn-primary w-100 mt-3 mb-4 btn-change-password">
                            <b>Confirm Password</b>
                        </button>
                    </form>
                </div>
            </div>
        </div>

    </div>

</div>
<div class="main-wrapper mya-onboarding-wizard h-100" data-layout="horizontal" data-boxed-layout="boxed">
  <header class="topbar">
    <nav class="px-3 navbar top-navbar navbar-expand-md navbar-light">
      <div>
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <!-- -------------------------------------------------------------- -->
        <!-- Logo -->
        <!-- -------------------------------------------------------------- -->
        <a href="index.html">
          <!-- Logo icon -->
          <b>
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Light Logo icon -->
            <img src="./assets/images/logos/mya-logo-colored.svg" alt="homepage" class="light-logo" />
          </b>
          <!--End Logo icon -->
          <!-- Logo text -->

        </a>
        <!-- -------------------------------------------------------------- -->
        <!-- End Logo -->
        <!-- -------------------------------------------------------------- -->
        <!-- -------------------------------------------------------------- -->
        <!-- Toggle which is visible on mobile only -->
        <!-- -------------------------------------------------------------- -->

      </div>
      <!-- -------------------------------------------------------------- -->
      <!-- End Logo -->
      <!-- -------------------------------------------------------------- -->
      <div class="navbar-collapse collapse" id="navbarSupportedContent">
        <!-- -------------------------------------------------------------- -->
        <!-- toggle and nav items -->
        <!-- -------------------------------------------------------------- -->
        <div class="me-auto"></div>
        <!-- -------------------------------------------------------------- -->
        <!-- Right side toggle and nav items -->
        <!-- -------------------------------------------------------------- -->
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle p-0" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <div class="circle">
                <span class="initials text-white">{{initials}}</span>
              </div>
              <span class="d-none d-md-block ms-3">
                <h5 class="mb-0">
                  <b>{{accountCreator?.firstName + ' ' + accountCreator?.lastName}}</b>

                  <i data-feather="chevron-down" class="feather-sm ms-2"></i>
                </h5>
              </span>
              <span class="d-block d-md-none"><i data-feather="plus" class="feather-sm"></i></span>
            </a>
            <div class="dropdown-menu dropdown-menu-animate-up dropdown-menu-end profile-dropdown"
              aria-labelledby="navbarDropdown">

              <h5>User Profile</h5>
              <div class="d-flex">
                <div class="circle me-3">
                  <span class="initials text-white">{{initials}}</span>
                </div>
                <span style="align-self: center;">
                  <h5 class="mb-0">
                    <b>{{accountCreator?.firstName + ' ' + accountCreator?.lastName}}</b>
                  </h5>
                  <h5 class="mb-0 text-muted">{{getUserType(accountCreator ?? null)}}</h5>

                </span>
              </div>

              <hr class="hr" />

              <button type="button" class="btn waves-effect waves-light btn-primary w-100 mt-2 btn-change-password"
                (click)="changePassword()"><b>Change Password</b></button>

              <button type="button" class="btn waves-effect waves-light btn-outline-primary w-100 mt-2 btn-logout"
                (click)="logout()"><b>Log Out</b></button>
            </div>
          </li>
          <!-- -------------------------------------------------------------- -->
          <!-- User profile and search -->
          <!-- -------------------------------------------------------------- -->
        </ul>
      </div>
    </nav>
  </header>

  <div class="progress wizard-progress rounded-0 w-100">
    <div class="progress-bar animated-progress-bar indeterminate"
      [ngStyle]="{'width': (currentPage/(OnBoardingWizard.Scheduling + 1)) * 100 + '%'}"></div>
  </div>
  <div class="background-img">
    <div class="custom-wrapper">
      <div class="steps">
        <div class="d-flex">
          <div class="step-container" [ngClass]="{ 'show': currentPage === OnBoardingWizard.SupportUsers}">
            <a id="step-support-team" href="javascript:void(0)">
              <span class="step">
                <img alt="support-team" />
              </span>
            </a>
          </div>
          <div class="flex-fill align-self-center step-container"
            [ngClass]="{ 'show': currentPage === OnBoardingWizard.SupportUsers}">
            <div class="separator" [ngClass]="currentPage > OnBoardingWizard.SupportUsers ? '' : 'inactive'"></div>
          </div>
          <div class="step-container"
            [ngClass]="{ 'show': (currentPage === OnBoardingWizard.MenteeInfo || currentPage + 1 === OnBoardingWizard.MenteeInfo)}">
            <a id="step-mentee-details" href="javascript:void(0)">
              <span class="step" [ngClass]="currentPage > OnBoardingWizard.SupportUsers ? '' : 'inactive'">
                <img alt="mentee-details" />
              </span>
            </a>
          </div>
          <div class="flex-fill align-self-center step-container"
            [ngClass]="{ 'show': currentPage === OnBoardingWizard.MenteeInfo}">
            <div class="separator" [ngClass]="currentPage > OnBoardingWizard.MenteeInfo ? '' : 'inactive'"></div>
          </div>
          <div class="step-container"
            [ngClass]="{ 'show': (currentPage === OnBoardingWizard.PlanSelection || currentPage + 1 === OnBoardingWizard.PlanSelection)}">
            <a id="step-plan" href="javascript:void(0)">
              <span class="step" [ngClass]="currentPage > OnBoardingWizard.MenteeInfo ? '' : 'inactive'">
                <img alt="plan" />
              </span>
            </a>
          </div>
          <div class="flex-fill align-self-center step-container"
            [ngClass]="{ 'show': currentPage === OnBoardingWizard.PlanSelection}">
            <div class="separator" [ngClass]="currentPage > OnBoardingWizard.PlanSelection ? '' : 'inactive'"></div>
          </div>
          <div class="step-container"
            [ngClass]="{ 'show': (currentPage === OnBoardingWizard.PaymentInfo || currentPage + 1 === OnBoardingWizard.PaymentInfo || currentPage - 1 === OnBoardingWizard.PaymentInfo)}">
            <a id="step-payment" href="javascript:void(0)">
              <span class="step" [ngClass]="currentPage > OnBoardingWizard.PlanSelection ? '' : 'inactive'">
                <img alt="payment" />
              </span>
            </a>
          </div>
          <div class="flex-fill align-self-center step-container"
            [ngClass]="{ 'show': (currentPage === OnBoardingWizard.PaymentInfo|| currentPage - 1 === OnBoardingWizard.PaymentInfo)}">
            <div class="separator" [ngClass]="currentPage > OnBoardingWizard.PaymentInfo ? '' : 'inactive'"></div>
          </div>
          <div class="step-container"
            [ngClass]="{ 'show': (currentPage === OnBoardingWizard.Scheduling || currentPage + 1 === OnBoardingWizard.Scheduling)}">
            <a id="step-scheduling" href="javascript:void(0)">
              <span class="step" [ngClass]="currentPage > OnBoardingWizard.PaymentInfo ? '' : 'inactive'">
                <img alt="scheduling" />
              </span>
            </a>
          </div>
        </div>
      </div>

      <div class="page-wrapper w-100">
        <ng-container [ngSwitch]="currentPage">
          <mya-support-team *ngSwitchCase="OnBoardingWizard.SupportUsers" (next)="next()" (viewUser)="viewUser($event)"
            (deleteUser)="deleteUser($event)"></mya-support-team>
          <mya-mentee-details *ngSwitchCase="OnBoardingWizard.MenteeInfo" (next)="next()"
            (previous)="previous()"></mya-mentee-details>
          <mya-plan-selection *ngSwitchCase="OnBoardingWizard.PlanSelection" (next)="next()"
            (previous)="previous()"></mya-plan-selection>
          <mya-payments *ngSwitchCase="OnBoardingWizard.PaymentInfo" (next)="next()"
            (previous)="previous()"></mya-payments>
          <mya-schedule-appointment *ngSwitchCase="OnBoardingWizard.Scheduling" (previous)="previous()">
          </mya-schedule-appointment>
        </ng-container>
      </div>
    </div>

  </div>

</div>
<mya-change-password-modal></mya-change-password-modal>
<mya-support-member-view-modal [user]="selectedUser"
  (editUser)="SupportTeamComponent.editUser($event)"></mya-support-member-view-modal>
<mya-delete-user-confirmation-modal [user]="selectedUser"></mya-delete-user-confirmation-modal>
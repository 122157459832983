<div class="card card-hover p-0 mya-plan-card"
    [ngClass]="{ 'current-plan-card': isCurrentPlanCard, 'cursor-pointer': !isReadOnly && !isCurrentPlanCard}"
    *ngIf="plan && account" (click)="selectPlan()">
    <div class="p-4">
        <ng-container *ngIf="isCurrentPlanCard; else elseBlock">
            <h4>{{mentee ? (mentee.firstName + ' ' + mentee.lastName) : '-'}}'s Mentoring Plan</h4>
            <h5>{{plan.label}}</h5>
        </ng-container>

        <ng-template #elseBlock>
            <div class="d-flex">
                <h5 class="d-inline me-auto mb-0">{{plan.label}}</h5>
                <input class="form-check-input success check-outline outline-success ms-2" type="radio"
                    [id]="checkBoxId" name="mya-plan-card" [value]="plan.id" [checked]="plan.id === currentPlanId"
                    disabled />
            </div>
        </ng-template>
        <hr class="hr" />
        <div class="d-flex">
            <span class="me-auto" [ngClass]="{ 'opacity-70': isCurrentPlanCard}">Number of sessions booked:</span>
            <span><b>{{numberOfSessions}} {{numberOfSessions > 1 ? 'Sessions' : 'Session'}}</b></span>
        </div>
        <div class="d-flex">
            <span class="me-auto" [ngClass]="{ 'opacity-70': isCurrentPlanCard}">Cost:</span>
            <span><b>${{cost}}.00</b></span>
        </div>
        <div class="d-flex">
            <span class="me-auto" [ngClass]="{ 'opacity-70': isCurrentPlanCard}">Mentor:</span>
            <span><b>{{mentor ? (mentor.firstName + ' ' + mentor.lastName) : '-'}}</b></span>
        </div>
    </div>
</div>
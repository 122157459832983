<div [id]="elementId" class="modal fade mya-support-member-view-modal">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="d-flex flex-wrap" *ngIf="user">
                    <div class="flex-fill px-4">
                        <div class="d-flex flex-column align-items-center justify-content-center title-container pt-5">
                            <div class="circle mb-2">
                                <span class="initials text-white">{{initials}}</span>
                            </div>
                            <h4>{{user.firstName + ' ' + user.lastName}}</h4>
                            <h5>({{getUserType(user)}})</h5>
                        </div>
                    </div>
                    <div class="vr"></div>
                    <div class="flex-fill">
                        <div class="d-flex flex-column p-3">
                            <div class="d-flex mb-2">
                                <i class="fas fa-at text-center mt-1 me-2"></i>
                                <div>
                                    <p class="mb-0"><b>Email Address</b></p>
                                    <p class="mb-0">{{user.emailAddress ?? '-'}}</p>
                                </div>
                            </div>
                            <div class="d-flex mb-2">
                                <i class="fas fa-mobile-alt text-center mt-1 me-2"></i>
                                <div>
                                    <p class="mb-0"><b>Phone Number (where you can receive texts)</b></p>
                                    <p class="mb-0">{{user.phoneNumber ?? '-'}}</p>
                                </div>
                            </div>
                            <div class="d-flex mb-2">
                                <i class="fas fa-map-marker-alt text-center mt-1 me-2"></i>
                                <div>
                                    <p class="mb-0"><b>Address Line 1</b></p>
                                    <p class="mb-0">{{user.mailingAddress?.addressLine1 ?? '-'}}</p>
                                </div>
                            </div>
                            <div class="d-flex mb-2">
                                <i class="fas fa-map-marker-alt text-center mt-1 me-2"></i>
                                <div>
                                    <p class="mb-0"><b>Address Line 2</b></p>
                                    <p class="mb-0">{{user.mailingAddress?.addressLine2 ?? '-'}}</p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>
                                    <p class="mb-0"><b>City</b></p>
                                    <p>{{user.mailingAddress?.city ?? '-'}}</p>
                                </div>
                                <div>
                                    <p class="mb-0"><b>State</b></p>
                                    <p>{{state?.label ?? '-'}}</p>
                                </div>
                                <div>
                                    <p class="mb-0"><b>ZIP Code</b></p>
                                    <p>{{user.mailingAddress?.zipCode ?? '-'}}</p>
                                </div>
                            </div>
                            <div class="d-flex flex-row-reverse">
                                <button type="button" class="btn waves-effect waves-light btn-primary btn-edit mt-2"
                                    (click)="edit()">
                                    Edit
                                </button>
                                <button type="button"
                                    class="btn waves-effect waves-light btn-outline-primary btn-cancel mt-2"
                                    (click)="cancel()">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
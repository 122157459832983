<ng-container *ngIf="mentorAppointment">
    <div class="appointment-booked-container p-5 w-100 justify-content-center align-items-center">
        <div class="flex-grow-1">
            <h1>Welcome aboard World Wide Youth Mentoring {{parentFirstname}}!</h1>
            <h4 class="mb-4 text-muted">
                {{menteeFirstname}}'s first session is now booked.
                You will also receive an email from {{mentorName}} giving you feedback after the first session.
                Thank you for giving us the opportunity to have you join our mentoring community.
                We are looking forward to the journey!</h4>
            <button type="button" class="btn waves-effect waves-light btn-primary mt-3 mb-4 btn-return"
                (click)="redirectToPortal()">
                <b>Return to Parent Portal</b></button>
        </div>
        <div class="img-container">
            <img src="./assets/images/appointment-booked-logo.png" alt="padlock" />
        </div>
    </div>
</ng-container>

<ng-container *ngIf="mentorAppointment === null && contract?.data?.contract?.status === 'sent'">
    <div class="contract-container p-5 w-100 justify-content-center align-items-center">
        <div class="flex-grow-1">
            <h1>Signing the Contract</h1>
            <h4 class="mb-4 text-muted">
                Dear {{parentFirstname}},<br> <br>

                Thank you for registering. We are excited to embark on this transformative journey with
                {{menteeFirstname}}. <br> <br>

                A contract has been sent to your registered email address. This agreement outlines our commitment to
                supporting {{menteeFirstname}}'s growth and development. Please sign it at your earliest convenience to
                schedule {{menteeFirstname}}'s first mentoring session with {{mentorName}}. <br> <br>

                We are here to assist you every step of the way and look forward to seeing the positive changes that
                will unfold. <br> <br>

                Warm regards,<br><br>

                <span>Ken</span> <br>
                Ken Rabow <br>
                Founder & CEO <br>
                World Wide Youth Mentoring Inc.
            </h4>
        </div>
        <div class="img-container">
            <img src="./assets/images/contract-logo.png" alt="padlock" />
        </div>
    </div>
</ng-container>

<ng-container
    *ngIf="mentorAppointment === null && contract?.data?.contract?.status === 'signed' && mentor?.emailAddress">
    <div class="d-flex flex-column flex-sm-row">
        <h1 class="d-inline mb-0 me-2">Scheduling</h1>
        <h3 class="d-inline mb-0 align-self-sm-center step-heading">Step 5/5</h3>
    </div>
    <div class="mt-4">
        <div class="row">
            <div class="col-12">
                <div class="p-4 form-box">
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <mya-plan-card [isCurrentPlanCard]="true" [plan]="selectedPlan"></mya-plan-card>
                        <h3 class="my-3">Choose {{menteeFirstname}}'s First Session Date and Time</h3>
                        <mya-appointment-datepicker *ngIf="serviceId && currentDate && mentor" [currentDate]="currentDate" [bookingStaffId]="mentor.bookingStaffId"
                            [serviceId]="serviceId" (appointmentSlotSelected)="appointmentSlotSelected($event)">
                        </mya-appointment-datepicker>
                        <div>
                            <div class="d-flex flex-wrap mt-3 selected-date-container" *ngIf="appointmentSlot">
                                <div class="d-flex align-items-center me-3">
                                    <i class="ri-calendar-event-line text-gray selected-icon"></i>
                                    <span class="ms-1 text-gray selected-text">Selected Date:</span>
                                    <span class="ms-1 selected-subtext"><b>
                                            {{appointmentSlot.date | date: 'MMMd, y'}}</b></span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="ri-time-line text-gray selected-icon"></i>
                                    <span class="ms-1 text-gray selected-text">Selected Time:</span>
                                    <span class="ms-1 selected-subtext">
                                        <b>{{appointmentSlot.date | date:'h:mma'}}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-12">
                <div class="d-flex flex-row-reverse">
                    <button type="button" class="btn waves-effect waves-light btn-primary btn-submit mt-4"
                        [disabled]="appointmentSlot === null" (click)="bookAppointment()">
                        Book Appointment
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
export class InternalUserRoles {
    public static Admin = "Admin";
    public static Consultant = "Consultant";
    public static ResidentLevel = "ResidentLevel";
    public static MasterLevel = "MasterLevel";
    public static FirstDegree = "FirstDegree";
    public static SecondDegree = "SecondDegree";

    public static getMentorRoles() : string[]{
        return [this.ResidentLevel, this.MasterLevel, this.FirstDegree, this.SecondDegree];
    }
}
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { HistoryAttachment, HistoryItem, HistoryTypeEnum, HistoryTypeLookup } from '@mya/models';
import { v4 as uuid } from 'uuid';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs';
import { LoaderService } from '../../../../services/loader.service';
import { HistoryService } from '../../../../services/history.service';
import { inlineReadOnlyEditorConfig } from '../../../../common/configs/angular-editor-configs';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-note-card',
  templateUrl: './note-card.component.html',
  styleUrls: ['./note-card.component.scss'],
})
export class NoteCardComponent implements OnDestroy {
  @Input() historyItem: HistoryItem | null = null;
  @Output() viewHistoryItem = new EventEmitter<HistoryItem>();
  editorConfig = inlineReadOnlyEditorConfig;
  subscriptions: Subs[] = [];

  get HistoryTypeLookup() {
    return HistoryTypeLookup;
  }

  get noteTypeClass() {
    switch (this.historyItem?.historyTypeId) {
      case HistoryTypeEnum.System:
        return 'note-system';
      case HistoryTypeEnum.Consultant:
        return 'note-consultant';
      case HistoryTypeEnum.Parent:
        return 'note-parent';
      case HistoryTypeEnum.Quote:
        return 'note-quote';
      case HistoryTypeEnum.Gcs:
        return 'note-gcs';
      case HistoryTypeEnum.Som:
        return 'note-som';
      case HistoryTypeEnum.Col:
        return 'note-col';
      default:
        return null;
    }
  }

  constructor(private loaderService: LoaderService, private historyService: HistoryService) { }

  viewNoteDetails() {
    if (this.historyItem) {
      this.viewHistoryItem.emit(this.historyItem);
    }
  }

  downloadAttachment(historyAttachment: HistoryAttachment) {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.historyService.getHistoryAttachmentFile(historyAttachment.id)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      })).subscribe((response: Blob) => {
        this.loaderService.hide(loaderIdentifier);
        const downloadUrl = URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = historyAttachment.filename;
        link.click();
        URL.revokeObjectURL(downloadUrl);
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

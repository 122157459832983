export enum OnBoardingWizard {
    SupportUsers,
    MenteeInfo,
    PlanSelection,
    PaymentInfo,
    Scheduling
}

export class Steps {
    public static OnBoardingWizard = OnBoardingWizard;
}
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Account, ExternalUser, InternalUser, PlanPricing, SmartCode, getMentorType } from '@mya/models';
import { PricingService } from '../../../../services/pricing.service';
import { AccountService } from '../../../../services/account.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss'],
})
export class PlanCardComponent implements OnInit, OnDestroy {
  @Input() plan: SmartCode | null = null;
  @Input() currentPlanId: string | null = null;
  @Input() isCurrentPlanCard = false;
  @Input() isReadOnly = false;
  @Output() planSelected = new EventEmitter<string>();
  planPricings: PlanPricing[] = [];
  account: Account | null = null;
  mentor: InternalUser | null = null;
  mentee: ExternalUser | null = null;
  subscriptions: Subs[] = [];

  get checkBoxId() {
    return `plan-card-checkbox${this.plan}`
  }

  get numberOfSessions(): number {
    if (this.plan) {
      const planPricing = this.planPricings.find(i => i.mentorType == getMentorType(this.mentor ?? null) && i.mentoringPlanId == this.plan?.id);

      if (planPricing) {
        return planPricing.sessionsPerWeek == null ? 1 : planPricing.sessionsPerWeek * 4;
      }
    }

    return 0;
  }

  get cost(): number {
    if (this.plan) {
      const planPricing = this.planPricings.find(i => i.mentorType == getMentorType(this.mentor ?? null) && i.mentoringPlanId == this.plan?.id);

      if (planPricing) {
        return planPricing.price;
      }
    }

    return 0;
  }

  constructor(private pricingService: PricingService, private accountService: AccountService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(account => {
      this.account = account;
    }));
    this.subscriptions.push(this.accountService.Mentee$.subscribe(user => {
      this.mentee = user;
    }));
    this.subscriptions.push(this.accountService.Mentor$.subscribe(user => {
      this.mentor = user;
    }));

    this.subscriptions.push(this.pricingService.PlanPricing$.subscribe(i => {
      this.planPricings = i;
    }));
  }

  selectPlan() {
    if (this.isReadOnly || this.plan == null)
      return;

    this.planSelected.emit(this.plan.id);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

<div class="modal fade mya-parent-time-scheduling-modal" [id]="elementId" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header d-flex align-items-center">
                <h4 class="modal-title">
                    Select Parent Time
                </h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <mya-appointment-datepicker *ngIf="mentor?.bookingStaffId && serviceId && currentDate" [currentDate]="currentDate"
                    [serviceId]="serviceId" [bookingStaffId]="mentor?.bookingStaffId ?? null"
                    (appointmentSlotSelected)="appointmentSlotSelected($event)">
                </mya-appointment-datepicker>
                <div class="d-flex mt-3" *ngIf="appointmentSlot">
                    <div class="d-flex align-items-center">
                        <i class="ri-calendar-event-line text-muted selected-icon"></i>
                        <span class="ms-1 text-muted selected-text">Selected Date:</span>
                        <span class="ms-1 selected-subtext"><b>
                                {{appointmentSlot.date | date: 'MMMd, y'}}</b></span>
                    </div>
                    <div class="d-flex ms-3 align-items-center">
                        <i class="ri-time-line text-muted selected-icon"></i>
                        <span class="ms-1 text-muted selected-text">Selected Time:</span>
                        <span class="ms-1 selected-subtext"><b>{{appointmentSlot.date | date:'h:mm a'}}</b></span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-outline-primary btn-cancel"
                    data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn waves-effect waves-light btn-primary btn-confirm"
                    [disabled]="appointmentSlot === null" (click)="createAppointment()">Confirm</button>
            </div>
        </div>
    </div>
</div>
<div class="main-wrapper mya-login h-100">
    <div class="auth-wrapper background-img h-100">
        <div class="row  h-100">
            <div class="col-12 col-md-6 col-lg-7 col-xl-8 d-flex justify-content-center align-items-center">
                <div class="mx-2">
                    <h1 class="display-2 text-white text-center">Welcome</h1>
                    <h1 class="text-center text-white">to the Parent Portal,<br> World Wide Youth Mentoring Inc.</h1>
                </div>
            </div>
            <div
                class="col-12 col-md-6 col-lg-5 col-xl-4 px-5 login-box d-flex flex-column justify-content-center align-items-center">
                <img src="./assets/images/logos/mya-logo-colored.svg" alt="logo" />
                <h1 class="mt-4">Parent Portal</h1>
                <h3 class="mb-4 text-muted">Select a Mentee to Continue</h3>
                <div class="p-4 mb-5 bg-body rounded mt-2 form-box">
                    <form [formGroup]="tenantform" (ngSubmit)="onSubmit()">
                        <div class="mb-3 mt-4" *ngFor="let tenant of tenants">
                            <mya-tenant-card [tenant]="tenant" [selectedTenant]="selectedTenant"
                                (tenantSelected)="tenantSelected($event)"></mya-tenant-card>
                        </div>
                        <hr class="hr mt-3" />

                        <button type="submit" class="btn waves-effect waves-light btn-primary w-100 mt-3 mb-4 btn-login"
                            [disabled]="!tenantform.valid"><b>Select Tenant</b></button>
                    </form>
                </div>
            </div>
        </div>

    </div>

</div>
export enum SmartTypes {
    FindUS = 1,
    Concerns = 2,
    MentoringProgram = 3,
    MentoringSystem = 4,
    State = 5,
    UserType = 6,
    ComputerOS = 7,
    PhoneOS = 8,
    Pronouns = 9,
    AppointmentCancelReason = 10,
    Country = 11,
    CountryCode = 12,
    MentoringPlan = 13
}
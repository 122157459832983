import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ExternalUser, SmartCode, SmartTypes, getUserType } from '@mya/models';
import { ModalConstant } from '../../../../common/constants/modal.constant';
import { LoaderService } from '../../../../services/loader.service';
import { ModalService } from '../../../../services/modal.service';
import { SmartTypesService } from '../../../../services/smart-types.service';
import { v4 as uuid } from 'uuid';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-support-member-view-modal',
  templateUrl: './support-member-view-modal.component.html',
  styleUrls: ['./support-member-view-modal.component.scss'],
})
export class SupportMemberViewModalComponent implements OnInit, OnDestroy {
  elementId = ModalConstant.SUPPORT_MEMBER_VIEW_MODAL;
  @Input() user: ExternalUser | null = null;
  @Output() editUser = new EventEmitter<ExternalUser>();
  subscriptions: Subs[] = [];
  userTypes: Record<string, SmartCode> = {};
  states: SmartCode[] = [];

  get initials() {
    return `${this.user?.firstName.charAt(0)}${this.user?.lastName.charAt(0)}`.toUpperCase();
  }

  get state() {
    return this.states.find(i => i.id == this.user?.mailingAddress?.stateId);
  }

  constructor(private modalService: ModalService,
    private loaderService: LoaderService,
    private smartTypesService: SmartTypesService) { }

  ngOnInit(): void {
    const loaderIdentifier = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.UserType, loaderIdentifier).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier);
      smartCodes?.forEach(smartCode => {
        this.userTypes[smartCode.id] = smartCode;
      });
    }));
    const loaderIdentifier2 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.State, loaderIdentifier2).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier2);
      this.states = smartCodes;
    }));
  }

  getUserType(user: ExternalUser | null) {
    return getUserType(user, this.userTypes);
  }

  edit() {
    if (this.user == null) return;
    this.editUser.emit(this.user);
    this.modalService.hide(ModalConstant.SUPPORT_MEMBER_VIEW_MODAL);
  }

  cancel() {
    this.modalService.hide(ModalConstant.SUPPORT_MEMBER_VIEW_MODAL);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

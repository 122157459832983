<div [id]="elementId" class="modal fade mya-parent-time-payment-modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header d-flex align-items-center">
                <h4 class="modal-title">
                    Parent Time Payment
                </h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="mb-0" *ngIf="parentTimeType">You have selected a
                    <b>{{ParentTimeTypeLookup[parentTimeType]}}</b> session with <b>{{mentorName}}</b>. Please confirm
                    your selection, as clicking 'Confirm' will charge the payment method displayed below. If you need to
                    change your payment method on file, please visit the payments section.</p>
                <p class="mt-2" *ngIf="parentTimePrice">Charge Amount: <b>${{parentTimePrice.price}}</b></p>
                <h5 class="mt-2">Payment Method:</h5>
                <mya-payment-method-card [readonly]="true"></mya-payment-method-card>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-outline-primary btn-cancel"
                    data-bs-dismiss="modal">
                    Close
                </button>
                <button type="button" class="btn waves-effect waves-light btn-primary btn-confirm"
                    (click)="confirm()">Confirm</button>
            </div>
        </div>
    </div>
</div>
export enum Gender {
    Male,
    Female,
    Other
}

export const GenderLookup: Record<Gender, string> = {
    [Gender.Male]: "Male",
    [Gender.Female]: "Female",
    [Gender.Other]: "Other",
};
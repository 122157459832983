import { Component, EventEmitter, Output } from '@angular/core';
import { ParentTimeTypes } from '@mya/models';
import { ModalConstant } from '../../../../common/constants/modal.constant';
import { ModalService } from '../../../../services/modal.service';

@Component({
  selector: 'mya-parent-time-selection-modal',
  templateUrl: './parent-time-selection-modal.component.html',
  styleUrls: ['./parent-time-selection-modal.component.scss'],
})
export class ParentTimeSelectionModalComponent {
  @Output() scheduleParentTime = new EventEmitter<ParentTimeTypes>();
  elementId = ModalConstant.PARENT_TIME_SELECTION_MODAL;

  constructor(private modalService: ModalService) { }

  get ParentTimeTypes() {
    return ParentTimeTypes;
  }

  processParentTimeRequest(parentTimeTypes: ParentTimeTypes) {
    this.scheduleParentTime.emit(parentTimeTypes);
    this.modalService.hide(ModalConstant.PARENT_TIME_SELECTION_MODAL);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { NgxStripeModule } from 'ngx-stripe';
import { BookingSharedModule } from '@mya/booking-shared';
import { AppointmentsComponent } from '../components/pages/appointments/appointments.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { SubscriptionComponent } from '../components/pages/subscription/subscription.component';
import { NotesComponent } from '../components/pages/notes/notes.component';
import { NoteCardComponent } from '../components/shared/cards/note-card/note-card.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PlanCardComponent } from '../components/shared/cards/plan-card/plan-card.component';
import { CancelPlanConfirmationModalComponent } from '../components/shared/modals/cancel-plan-confirmation-modal/cancel-plan-confirmation-modal.component';
import { PaymentComponent } from '../components/pages/payment/payment.component';
import { PaymentMethodCardComponent } from '../components/shared/cards/payment-method-card/payment-method-card.component';
import { ParentTimeSelectionModalComponent } from '../components/shared/modals/parent-time-selection-modal/parent-time-selection-modal.component';
import { ParentTimeSchedulingModalComponent } from '../components/shared/modals/parent-time-scheduling-modal/parent-time-scheduling-modal.component';
import { ParentTimePaymentModalComponent } from '../components/shared/modals/parent-time-payment-modal/parent-time-payment-modal.component';
import { ChangePasswordModalComponent } from '../components/shared/modals/change-password-modal/change-password-modal.component';
import { OnboardingWizardComponent } from '../components/pages/onboarding-wizard/onboarding-wizard.component';
import { SupportTeamComponent } from '../components/shared/onboarding-steps/support-team/support-team.component';
import { AddMemberCardComponent } from '../components/shared/cards/add-member-card/add-member-card.component';
import { MemberCardComponent } from '../components/shared/cards/member-card/member-card.component';
import { SupportMemberDetailsComponent } from '../components/shared/onboarding-steps/support-member-details/support-member-details.component';
import { SupportMemberViewModalComponent } from '../components/shared/modals/support-member-view-modal/support-member-view-modal.component';
import { DeleteUserConfirmationModalComponent } from '../components/shared/modals/delete-user-confirmation-modal/delete-user-confirmation-modal.component';
import { MenteeDetailsComponent } from '../components/shared/onboarding-steps/mentee-details/mentee-details.component';
import { PlanSelectionComponent } from '../components/shared/onboarding-steps/plan-selection/plan-selection.component';
import { GoogleMapsModule } from "@angular/google-maps";
import { PaymentsComponent } from '../components/shared/onboarding-steps/payments/payments.component';
import { ScheduleAppointmentComponent } from '../components/shared/onboarding-steps/schedule-appointment/schedule-appointment.component';
import { NotesViewComponent } from '../components/shared/views/notes-view/notes-view.component';
import { TenantSelectionComponent } from '../components/pages/tenant-selection/tenant-selection.component';
import { TenantCardComponent } from '../components/shared/cards/tenant-card/tenant-card.component';

@NgModule({
  declarations: [
    MainLayoutComponent,
    AppointmentsComponent,
    NotesComponent,
    SubscriptionComponent,
    NoteCardComponent,
    PlanCardComponent,
    CancelPlanConfirmationModalComponent,
    PaymentComponent,
    PaymentMethodCardComponent,
    ParentTimeSelectionModalComponent,
    ParentTimeSchedulingModalComponent,
    ParentTimePaymentModalComponent,
    ChangePasswordModalComponent,
    OnboardingWizardComponent,
    SupportTeamComponent,
    MemberCardComponent,
    SupportMemberDetailsComponent,
    SupportMemberViewModalComponent,
    DeleteUserConfirmationModalComponent,
    MenteeDetailsComponent,
    AddMemberCardComponent,
    PlanSelectionComponent,
    PaymentsComponent,
    NotesViewComponent,
    ScheduleAppointmentComponent,
    TenantSelectionComponent,
    TenantCardComponent],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    BookingSharedModule,
    ReactiveFormsModule,
    IMaskModule,
    NgxStripeModule,
    FullCalendarModule,
    AngularEditorModule,
    FormsModule,
    GoogleMapsModule
  ]
})
export class LayoutModule { }

import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BookingAppointmentSlot, BookingService } from '@mya/booking-shared';
import { APP_CONFIG } from '@mya/configuration';
import { Account, adjustDateByTimeZone, Appointment, AppointmentTypeEnum, CreateParentTimeAppointmentRequest, ExternalUser, InternalUser, ParentTimeToken, ParentTimeTypes, ReferenceDataTypes, ReferenceItem } from '@mya/models';
import { ModalConstant } from '../../../../common/constants/modal.constant';
import { AccountService } from '../../../../services/account.service';
import { AppointmentService } from '../../../../services/appointment.service';
import { LoaderService } from '../../../../services/loader.service';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { DateTimeService } from 'apps/support/src/app/services/date-time.service';
import { ReferenceDataService } from 'apps/support/src/app/services/reference-data.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-parent-time-scheduling-modal',
  templateUrl: './parent-time-scheduling-modal.component.html',
  styleUrls: ['./parent-time-scheduling-modal.component.scss'],
})
export class ParentTimeSchedulingModalComponent implements OnInit, OnDestroy {
  @Output() parentTimeScheduled = new EventEmitter();
  @Input() get parentTimeToken(): ParentTimeToken | null { return this._parentTimeToken; }
  set parentTimeToken(value: ParentTimeToken | null) {
    this._parentTimeToken = value;
    this.appointmentSlot = null;
    this.getBookingService();
  }

  subscriptions: Subs[] = [];
  accountCreator: ExternalUser | null = null;
  mentee: ExternalUser | null = null;
  mentor: InternalUser | null = null;
  overriddenDate: Date | null = null;
  timeZones: ReferenceItem[] = [];
  serviceId: string;
  private _parentTimeToken: ParentTimeToken | null = null;

  elementId = ModalConstant.PARENT_TIME_SCHEDULING_MODAL;
  account: Account | null = null;
  appointmentSlot: BookingAppointmentSlot | null = null;
  meetingDuration = 0;

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(@Inject(APP_CONFIG) private appConfig: any,
    private accountService: AccountService,
    private bookingService: BookingService,
    private loaderService: LoaderService,
    private appointmentService: AppointmentService,
    private referenceDataService: ReferenceDataService,
    dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
    this.serviceId = this.appConfig.booking.services.mentor30mins;
  }

  ngOnInit(): void {
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(account => {
      this.account = account;
    }));
    this.subscriptions.push(this.accountService.AccountCreator$.subscribe(user => {
      this.accountCreator = user;
    }));
    this.subscriptions.push(this.accountService.Mentee$.subscribe(user => {
      this.mentee = user;
    }));
    this.subscriptions.push(this.accountService.Mentor$.subscribe(user => {
      this.mentor = user;
    }));

    this.getBookingService();
    this.subscriptions.push(this.bookingService.CurrentBookingService$.subscribe(service => {
      if (service) {
        this.meetingDuration = moment.duration(service.defaultDuration).asMinutes();
      }
    }));
    this.subscriptions.push(this.referenceDataService.ReferenceData$.subscribe(referenceData => {
      if (referenceData != null) {
        const references = JSON.parse(referenceData);
        this.timeZones = references[ReferenceDataTypes.TimeZones];
      }
    }));
  }

  getBookingService() {
    if (this.serviceId) {
      this.bookingService.GetService(this.serviceId);
    }
  }

  appointmentSlotSelected(date: BookingAppointmentSlot) {
    this.appointmentSlot = date;
  }

  createAppointment() {
    if (this.appointmentSlot && this.account && this.mentor && this.mentee && this.accountCreator && this.parentTimeToken) {
      const appointmentSlot = this.appointmentSlot;
      const timeZone = this.accountCreator.timeZone as string;

      const appointmentId = uuid();
      const endDateTime = new Date(appointmentSlot.date);
      endDateTime.setMinutes(endDateTime.getMinutes() + this.meetingDuration);

      const request: CreateParentTimeAppointmentRequest = {
        appointment: <Appointment>{
          id: appointmentId,
          accountId: this.account.id,
          durationInMinutes: this.meetingDuration,
          title: `PT`,
          startTime: appointmentSlot.date,
          appointmentTypeId: AppointmentTypeEnum.ParentTime,
          meetingUrl: '',
          bookingAppointmentId: '',
        },
        appointmentExternalUsers: [{
          id: uuid(),
          appointmentId: appointmentId,
          externalUserId: this.accountCreator.id as string,
          externalUser: null
        }],
        appointmentInternalUsers: [{
          id: uuid(),
          appointmentId: appointmentId,
          internalUserId: this.mentor.id as string,
          internalUser: null
        }],
        bookingAppointment: {
          startDateTime: {
            dateTime: adjustDateByTimeZone(appointmentSlot.date, timeZone, this.timeZones),
            timeZone: timeZone
          },
          endDateTime: {
            dateTime: adjustDateByTimeZone(endDateTime, timeZone, this.timeZones),
            timeZone: timeZone
          },
          serviceId: this.serviceId,
          staffMemberIds: [appointmentSlot.staffId]
        },
        customers: [{
          customerId: this.accountCreator.bookingCustomerId,
          emailAddress: this.accountCreator.emailAddress,
          name: this.accountCreator.firstName
        }],
        parentTimeTokenId: this.parentTimeToken.id
      }

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.appointmentService.createParentTimeAppointment(request, loaderIdentifier).subscribe(i => {
        this.loaderService.hide(loaderIdentifier);
        this.parentTimeScheduled.emit();
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PickerProgressBarService {
  private readonly isVisible = new BehaviorSubject<boolean>(false);
  public readonly IsVisible$: Observable<boolean> = this.isVisible;

  show() {
    this.isVisible.next(true);
  }

  hide() {
    this.isVisible.next(false);
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PaymentService } from '../../../../services/payment.service';
import { LoaderService } from '../../../../services/loader.service';
import { v4 as uuid } from 'uuid';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-payment-method-card',
  templateUrl: './payment-method-card.component.html',
  styleUrls: ['./payment-method-card.component.scss'],
})
export class PaymentMethodCardComponent implements OnInit, OnDestroy {
  @Input() readonly = false;
  @Output() updatePaymentMethod = new EventEmitter();
  brand: string | null = null;
  last4: string | null = null;
  expMonth: number | null = null;
  expYear: number | null = null;
  subscriptions: Subs[] = [];

  constructor(private paymentService: PaymentService, private loaderService: LoaderService) { }

  ngOnInit(): void {
    const loaderIdentifier = uuid();
    this.subscriptions.push(this.paymentService.getPaymentMethod(loaderIdentifier).subscribe(result => {
      this.loaderService.hide(loaderIdentifier);
      this.brand = result.brand;
      this.last4 = result.last4;
      this.expMonth = result.expMonth;
      this.expYear = result.expYear;
    }));
  }

  update() {
    this.updatePaymentMethod.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tenant } from '@mya/models';

@Component({
  selector: 'mya-tenant-card',
  templateUrl: './tenant-card.component.html',
  styleUrls: ['./tenant-card.component.scss'],
})
export class TenantCardComponent implements OnInit {
  @Input() tenant: Tenant | null = null;
  @Input() selectedTenant: string | null = null;
  @Output() tenantSelected = new EventEmitter<Tenant>();

  constructor() { }

  ngOnInit(): void { }

  selectTenant() {
    if (this.tenant) {
      this.tenantSelected.emit(this.tenant);
    }
  }
}

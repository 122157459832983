import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { v4 as uuid } from 'uuid';
import { ExternalUserClaimTypes, SwitchTenantRequest, Tenant } from '@mya/models';
import { JwtService } from '../../../services/jwt.service';
import { Router } from '@angular/router';
import { LoaderService } from '../../../services/loader.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-tenant-selection',
  templateUrl: './tenant-selection.component.html',
  styleUrls: ['./tenant-selection.component.scss'],
})
export class TenantSelectionComponent implements OnInit, OnDestroy {
  tenant: string | null = null;
  plan: string | null = null;
  tenants: Tenant[] = [];
  currentTenant: string | null = null;
  subscriptions: Subs[] = [];

  get selectedTenant() {
    return this.tenantform.controls.tenant.value ?? null;
  }

  tenantform = this.formBuilder.group({
    tenant: [null as string | null, [Validators.required]]
  });

  constructor(private authenticationService: AuthenticationService,
    private jwtService: JwtService,
    private router: Router,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    const token = this.authenticationService.getToken();
    if (token) {
      const decodedToken: any = this.jwtService.DecodeToken(token);
      this.currentTenant = decodedToken[ExternalUserClaimTypes.Tenant];
      this.tenantform.controls.tenant.setValue(this.currentTenant);
    }

    this.plan = localStorage.getItem('plan');
    this.tenant = localStorage.getItem('tenant');

    if (this.currentTenant == this.tenant) {
      this.router.navigate(['/']);
    }

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.authenticationService.fetchTenants(loaderIdentifier).subscribe(result => {
      this.loaderService.hide(loaderIdentifier);

      this.tenants = result.tenants;
      if (this.tenant) {
        this.autoSelectTenant(this.tenant, this.tenants);
      }
    }));
  }

  autoSelectTenant(tenantId: string, tenants: Tenant[]) {
    const tenant = tenants.find(i => i.tenantId == tenantId);

    if (tenant) {
      const request: SwitchTenantRequest = {
        tenantId: tenantId
      }
      this.authenticationService.switchTenant(request);
    }
  }

  tenantSelected(tenant: Tenant) {
    this.tenantform.controls.tenant.setValue(tenant.tenantId);
  }

  onSubmit() {
    const selectedTenant = this.tenantform.controls.tenant.value ?? null;

    if (selectedTenant == null)
      return;

    if (this.currentTenant == selectedTenant) {
      this.router.navigate(['/']);
      return;
    }

    const request: SwitchTenantRequest = {
      tenantId: selectedTenant
    }
    this.authenticationService.switchTenant(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

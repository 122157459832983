<div class="container-fluid px-5 pt-5 h-100 mya-appointments overflow-y-auto">
    <div class="row h-100">
        <div class="col-12 mb-3 d-flex">
            <div>
                <h2 class="mb-0">{{mentee?.firstName + ' ' + mentee?.lastName}} &
                    {{accountCreator?.firstName + ' ' + accountCreator?.lastName}} Appointments with
                    {{mentor?.firstName + ' ' + mentor?.lastName}}</h2>
                <h4 class="mb-0">Remaining Paid Sessions in This Payment Cycle: {{remainingAppointments}}</h4>
            </div>
            <button type="button"
                class="btn-book-parent btn waves-effect waves-light btn-primary ms-auto cursor-pointer"
                (click)="openParentTimeSelectionModal()">
                <b>
                    <div class="d-flex align-items-center"> Book Parent Time
                        <div class="ms-2 d-flex flex-column">
                            <h6 class="mb-0 free-sessions"><span>Free Session(s)</span>: {{freeParentTimeSessions}}</h6>
                            <h6 class="mb-0 paid-sessions"><span>Paid Session(s)</span>: {{paidParentTimeSessions}}</h6>
                        </div>
                    </div>
                </b>
            </button>
        </div>
        <div class="col-12 p-5 calendar-container">
            <full-calendar #calendar [options]="calendarOptions"></full-calendar>
        </div>
    </div>
</div>

<mya-parent-time-selection-modal (scheduleParentTime)="parentTimeSelected($event)"></mya-parent-time-selection-modal>
<mya-parent-time-scheduling-modal [parentTimeToken]="parentTimeToken"
    (parentTimeScheduled)="parentTimeScheduled()"></mya-parent-time-scheduling-modal>
<mya-parent-time-payment-modal [parentTimeType]="parentTimeType"
    (parentTimeTokenGenerated)="scheduleParentTime($event)"></mya-parent-time-payment-modal>
import { Component, OnDestroy } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { SendPasswordResetEmailRequest } from '@mya/models';
import { v4 as uuid } from 'uuid';
import { LoaderService } from '../../../services/loader.service';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnDestroy {
  subscriptions: Subs[] = [];

  forgotPasswordForm = this.formBuilder.group({
    emailAddress: ['', [Validators.required, Validators.email]]
  });

  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder) { }

  login() {
    this.router.navigate(['/login']);
  }

  onSubmit() {
    if (this.forgotPasswordForm.controls.emailAddress.value) {
      const request: SendPasswordResetEmailRequest = {
        emailAddress: this.forgotPasswordForm.controls.emailAddress.value
      };

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.authenticationService.sendPasswordResetRequest(request, loaderIdentifier)
        .subscribe(() => {
          this.loaderService.hide(loaderIdentifier);
          this.forgotPasswordForm.controls.emailAddress.setValue('');
          toastr.success('Email Sent');
        }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Account, ExternalUser, ExternalUserClaimTypes, SmartCode, SmartTypes, getUserType } from '@mya/models';
import { AccountService } from '../../../../services/account.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { JwtService } from '../../../../services/jwt.service';
import { LoaderService } from '../../../../services/loader.service';
import { SmartTypesService } from '../../../../services/smart-types.service';
import { v4 as uuid } from 'uuid';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-member-card',
  templateUrl: './member-card.component.html',
  styleUrls: ['./member-card.component.scss'],
})
export class MemberCardComponent implements OnInit, OnDestroy {
  @Input() user: ExternalUser | null = null;
  @Output() editUser = new EventEmitter<ExternalUser>();
  @Output() deleteUser = new EventEmitter<ExternalUser>();
  @Output() viewUser = new EventEmitter<ExternalUser>();
  linkedAccount: Account | null = null;
  userTypes: Record<string, SmartCode> = {};
  email: string | null = null;
  subscriptions: Subs[] = [];

  get initials() {
    return `${this.user?.firstName.charAt(0)}${this.user?.lastName.charAt(0)}`.toUpperCase();
  }

  get isCurrentUser() {
    return this.user !== null && this.email?.toLowerCase().trim() === this.user?.emailAddress?.toLowerCase().trim();
  }

  constructor(private authenticationService: AuthenticationService,
    private accountService: AccountService,
    private jwtService: JwtService,
    private loaderService: LoaderService,
    private smartTypesService: SmartTypesService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(i => {
      this.linkedAccount = i;
    }));
    const token = this.authenticationService.getToken();
    if (token) {
      const decodedToken: any = this.jwtService.DecodeToken(token);
      this.email = decodedToken[ExternalUserClaimTypes.Email];
    }

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.UserType, loaderIdentifier).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier);
      smartCodes?.forEach(smartCode => {
        this.userTypes[smartCode.id] = smartCode;
      });
    }));
  }

  edit(user: ExternalUser, event: Event) {
    event.stopPropagation();
    this.editUser.emit(user);
  }

  delete(user: ExternalUser, event: Event) {
    event.stopPropagation();
    this.deleteUser.emit(user);
  }

  getUserType(user: ExternalUser | null) {
    return getUserType(user, this.userTypes);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

<ng-container *ngIf="!editMode; else elseBlock">
    <div class="d-flex flex-column flex-sm-row">
        <h1 class="d-inline mb-0 me-2">Support Team</h1>
        <h3 class="d-inline mb-0 align-self-sm-center step-heading">Step 1/5</h3>
    </div>
    <div class="d-flex flex-wrap mt-4 card-container">
        <mya-add-member-card (addSupportUser)="editMode = true"></mya-add-member-card>

        <ng-container *ngFor="let accountAccess of linkedAccount?.accountAccesses">
            <mya-member-card
                *ngIf="accountAccess.externalUser && userTypes[accountAccess.externalUser.userTypeId].code !== 'MT'"
                [user]="accountAccess.externalUser" (editUser)="editUser($event)"
                (viewUser)="viewUser.emit($event)" (deleteUser)="deleteUser.emit($event)"></mya-member-card>
        </ng-container>
    </div>
    <div class="d-flex flex-row-reverse">
        <button type="button" class="btn waves-effect waves-light btn-primary btn-next mt-4" (click)="next.emit()">
            Next
        </button>
    </div>

</ng-container>

<ng-template #elseBlock>
    <mya-support-member-details [user]="selectedUser" (closeEditMode)="closeEditMode()"></mya-support-member-details>
</ng-template>
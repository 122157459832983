export enum AccountStatus {
    New,
    ConsultDecline,
    Onboarding,
    ContractPending,
    Active,
    PaymentLate,
    Suspended,
    PendingCancellation,
    ResumePending,
    Cancelled,
    TrialEnded,
    ConsultationCanceled
}

export const AccountStatusLookup: Record<AccountStatus, string> = {
    [AccountStatus.New]: "New Account",
    [AccountStatus.ConsultDecline]: "Declined by Consultant",
    [AccountStatus.ContractPending]: "Contract Pending",
    [AccountStatus.Onboarding]: "Onboarding Pending",
    [AccountStatus.Active]: "Active",
    [AccountStatus.PaymentLate]: "Payment Late",
    [AccountStatus.Suspended]: "Suspended",
    [AccountStatus.PendingCancellation]: "Cancellation Pending",
    [AccountStatus.ResumePending]: "Resume Plan Pending",
    [AccountStatus.Cancelled]: "Cancelled",
    [AccountStatus.TrialEnded]: "Trial Ended",
    [AccountStatus.ConsultationCanceled]: "Consultation Canceled"
};
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { AppointmentExternalUsers, AppointmentInternalUsers, GetAppointmentsByAccountResult, GetAttendeesByAppointmentResult, Appointment, GetAllAppointmentsResult, ParentTimeTypes, GetParentTimeTokenResult, GetUpcomingAppointmentDetailsResult, CreateFirstMentorAppointmentRequest, CreateFirstMentorAppointmentResult, CreateParentTimeAppointmentRequest, CreateParentTimeAppointmentResult } from '@mya/models';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { LoaderService } from './loader.service';
import { v4 as uuid } from 'uuid';
import { DatePipe } from '@angular/common';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Injectable({
  providedIn: 'root'
})
export class AppointmentService implements OnDestroy {
  baseApiUrl = '';
  subscriptions: Subs[] = [];
  private readonly appointments = new BehaviorSubject<Appointment[]>([]);
  public readonly Appointments$: Observable<Appointment[]> = this.appointments;

  private readonly appointmentInternalUsers = new BehaviorSubject<AppointmentInternalUsers[]>([]);
  public readonly AppointmentInternalUsers$: Observable<AppointmentInternalUsers[]> = this.appointmentInternalUsers;

  private readonly appointmentExternalUsers = new BehaviorSubject<AppointmentExternalUsers[]>([]);
  public readonly AppointmentExternalUsers$: Observable<AppointmentExternalUsers[]> = this.appointmentExternalUsers;


  constructor(private http: HttpClient,
    private loaderService: LoaderService,
    private datePipe: DatePipe,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.appointment}api/appointment`
  }

  createFirstMentorAppointment(request: CreateFirstMentorAppointmentRequest, loaderIdentifier: string): Observable<CreateFirstMentorAppointmentResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post<CreateFirstMentorAppointmentResult>(`${this.baseApiUrl}/create-first-mentor-appointment`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        toastr.error('An error has occured');
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  createParentTimeAppointment(request: CreateParentTimeAppointmentRequest, loaderIdentifier: string): Observable<CreateParentTimeAppointmentResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post<CreateParentTimeAppointmentResult>(`${this.baseApiUrl}/create-parent-time-appointment`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        toastr.error('An error has occured');
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  getAppointmentsByAccount(): void {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetAppointmentsByAccountResult>(`${this.baseApiUrl}/get-appointments-by-account`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe(data => {
        this.appointments.next(data.appointments);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  getAttendeesByAppointment(appointmentId: string): void {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetAttendeesByAppointmentResult>(`${this.baseApiUrl}/get-attendees-by-appointment/${appointmentId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe(data => {
        this.appointmentInternalUsers.next(data.appointmentInternalUsers);
        this.appointmentExternalUsers.next(data.appointmentExternalUsers);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  getAppointments(startDate: Date, endDate: Date): void {
    const loaderIdentifier = uuid();
    const formattedStartDate = this.datePipe.transform(new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000), 'YYYY-MM-ddTHH:mm:ss');
    const formatterdEndDate = this.datePipe.transform(new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000), 'YYYY-MM-ddTHH:mm:ss')
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetAllAppointmentsResult>(`${this.baseApiUrl}/get-appointments/${formattedStartDate}/${formatterdEndDate}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe(data => {
        this.appointments.next(data.appointments);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  getParentTimeToken(parentTimeType: ParentTimeTypes, loaderIdentifier: string): Observable<GetParentTimeTokenResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.get<GetParentTimeTokenResult>(`${this.baseApiUrl}/get-parent-time-token/${parentTimeType}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  getUpcomingAppointmentDetails(loaderIdentifier: string): Observable<GetUpcomingAppointmentDetailsResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.get<GetUpcomingAppointmentDetailsResult>(`${this.baseApiUrl}/get-upcoming-appointment-details`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

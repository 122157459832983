import { formatDate } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Loader } from '@googlemaps/js-api-loader';
import { APP_CONFIG } from '@mya/configuration';
import { Account, AddSupportUserToAccountRequest, EditExternalUserRequest, ExternalUser, ExternalUserClaimTypes, Gender, GeocoderAddressTypes, InternalUser, ReferenceDataTypes, ReferenceItem, SmartCode, SmartTypes } from '@mya/models';
import { AccountService } from '../../../../services/account.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { ExternalUserService } from '../../../../services/external-user.service';
import { JwtService } from '../../../../services/jwt.service';
import { LoaderService } from '../../../../services/loader.service';
import { SmartTypesService } from '../../../../services/smart-types.service';
import { v4 as uuid } from 'uuid';
import { ReferenceDataService } from '../../../../services/reference-data.service';
import { DateTimeService } from 'apps/support/src/app/services/date-time.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-support-member-details',
  templateUrl: './support-member-details.component.html',
  styleUrls: ['./support-member-details.component.scss'],
})
export class SupportMemberDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("addressLine1") addressLine1Field: ElementRef | null = null;
  @Input() user: ExternalUser | null = null;
  @Output() closeEditMode = new EventEmitter();
  subscriptions: Subs[] = [];
  userTypeLookup: SmartCode[] = [];
  states: SmartCode[] = [];
  countries: Record<string, SmartCode> = {};
  countryLookup: SmartCode[] = [];
  countryCodes: SmartCode[] = [];
  countryCodeLookup: Record<string, SmartCode> = {};
  linkedAccount: Account | null = null;
  accountCreator: ExternalUser | null = null;
  email: string | null = null;
  placeLibrary: google.maps.PlacesLibrary | null = null;
  autoCompleteListener: google.maps.MapsEventListener | null = null;
  timeZones: ReferenceItem[] = [];
  enableAddressField = true;
  overriddenDate: Date | null = null;
  phoneMask: any = {
    mask: '(000) 000-0000'
  };

  get isPrimarySupportMember() {
    return this.accountCreator?.id != null && this.accountCreator?.id == this.user?.id;
  }

  get isNewUser() {
    return this.user == null;
  }

  get isOtherUser() {
    return this.userTypeLookup.find(i => i.id == this.form.controls.userType.value)?.code === 'O';
  }

  get stateLookup() {
    return this.states.filter(i => i.subTypeSmartCodeId == this.form.controls.country.value);
  }

  get isNorthAmericanCountry() {
    return this.countries[this.form.controls.country.value ?? '']?.code?.toLowerCase() == 'ca' || this.countries[this.form.controls.country.value ?? '']?.code?.toLowerCase() == 'us';
  }

  get stateTitle() {
    return this.countries[this.form.controls.country.value ?? '']?.code?.toLowerCase() == 'ca' ? 'Province' : 'State';
  }

  get zipTitle() {
    return this.countries[this.form.controls.country.value ?? '']?.code?.toLowerCase() == 'ca' ? 'Postal Code' : 'ZIP Code';
  }

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  form = this.formBuilder.group({
    firstName: [null as string | null, [Validators.required, Validators.minLength(2)]],
    lastName: [null as string | null, [Validators.required, Validators.minLength(2)]],
    address1: [null as string | null, []],
    address2: [null as string | null, []],
    city: [null as string | null, []],
    state: [null as string | null, []],
    country: [null as string | null, []],
    zip: [null as string | null, []],
    timeZone: [null as string | null, []],
    phoneNumber: [null as string | null, [Validators.required]],
    countryCode: [null as string | null, [Validators.required]],
    receiveTexts: [true, [Validators.required]],
    email: [null as string | null, [Validators.required, Validators.email]],
    dateOfBirth: [null as string | null, []],
    userType: [null as string | null, [Validators.required]],
    otherUserType: [null as string | null, []],
  })

  constructor(@Inject(APP_CONFIG) private appConfig: any,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private smartTypesService: SmartTypesService,
    private accountService: AccountService,
    private externalUserService: ExternalUserService,
    private authenticationService: AuthenticationService,
    private jwtService: JwtService,
    private referenceDataService: ReferenceDataService,
    private changeDetectorRef: ChangeDetectorRef,
    dateTimeService: DateTimeService) {
      this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => {
      this.overriddenDate = date;
    }));
  }

  ngOnInit(): void {
    const token = this.authenticationService.getToken();
    if (token) {
      const decodedToken: any = this.jwtService.DecodeToken(token);
      this.email = decodedToken[ExternalUserClaimTypes.Email];
    }
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(i => {
      this.linkedAccount = i;
      this.form.controls.timeZone.setValidators(this.isPrimarySupportMember ? [Validators.required] : []);
    }));
    this.subscriptions.push(this.accountService.AccountCreator$.subscribe(user => {
      this.accountCreator = user;
    }));

    const loaderIdentifier1 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.UserType, loaderIdentifier1).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier1);
      this.userTypeLookup = smartCodes;
    }));
    const loaderIdentifier2 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.State, loaderIdentifier2).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier2);
      this.states = smartCodes;
    }));
    const loaderIdentifier3 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.Country, loaderIdentifier3).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier3);
      this.countryLookup = smartCodes;
      smartCodes?.forEach(smartCode => {
        this.countries[smartCode.id] = smartCode;
      });

      const country = this.countryLookup.find(i => i.code.toLowerCase() == 'us');
      if (country) {
        this.setCountryValue(country.id);
      }
      this.initializeGoogleAutocomplete();
    }));

    const loaderIdentifier4 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.CountryCode, loaderIdentifier4).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier4);
      this.countryCodes = smartCodes;
      smartCodes?.forEach(smartCode => {
        this.countryCodeLookup[smartCode.id] = smartCode;
      });

      const usCountryCode = this.countryCodes.find(i => i.code.toLowerCase() == 'us')?.id ?? null;
      if (this.form.controls.countryCode.value == null && usCountryCode) {
        this.form.controls.countryCode.setValue(usCountryCode);
        this.form.controls.phoneNumber.setValidators([Validators.required, Validators.minLength(14)]);
        this.form.controls.phoneNumber.updateValueAndValidity();
      }
    }));

    this.subscriptions.push(this.referenceDataService.ReferenceData$.subscribe(referenceData => {
      if (referenceData != null) {
        const references = JSON.parse(referenceData);
        this.timeZones = references[ReferenceDataTypes.TimeZones];
      }
    }));

    if (this.user) {
      this.form.patchValue({
        dateOfBirth: this.user.dateOfBirth ? formatDate(this.user.dateOfBirth, 'yyyy-MM-dd', 'en') : null,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.emailAddress,
        userType: this.user.userTypeId,
        otherUserType: this.user.otherUserType,
        address1: this.user.mailingAddress?.addressLine1,
        address2: this.user.mailingAddress?.addressLine2,
        city: this.user.mailingAddress?.city,
        state: this.user.mailingAddress?.stateId,
        country: this.user.mailingAddress?.countryId,
        zip: this.user.mailingAddress?.zipCode,
        phoneNumber: this.user.phoneNumber,
        countryCode: this.user.countryCodeId,
        receiveTexts: this.user.receiveTexts,
        timeZone: this.user.timeZone
      });
    }
  }

  ngAfterViewInit(): void {
    new Loader({
      apiKey: this.appConfig.googleMapsApiKey,
      version: "weekly",
      libraries: ["places"]
    })
      .importLibrary('places')
      .then((library) => {
        this.placeLibrary = library;
        this.initializeGoogleAutocomplete();
      })
      .catch((e) => {
        console.error(e);
      });
  }

  initializeGoogleAutocomplete() {
    const country = this.countryLookup.find(i => i.id == this.form.controls.country.value) ?? null;
    if (this.addressLine1Field && this.placeLibrary && country) {
      google.maps.event.clearListeners(this.addressLine1Field.nativeElement as HTMLInputElement, "place_changed");
      this.enableAddressField = false;
      this.changeDetectorRef.detectChanges();
      this.enableAddressField = true;
      this.changeDetectorRef.detectChanges();
      const autoCompleteField = new google.maps.places.Autocomplete(this.addressLine1Field.nativeElement as HTMLInputElement, {
        componentRestrictions: { country: [country.code] },
        fields: ["address_components", "formatted_address"],
        types: ["address"]
      });

      this.autoCompleteListener = autoCompleteField.addListener("place_changed", () => this.autocompleteAddress(autoCompleteField?.getPlace() ?? null));
    }
  };

  autocompleteAddress(place: google.maps.places.PlaceResult | null) {
    if (place) {
      if (place.address_components && place.address_components.length > 0) {
        place.address_components.forEach(addressComponent => this.loadAddressValues(addressComponent, this.form));
        this.autoCompleteAddressLine1(place);
      }
    }
  }

  loadAddressValues(addressComponent: google.maps.GeocoderAddressComponent, form: FormGroup) {
    if (addressComponent.types.includes(GeocoderAddressTypes.AdministrativeAreaLevel1)) {
      const state = this.stateLookup.find(s => s.code == addressComponent.short_name || s.label == addressComponent.long_name);
      if (state) {
        form.get('state')?.setValue(state.id);
      }
    }

    if (addressComponent.types.includes(GeocoderAddressTypes.PostalCode)) {
      form.get('zip')?.setValue(addressComponent.long_name);
    }

    if (addressComponent.types.includes(GeocoderAddressTypes.Locality)) {
      form.get('city')?.setValue(addressComponent.long_name);
    }
  }

  autoCompleteAddressLine1(place: google.maps.places.PlaceResult) {
    const line1 = place.formatted_address?.split(",")[0];
    if (line1) {
      this.form.controls.address1.setValue(line1);
    }
  }

  changeState(e: any) {
    this.form.controls.state?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  changeCountry(e: SmartCode) {
    this.setCountryValue(e.id);
    this.resetAddressFields();
    this.initializeGoogleAutocomplete();
  }

  changeCountryCode(countryCode: SmartCode) {
    this.form.controls.countryCode.setValue(countryCode.id, {
      onlySelf: true,
    });

    if (countryCode.label == "1") {
      this.phoneMask = {
        mask: '(000) 000-0000'
      };
      this.form.controls.phoneNumber.setValidators([Validators.required, Validators.minLength(14)]);
      this.form.controls.phoneNumber.updateValueAndValidity();
    } else {
      this.phoneMask = {
        mask: '0000000000000000'
      };
      this.changeDetectorRef.detectChanges();
      if (this.form.controls.phoneNumber.value) {
        this.form.controls.phoneNumber.setValue(this.form.controls.phoneNumber.value.replace(/[^0-9]/g, ''));
        this.form.controls.phoneNumber.setValidators([Validators.required]);
        this.form.controls.phoneNumber.updateValueAndValidity();
      }
    }
  }

  changeTimeZone(e: any) {
    this.form.controls.timeZone.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  resetAddressFields() {
    this.form.controls.address1?.setValue(null, { onlySelf: true });
    this.form.controls.address2?.setValue(null, { onlySelf: true });
    this.form.controls.city?.setValue(null, { onlySelf: true });
    this.form.controls.state?.setValue(null, { onlySelf: true });
    this.form.controls.zip?.setValue(null, { onlySelf: true });
  }

  setCountryValue(country: string) {
    this.form.controls.country.setValue(country);
    if (this.isNorthAmericanCountry) {
      this.form.controls.state.enable();
      this.form.controls.zip.enable();
    } else {
      this.form.controls.state.reset();
      this.form.controls.state.disable();
      this.form.controls.zip.reset();
      this.form.controls.zip.disable();
    }
  }

  changeUserType(e: any) {
    this.form.controls.userType?.setValue(e.target.value, {
      onlySelf: true,
    });

    const userType = this.userTypeLookup.find(i => i.id == this.form.controls.userType.value) ?? null;
    if (userType?.code == 'O') {
      this.form.controls.otherUserType.setValidators(Validators.required);
    } else {
      this.form.controls.otherUserType.removeValidators(Validators.required);
    }

    this.form.controls.otherUserType.updateValueAndValidity();
  }

  submit() {
    if (this.form == null || this.linkedAccount?.id == null)
      return;

    this.form.markAllAsTouched();

    if (this.form.valid) {

      if (this.user == null) {
        const user: ExternalUser = <ExternalUser>{
          id: uuid(),
          emailAddress: this.form.controls.email.value as string,
          dateOfBirth: new Date(this.form.controls.dateOfBirth.value as string),
          gender: Gender.Other,
          firstName: this.form.controls.firstName.value as string,
          lastName: this.form.controls.lastName.value as string,
          userTypeId: this.userTypeLookup.find(i => i.id == this.form.controls.userType.value)?.id as string,
          otherUserType: this.form.controls.otherUserType.value,
          phoneNumber: this.form.controls.phoneNumber.value?.replace(/[^0-9]/g, '') ?? null,
          countryCodeId: this.countryCodes.find(i => i.id == this.form.controls.countryCode.value)?.id ?? null,
          receiveTexts: this.form.controls.receiveTexts.value as boolean,
          timeZone: this.form.controls.timeZone.value,
          creationDate: this.currentDate,
          bookingCustomerId: '',
          mailingAddress: {
            addressLine1: this.form.controls.address1.value as string,
            addressLine2: this.form.controls.address2.value as string,
            city: this.form.controls.city.value as string,
            stateId: this.isNorthAmericanCountry ? (this.stateLookup.find(i => i.id == this.form.controls.state.value) ?? null)?.id : null,
            countryId: this.countryLookup.find(i => i.id == this.form.controls.country.value)?.id ?? null,
            zipCode: this.isNorthAmericanCountry ? (this.form.controls.zip.value as string) : null,
          },
          receiveScheduleEmail: false,
        };

        user.id = uuid();
        const request: AddSupportUserToAccountRequest = {
          user: user
        };

        const loaderIdentifier = uuid();
        this.subscriptions.push(this.externalUserService.addSupportUserToAccount(request, loaderIdentifier).subscribe((i) => {
          this.accountService.getAccount(true);
          this.loaderService.hide(loaderIdentifier);
          this.closeEditMode.emit(true);
        }));

      } else {
        this.user.dateOfBirth = new Date(this.form.controls.dateOfBirth.value as string);
        this.user.firstName = this.form.controls.firstName.value as string;
        this.user.lastName = this.form.controls.lastName.value as string;
        this.user.userTypeId = this.userTypeLookup.find(i => i.id == this.form.controls.userType.value)?.id as string;
        this.user.otherUserType = this.form.controls.otherUserType.value;
        this.user.phoneNumber = this.form.controls.phoneNumber.value?.replace(/[^0-9]/g, '') ?? null;
        this.user.countryCodeId = this.countryCodes.find(i => i.id == this.form.controls.countryCode.value)?.id ?? null;
        this.user.receiveTexts = this.form.controls.receiveTexts.value as boolean;
        this.user.timeZone = this.form.controls.timeZone.value;
        this.user.mailingAddress = {
          addressLine1: this.form.controls.address1.value as string,
          addressLine2: this.form.controls.address2.value as string,
          city: this.form.controls.city.value as string,
          stateId: this.isNorthAmericanCountry ? ((this.stateLookup.find(i => i.id == this.form.controls.state.value) ?? null)?.id ?? null) : null,
          countryId: this.countryLookup.find(i => i.id == this.form.controls.country.value)?.id ?? null,
          zipCode: this.isNorthAmericanCountry ? (this.form.controls.zip.value as string) : null
        };

        const request: EditExternalUserRequest = {
          user: this.user
        };

        const loaderIdentifier = uuid();
        this.subscriptions.push(this.externalUserService.editExternalUser(request, loaderIdentifier).subscribe((i) => {
          this.accountService.getAccount(true);
          this.loaderService.hide(loaderIdentifier);
          this.closeEditMode.emit(true);
        }));
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
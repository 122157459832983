import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { BookingServiceDto, BookingStaffAvailabilityItem, BookingStaffMember } from '../models';
import { BookingDatetime } from '../models/booking-datetime';
import { BookingStaffAvailability } from '../models/booking-staff-availability';
import { BookingStaffAvailabilityRequest } from '../models/booking-staff-availability-request';
import { PickerProgressBarService } from './picker-progress-bar.service';
import { BookingBusiness } from '../models/booking-business';
import { Subscription as Subs } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookingService implements OnDestroy {
  baseApiUrl = '__API_URLS_EXTERNAL_BOOKING__';
  subscriptions: Subs[] = [];
  private readonly serviceId = new BehaviorSubject<string | null>(null);
  public readonly ServiceId$: Observable<string | null> = this.serviceId;
  private readonly currentBookingService = new BehaviorSubject<BookingServiceDto | null>(null);
  public readonly CurrentBookingService$: Observable<BookingServiceDto | null> = this.currentBookingService;
  private readonly currentStaffMember = new BehaviorSubject<BookingStaffMember | null>(null);
  public readonly CurrentStaffMember$: Observable<BookingStaffMember | null> = this.currentStaffMember;
  private readonly staffAvailability = new BehaviorSubject<BookingStaffAvailability[]>([]);
  public readonly StaffAvailability$: Observable<BookingStaffAvailability[]> = this.staffAvailability;
  private readonly bookingBusiness = new BehaviorSubject<BookingBusiness | null>(null);
  public readonly BookingBusiness$: Observable<BookingBusiness | null> = this.bookingBusiness;

  constructor(@Inject(APP_CONFIG) appConfig: any, private http: HttpClient, private pickerProgressBarService: PickerProgressBarService) {
    this.baseApiUrl = appConfig?.apiUrls?.booking ?? this.baseApiUrl;
  }

  GetStaffAvailability(date: Date, staffIds: string[]): void {
    this.staffAvailability.next([]);
    const firstDayOfMonth = new Date(date);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const startDateTime: BookingDatetime = {
      dateTime: firstDayOfMonth,
      timeZone: 'UTC'
    };
    const endDateTime: BookingDatetime = {
      dateTime: lastDayOfMonth,
      timeZone: 'UTC'
    };
    const staffAvailabilityRequest = new BookingStaffAvailabilityRequest(startDateTime, endDateTime);
    staffAvailabilityRequest.staffIds = staffIds;

    this.pickerProgressBarService.show();
    this.subscriptions.push(this.http.post<BookingStaffAvailabilityItem[]>(`${this.baseApiUrl}api/Bookings/GetStaffAvailability`, staffAvailabilityRequest)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.pickerProgressBarService.hide();
        throw error;
      })).subscribe(data => {
        const staffAvailability: BookingStaffAvailability[] = [];
        data.forEach(BookingStaffAvailabilityItem => {
          BookingStaffAvailabilityItem.availabilityItems.forEach(availabilityItem => {
            staffAvailability.push({
              staffId: BookingStaffAvailabilityItem.staffId,
              startDateTime: availabilityItem.startDateTime,
              endDateTime: availabilityItem.endDateTime
            })
          });
        });
        this.staffAvailability.next(staffAvailability);
        this.pickerProgressBarService.hide();
      }));
  }

  GetStaffMember(bookingStaffId: string) {
    this.subscriptions.push(this.http.get<BookingStaffMember>(`${this.baseApiUrl}api/Bookings/GetStaffMember/${bookingStaffId}`)
      .subscribe(bookingStaffMember => {
        this.currentStaffMember.next(bookingStaffMember);
      }));
  }

  GetService(serviceId: string) {
    this.subscriptions.push(this.http.get<BookingServiceDto>(`${this.baseApiUrl}api/Bookings/GetService/${serviceId}`)
      .subscribe(bookingService => {
        this.currentBookingService.next(bookingService);
      }));
  }

  GetAppointmentServiceId(appointmentId: string) {
    this.serviceId.next(null);
    this.subscriptions.push(this.http.get(`${this.baseApiUrl}api/Bookings/GetAppointmentServiceId`, { responseType: 'text', params: { appointmentId: encodeURIComponent(appointmentId) } })
      .subscribe(serviceId => {
        this.serviceId.next(serviceId);
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

export { }

declare module "@ng-bootstrap/ng-bootstrap" {
    interface NgbDate {
        toDate(): Date;
        addDays(days: number): NgbDate;
        addSeconds(seconds: number): NgbDate;
        addSeconds(seconds: number): NgbDate;
    }
}

NgbDate.prototype.toDate = function (): Date {
    return new Date(this.year, this.month - 1, this.day);
};

NgbDate.prototype.addDays = function (days: number): NgbDate {
    let date = this.toDate();
    date = new Date(date.getTime() + (days * 24 * 60 * 60 * 1000));
    return date.toNgbDate();
};

NgbDate.prototype.addSeconds = function (seconds: number): NgbDate {
    let date = this.toDate();
    date = new Date(date.getTime() + (seconds * 1000));
    return date.toNgbDate();
};
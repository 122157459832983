<form [formGroup]="cancelForm" (ngSubmit)="cancelSubscription()">
    <div [id]="elementId" class="modal fade mya-cancel-plan-confirmation-modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header d-flex align-items-center">
                    <h4 class="modal-title">
                        Cancel Subscription
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="notes-text">Please provide the reason for canceling your subscription:</label>
                        <textarea class="form-control" formControlName="reason"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn waves-effect waves-light btn-outline-primary btn-cancel"
                        data-bs-dismiss="modal">
                        Close
                    </button>
                    <button type="submit" [disabled]="!cancelForm.valid" class="btn waves-effect waves-light btn-danger btn-confirm">Confirm
                        Cancellation</button>
                </div>
            </div>
        </div>
    </div>
</form>
<div [id]="elementId" class="modal fade mya-delete-user-confirmation-modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header d-flex align-items-center">
                <h4 class="modal-title">
                    Delete Support Member
                </h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="mb-0">Are you sure you want to delete <b>{{user?.firstName + ' ' + user?.lastName}}</b> as a support member?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-outline-primary btn-cancel"
                    data-bs-dismiss="modal">
                    Cancel
                </button>
                <button type="button" class="btn waves-effect waves-light btn-danger btn-delete"
                    (click)="deleteUser()">Delete Support Member</button>
            </div>
        </div>
    </div>
</div>
import { Injectable, OnDestroy } from '@angular/core';
import { ReferenceDataTypes, ReferenceItem } from '@mya/models';
import { ReferenceDataService } from './reference-data.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subscription as Subs } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService implements OnDestroy {
  subscriptions: Subs[] = [];
  private readonly overriddenDate = new BehaviorSubject<Date | null>(null);
  public readonly OverriddenDate$: Observable<Date | null> = this.overriddenDate;

  constructor(private referenceDataService: ReferenceDataService) {
    this.subscriptions.push(this.referenceDataService.ReferenceData$.subscribe(referenceData => {
      if (referenceData != null) {
        const references = JSON.parse(referenceData);
        var overriddenDate = (references[ReferenceDataTypes.OverriddenDate] as ReferenceItem[])[0].description;
        this.overriddenDate.next(overriddenDate == null ? null : new Date(overriddenDate))
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

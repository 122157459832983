import { Component, OnDestroy } from '@angular/core';
import { DateTimeService } from '../../../services/date-time.service';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-date-checker',
  templateUrl: './date-checker.component.html',
  styleUrls: ['./date-checker.component.scss'],
})
export class DateCheckerComponent implements OnDestroy {
  overriddenDate: Date | null = null;
  subscriptions: Subs[] = [];

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  get isOverridden() {
    return this.overriddenDate != null;
  }

  constructor(referenceDataService: ReferenceDataService, dateTimeService: DateTimeService) {
    referenceDataService.fetchReferenceData();
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

<div [id]="elementId" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body p-4">
                <div class="row">
                    <div class="col-6 text-center">
                        <button type="button"
                            class="btn waves-effect waves-light btn-lg btn-primary w-100"
                            (click)="processParentTimeRequest(ParentTimeTypes.FreeTime30Mins)">Free 30 minutes</button>
                    </div>
                    <div class="col-6 text-center">
                        <button type="button" class="btn waves-effect waves-light btn-lg btn-primary w-100"
                            (click)="processParentTimeRequest(ParentTimeTypes.PaidTime30Mins)">Buy 30 minutes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mya-note-card" [ngClass]="noteTypeClass" *ngIf="historyItem">
    <div class="card card-body mb-2">
        <span class="side-stick"></span>
        <h5 class="note-title text-truncate w-75 mb-0">
            {{historyItem.historyTypeId ? HistoryTypeLookup[historyItem.historyTypeId] : ''}}
            <i class="point ri-checkbox-blank-circle-fill ms-1 fs-1"></i>
        </h5>
        <p class="note-date fs-2 text-muted">
            {{historyItem.creationDate | date: 'mediumDate'}} @ {{historyItem.creationDate | date: 'shortTime'}}
        </p>
        <div class="note-content">
            <angular-editor class="angular-editor-readonly angular-editor-single-line" [(ngModel)]="historyItem.note"
                [config]="editorConfig"></angular-editor>

            <h5 class="mt-0 attachments-indicator" *ngIf="historyItem.historyAttachments.length > 0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-paperclip feather-sm">
                    <path
                        d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48">
                    </path>
                </svg>
                <span>({{historyItem.historyAttachments.length}})</span>
            </h5>

            <button type="button" class="btn waves-effect waves-light btn-primary btn-rounded btn-view-details"
                (click)="viewNoteDetails()">Read More</button>
        </div>
    </div>
</div>
<div class="d-flex flex-column flex-sm-row">
    <h1 class="d-inline mb-0 me-2">Mentee Details</h1>
    <h3 class="d-inline mb-0 align-self-sm-center step-heading">Step 2/5</h3>
</div>
<div class="mt-4">
    <div class="row" *ngIf="form">
        <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="col-12">
                <div class="form-box">
                    <div class="row">
                        <div class="col-lg-6 col-12">
                            <div class="my-2">
                                <label for="first-name" class="form-label required mb-1">
                                    <b>First Name</b></label>
                                <input type="text" id="first-name" class="form-control" placeholder="John"
                                    formControlName="firstName" />
                                <ng-container
                                    *ngIf="form.controls.firstName.invalid && (form.controls.firstName.dirty || form.controls.firstName.touched)">
                                    <div [ngClass]="{'d-block' : form.controls.firstName.errors?.['required']}"
                                        class="invalid-feedback">First Name is required.</div>
                                    <div [ngClass]="{'d-block' : form.controls.firstName.errors?.['minlength']}"
                                        class="invalid-feedback">First Name must be at least 2 characters
                                        long.
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="my-2">
                                <label for="last-name" class="form-label required mb-1"><b>Last
                                        Name</b></label>
                                <input type="text" id="last-name" class="form-control" placeholder="Doe"
                                    formControlName="lastName" />
                                <ng-container
                                    *ngIf="form.controls.lastName.invalid && (form.controls.lastName.dirty || form.controls.lastName.touched)">
                                    <div [ngClass]="{'d-block' : form.controls.lastName.errors?.['required']}"
                                        class="invalid-feedback">Last Name is required.</div>
                                    <div [ngClass]="{'d-block' : form.controls.lastName.errors?.['minlength']}"
                                        class="invalid-feedback">Last Name must be at least 2 characters
                                        long.
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-lg-6 col-12">
                            <div class="my-2">
                                <label for="age" class="form-label required mb-1"><b>Age</b></label>
                                <input type="number" id="age" class="form-control" formControlName="age" />
                                <ng-container
                                    *ngIf="form.controls.age.invalid && (form.controls.age.dirty || form.controls.age.touched)">
                                    <div [ngClass]="{'d-block' : form.controls.age.errors?.['required']}"
                                        class="invalid-feedback">Age is required.</div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-lg-6 col-12">
                            <div class="my-2">
                                <label for="country" class="form-label mb-1"><b>Country</b></label>
                                <div class="input-group">
                                    <button
                                        class="btn btn-light-light text-secondary font-medium dropdown-toggle country-dropdown"
                                        type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <img src="/static/images/blank.gif" *ngIf="form.controls.country.value"
                                            [class]="'flag fnone '+ countries[form.controls.country.value].code.toLowerCase()">
                                        <span>{{form.controls.country.value ?
                                            countries[form.controls.country.value].label : ''}}</span>
                                    </button>
                                    <ul class="dropdown-menu country-select" role="presentation"
                                        style="margin-top: 0px; margin-bottom: 0px;">
                                        <ng-container *ngFor="let country of countryLookup">
                                            <li *ngIf="country.order === 2">
                                                <hr class="dropdown-divider">
                                            </li>
                                            <li (click)="changeCountry(country)">
                                                <a role="option" class="dropdown-item" id="bs-select-1-1" tabindex="0"
                                                    aria-setsize="241" aria-posinset="2">
                                                    <span class="text">
                                                        <img src="/static/images/blank.gif"
                                                            [class]="'flag fnone '+ country.code.toLowerCase()">
                                                        <span>{{country.label}}</span>
                                                    </span>
                                                </a>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-12">
                            <div class="my-2">
                                <label for="address1" class="form-label mb-1"><b>Address Line 1</b></label>
                                <input #addressLine1 type="text" id="address1" class="form-control"
                                    formControlName="address1"  *ngIf="enableAddressField" />
                            </div>
                        </div>

                        <div class="col-lg-6 col-12">
                            <div class="my-2">
                                <label for="address2" class="form-label mb-1"><b>Address Line 2</b></label>
                                <input type="text" id="address2" class="form-control" formControlName="address2" />
                            </div>
                        </div>

                        <div class="col-lg-2 col-12">
                            <div class="my-2">
                                <label for="city" class="form-label required mb-1"><b>City</b></label>
                                <input type="text" id="city" class="form-control" formControlName="city" />
                                <ng-container
                                    *ngIf="form.controls.city.invalid && (form.controls.city.dirty || form.controls.city.touched)">
                                    <div [ngClass]="{'d-block' : form.controls.city.errors?.['required']}"
                                        class="invalid-feedback">City is required.</div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-lg-2 col-6">
                            <div class="my-2">
                                <label for="state" class="form-label mb-1"
                                    [ngClass]="{'required' : isNorthAmericanCountry}"><b>{{stateTitle}}</b></label>
                                <select id="state" class="form-select" (change)="changeState($event)"
                                    formControlName="state">
                                    <option *ngFor="let state of stateLookup" [value]="state.id">
                                        {{ state.label }}
                                    </option>
                                </select>
                                <ng-container
                                    *ngIf="form.controls.state.invalid && (form.controls.state.dirty || form.controls.state.touched)">
                                    <div [ngClass]="{'d-block' : form.controls.state.errors?.['required']}"
                                        class="invalid-feedback">{{stateTitle}} is required.</div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-lg-2 col-6">
                            <div class="my-2">
                                <label for="zip" class="form-label mb-1"
                                    [ngClass]="{'required' : isNorthAmericanCountry}"><b>{{zipTitle}}</b></label>
                                <input type="text" id="zip" class="form-control" formControlName="zip" />
                                <ng-container
                                    *ngIf="form.controls.zip.invalid && (form.controls.zip.dirty || form.controls.zip.touched)">
                                    <div [ngClass]="{'d-block' : form.controls.zip.errors?.['required']}"
                                        class="invalid-feedback">{{zipTitle}} is required.</div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-lg-6 col-12">
                            <div class="my-2">
                                <label for="parent-time-zone" class="form-label mb-1 required"><b>Time Zone</b></label>
                                <select id="parent-time-zone" class="form-select" (change)="changeTimeZone($event)"
                                    formControlName="timeZone">
                                    <option *ngFor="let timeZone of timeZones" [value]="timeZone.id">
                                        {{ timeZone.description }}
                                    </option>
                                </select>
                                <ng-container
                                    *ngIf="form.controls.timeZone.invalid && (form.controls.timeZone.dirty || form.controls.timeZone.touched)">
                                    <div [ngClass]="{'d-block' : form.controls.timeZone.errors?.['required']}"
                                        class="invalid-feedback">Time Zone is required.</div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-12">
                            <div class="my-2">
                                <label for="phone-number" class="form-label required mb-1"><b>Phone
                                        Number (where you can receive texts)</b></label>
                                <div class="input-group">
                                    <button
                                        class="btn btn-light-light text-secondary font-medium dropdown-toggle country-code-dropdown"
                                        type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <img src="/static/images/blank.gif" *ngIf="form.controls.countryCode.value"
                                            [class]="'flag fnone '+ countryCodeLookup[form.controls.countryCode.value].code.toLowerCase()">
                                    </button>
                                    <ul class="dropdown-menu country-code-select" role="presentation"
                                        style="margin-top: 0px; margin-bottom: 0px;">
                                        <ng-container *ngFor="let countryCode of countryCodes">
                                            <li *ngIf="countryCode.order === 2">
                                                <hr class="dropdown-divider">
                                            </li>
                                            <li (click)="changeCountryCode(countryCode)">
                                                <a role="option" class="dropdown-item" id="bs-select-1-1" tabindex="0"
                                                    aria-setsize="241" aria-posinset="2">
                                                    <span class="text">
                                                        <img src="/static/images/blank.gif"
                                                            [class]="'flag fnone '+ countryCode.code.toLowerCase()">
                                                        <span>{{countryCode.description}}</span>
                                                        <span class="text-muted ms-1">
                                                            +{{countryCode.label}}
                                                        </span>
                                                    </span>
                                                </a>
                                            </li>
                                        </ng-container>
                                    </ul>
                                    <input type="email" id="phone-number" class="form-control" [imask]="phoneMask"
                                        placeholder="(xxx)xxx-xxxx" formControlName="phoneNumber" />
                                    <ng-container
                                        *ngIf="form.controls.phoneNumber.invalid && (form.controls.phoneNumber.dirty || form.controls.phoneNumber.touched)">
                                        <div [ngClass]="{'d-block' : form.controls.phoneNumber.errors?.['required']}"
                                            class="invalid-feedback">Phone Number is required.</div>
                                        <div [ngClass]="{'d-block' : form.controls.phoneNumber.errors?.['minlength']}"
                                            class="invalid-feedback">Phone Number is invalid.
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-2 col-lg-6 col-12">
                            <div class="my-2">
                                <label class="form-label required">
                                    Can Receive Texts</label>
                                <div class="d-flex mt-2">
                                    <div class="form-check ms-2">
                                        <input id="parent-receiveTexts-yes" class="form-check-input" type="radio"
                                            formControlName="receiveTexts" [value]=true>
                                        <label class="form-check-label" for="parent-receiveTexts-yes">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check ms-2">
                                        <input id="parent-receiveTexts-no" class="form-check-input" type="radio"
                                            formControlName="receiveTexts" [value]=false>
                                        <label class="form-check-label" for="parent-receiveTexts-no">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-12">
                            <div class="my-2">
                                <label for="email" class="form-label required mb-1"><b>Email
                                        Address</b></label>
                                <input type="email" id="email" class="form-control" placeholder="example@example.com"
                                    formControlName="email" [readOnly]="true" />
                                <ng-container
                                    *ngIf="form.controls.email.invalid && (form.controls.email.dirty || form.controls.email.touched)">
                                    <div [ngClass]="{'d-block' : form.controls.email.errors?.['required']}"
                                        class="invalid-feedback">Email is required.</div>
                                    <div [ngClass]="{'d-block' : form.controls.email.errors?.['email']}"
                                        class="invalid-feedback">Email is invalid.</div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-6 mb-3">
                            <label for="nickname" class="form-label mb-1">Nickname</label>
                            <input class="form-control" type="text" id="nickname" placeholder="Enter"
                                formControlName="nickname" />
                        </div>
                        <div class="col-6 mb-3">
                            <label for="pronouns" class="form-label mb-1">Pronouns</label>
                            <select id="pronouns" class="form-select" (change)="changePronoun($event)"
                                formControlName="pronouns">
                                <option *ngFor="let pronouns of pronounsLookup" [value]="pronouns.id">
                                    {{ pronouns.label }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 mb-3" *ngIf="isOtherPronoun">
                            <label for="otherPronouns" class="form-label required mb-1">Other Pronoun
                                Description</label>
                            <input class="form-control" type="text" id="otherPronouns" placeholder="Enter"
                                formControlName="otherPronouns" />
                            <ng-container
                                *ngIf="form.controls.otherPronouns.invalid && (form.controls.otherPronouns.dirty || form.controls.otherPronouns.touched)">
                                <div [ngClass]="{'d-block' : form.controls.otherPronouns.errors?.['required']}"
                                    class="invalid-feedback">Other Pronoun is required.</div>
                            </ng-container>
                        </div>

                    </div>

                </div>
            </div>

            <div class="col-12">
                <div class="d-flex flex-column-reverse flex-sm-row-reverse">
                    <button type="submit" class="btn waves-effect waves-light btn-primary btn-submit mt-4">
                        Next
                    </button>
                    <button type="button" class="btn waves-effect waves-light btn-outline-primary btn-back mt-4"
                        (click)="back()">
                        Back
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
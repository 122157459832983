import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ChangePasswordRequest, ShowValidationErrorCheck, ValidationPattern } from '@mya/models';
import { ModalConstant } from '../../../../common/constants/modal.constant';
import { AuthenticationService } from '../../../../../app/services/authentication.service';
import { LoaderService } from '../../../../../app/services/loader.service';
import { ModalService } from '../../../../../app/services/modal.service';
import { v4 as uuid } from 'uuid';
import { catchError } from 'rxjs';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent implements OnDestroy {
  elementId = ModalConstant.CHANGE_PASSWORD_MODAL;
  customError: string | null = null;
  subscriptions: Subs[] = [];

  passwordForm = this.formBuilder.group({
    currentPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required, Validators.pattern(ValidationPattern.PasswordPattern)]],
    confirmPassword: ['', [Validators.required, Validators.pattern(ValidationPattern.PasswordPattern)]],
  });

  constructor(private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder) { }

  public passwordReset() {
    this.passwordForm.reset();
  }

  passwordChanged(e: any) {
    this.passwordForm.controls.currentPassword.updateValueAndValidity();
    this.passwordForm.controls.newPassword.updateValueAndValidity();
    this.passwordForm.controls.confirmPassword.updateValueAndValidity();
  }

  showValidationError(formControlName: string, validation: string) {
    return ShowValidationErrorCheck(formControlName, validation, this.passwordForm);
  }

  onSubmit() {
    if (this.passwordForm.controls.currentPassword.value == null || this.passwordForm.controls.newPassword.value == null)
      return;

    const request: ChangePasswordRequest = {
      oldPassword: this.passwordForm.controls.currentPassword.value,
      newPassword: this.passwordForm.controls.newPassword.value
    };

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.authenticationService.changePassword(request, loaderIdentifier)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        if (error.status == HttpStatusCode.Conflict) {
          this.customError = error.error;
        } else {
          toastr.error('An error has occured');
        }

        throw error;
      })).subscribe(() => {
        this.loaderService.hide(loaderIdentifier);
        toastr.success('Password Changed Successful');
        this.modalService.hide(ModalConstant.CHANGE_PASSWORD_MODAL);
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripeKey: "pk_test_2V1MN9GC6eKDxdUoxq4qomAB00eZC4vLsA",
  googleMapsApiKey: "AIzaSyB7TrV6LiM63rameRQHC8_MuHSVkZofZJc",
  booking:{
    services: {
      mentor60mins: 'd147b617-0d4d-4448-8b58-35e7e161debf',
      mentor30mins: '427c6bb6-ccbe-4c59-80b7-98df089cb3d2'
    }
  },
  apiUrls: {
    authentication:"https://api.external.auth.mya.appech.ngrok.io/",
    account:"https://api.external.account.mya.appech.ngrok.io/",
    appointment:"https://api.external.appointment.mya.appech.ngrok.io/",
    booking:"https://api.external.booking.mya.appech.ngrok.io/",
    contract:"https://api.external.contract.mya.appech.ngrok.io/",
    payment:"https://api.external.payment.mya.appech.ngrok.io/",
    pricing:"https://api.external.pricing.mya.appech.ngrok.io/",
    smartType:"https://api.external.smart-type.mya.appech.ngrok.io/",
    externalUser:"https://api.external.external-user.mya.appech.ngrok.io/",
    subscription:"https://api.external.subscription.mya.appech.ngrok.io/",
    history: "https://api.external.history.mya.appech.ngrok.io/",
    referenceData: "https://api.external.reference-data.mya.appech.ngrok.io/"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

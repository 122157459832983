<div class="main-wrapper mya-login h-100">
    <div class="auth-wrapper background-img h-100">
        <div class="row  h-100">
            <div class="col-12 col-md-6 col-lg-7 col-xl-8 d-flex justify-content-center align-items-center">
                <div class="mx-2">
                    <h1 class="display-2 text-white text-center">Welcome</h1>
                    <h1 class="text-center text-white">to the Parent Portal,<br> World Wide Youth Mentoring Inc.</h1>
                </div>
            </div>
            <div
                class="col-12 col-md-6 col-lg-5 col-xl-4 px-5 login-box d-flex flex-column justify-content-center align-items-center">
                <img src="./assets/images/logos/mya-logo-colored.svg" alt="logo" />
                <h1 class="mt-4">Parent Portal</h1>
                <h3 class="mb-4 text-muted">login to your account</h3>
                <div class="p-4 mb-5 bg-body rounded mt-2 form-box">
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="mb-3 mt-4">
                            <label for="username"><b>Email Address</b></label>
                            <input type="email" id="username" name="username" class="form-control mt-1 mb-3"
                                placeholder="example@wwym.org" formControlName="username" />
                        </div>
                        <div class="mb-3">
                            <label for="password"><b>Password</b></label>
                            <input type="password" id="password" name="password" class="form-control mt-1 mb-3"
                                placeholder="●●●●●●●" formControlName="password" />
                        </div>
                        <p *ngIf="credentialError" class="text-danger">User name or password is incorrect.</p>
                        <a href="javascript:void(0)" (click)="forgotPassword()"><b>Forgot Password?</b></a>
                        <button type="submit" class="btn waves-effect waves-light btn-primary w-100 mt-3 mb-4 btn-login"
                            [disabled]="!loginForm.valid"><b>Login</b></button>
                    </form>
                </div>
            </div>
        </div>

    </div>

</div>
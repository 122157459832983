<div class="d-flex flex-column flex-sm-row">
    <h1 class="d-inline mb-0 me-2">Choose your Plan</h1>
    <h3 class="d-inline mb-0 align-self-sm-center step-heading">Step 3/5</h3>
</div>
<div class="mt-4">
    <div class="row">
        <div class="col-12">
            <div class="form-box">
                <div
                    class="d-flex flex-column flex-md-row justify-content-center gap-3">
                    <mya-plan-card [isCurrentPlanCard]="true" [plan]="selectedPlan"></mya-plan-card>
                    <div class="d-flex flex-column flex-wrap gap-3">
                        <ng-container *ngFor="let plan of availablePlans">
                            <mya-plan-card [plan]="plan" [currentPlanId]="selectedPlanId"
                                (planSelected)="planSelected($event)"></mya-plan-card>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-12">
            <div class="d-flex flex-column-reverse flex-sm-row-reverse">
                <button type="button" class="btn waves-effect waves-light btn-primary btn-submit mt-4"
                    [disabled]="selectedPlan === null" (click)="submit()">
                    Next
                </button>
                <button type="button" class="btn waves-effect waves-light btn-outline-primary btn-back mt-4"
                    (click)="back()">
                    Back
                </button>
            </div>
        </div>
    </div>
</div>
<div class="d-inline-block">
    <div><mya-appointment-datepicker *ngIf="serviceId" [currentDate]="currentDate" [serviceId]="serviceId"
            [center]="center" (appointmentSlotSelected)="appointmentSlotSelected($event)">
        </mya-appointment-datepicker></div>
    <div class="d-flex mt-1">
        <div class="flex-grow-1 w-0">
            <div class="d-flex flex-column">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <ng-container *ngIf="form.controls.appointment.valid">
                        <div class="row mx-0">
                            <div class="col-md-6 col-12 d-flex flex-column field-container-left mt-2">
                                <label for="parent-firstName" class="form-label required mb-1 align-self-start">
                                    <b>First Name</b></label>
                                <input type="text" id="parent-firstName" class="form-control bg-transparent"
                                    placeholder="John" formControlName="firstName" />
                                <ng-container
                                    *ngIf="form.controls.firstName.invalid && (form.controls.firstName.dirty || form.controls.firstName.touched)">
                                    <div [ngClass]="{'d-block' : form.controls.firstName.errors?.['required']}"
                                        class="invalid-feedback">First Name is required.</div>
                                    <div [ngClass]="{'d-block' : form.controls.firstName.errors?.['minlength']}"
                                        class="invalid-feedback">First Name must be at least 2 characters
                                        long.
                                    </div>
                                </ng-container>
                            </div>
                            <div class="col-md-6 col-12 d-flex flex-column field-container-right mt-2">
                                <label for="parent-email" class="form-label required mb-1 align-self-start"><b>Email
                                        Address</b></label>
                                <input type="email" id="parent-email" class="form-control bg-transparent"
                                    placeholder="example@example.com" formControlName="email" />
                                <ng-container
                                    *ngIf="form.controls.email.invalid && (form.controls.email.dirty || form.controls.email.touched)">
                                    <div [ngClass]="{'d-block' : form.controls.email.errors?.['required']}"
                                        class="invalid-feedback">Email is required.</div>
                                    <div [ngClass]="{'d-block' : form.controls.email.errors?.['email']}"
                                        class="invalid-feedback">Email is invalid.</div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button type="submit" [disabled]="!form.valid"
                                class="btn waves-effect waves-light btn-primary btn-book mt-5 px-3">
                                Book an Appointment<i class="ms-2 carousel-control-next-icon"></i>
                            </button>
                        </div>
                    </ng-container>
                </form>
            </div>

        </div>

    </div>
</div>
export enum ReportTypes {
    AccountsPayable = 1,
    MenteeAppointmentCancellation = 2,
    MenteeAccountCancellation = 3,
    MentorPerformance = 4,
    FailedPayment = 5,
    ConsultationFlow = 6
}

export const ReportTypesLookup: Record<ReportTypes, string> = {
    [ReportTypes.AccountsPayable]: "Accounts Payable",
    [ReportTypes.MenteeAppointmentCancellation]: "Mentee Appointment Cancellation",
    [ReportTypes.MenteeAccountCancellation]: "Mentee Account Cancellation",
    [ReportTypes.MentorPerformance]: "Mentor Performance",
    [ReportTypes.FailedPayment]: "Failed Payment",
    [ReportTypes.ConsultationFlow]: "Consultation Flow"
};
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Account, CancelCurrentPlanByUserRequest, ExternalUserClaimTypes } from '@mya/models';
import { ModalConstant } from '../../../../common/constants/modal.constant';
import { AccountService } from '../../../../services/account.service';
import { LoaderService } from '../../../../services/loader.service';
import { ModalService } from '../../../../services/modal.service';
import { v4 as uuid } from 'uuid';
import { AuthenticationService } from 'apps/support/src/app/services/authentication.service';
import { JwtService } from 'apps/support/src/app/services/jwt.service';
import { Subscription as Subs } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
declare const toastr: any;

@Component({
  selector: 'mya-cancel-plan-confirmation-modal',
  templateUrl: './cancel-plan-confirmation-modal.component.html',
  styleUrls: ['./cancel-plan-confirmation-modal.component.scss'],
})
export class CancelPlanConfirmationModalComponent implements OnInit, OnDestroy {
  @Input() account: Account | null = null;
  elementId = ModalConstant.CANCEL_PLAN_CONFIRMATION_MODAL;
  email: string | null = null;
  subscriptions: Subs[] = [];

  cancelForm = this.formBuilder.group({
    reason: [null, [Validators.required]]
  });

  constructor(private accountService: AccountService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
    private jwtService: JwtService) { }

  ngOnInit(): void {
    const token = this.authenticationService.getToken();
    if (token) {
      const decodedToken: any = this.jwtService.DecodeToken(token);
      this.email = decodedToken[ExternalUserClaimTypes.Email];
    }
  }

  cancelSubscription() {
    if(this.cancelForm.controls.reason.value == null)
      return;

    const loaderIdentifier = uuid();
    const request: CancelCurrentPlanByUserRequest = {
      reason: this.cancelForm.controls.reason.value
    }
    this.subscriptions.push(this.accountService.cancelCurrentPlanByAccount(request, loaderIdentifier).subscribe(() => {
      toastr.success('Subscription Cancelled.');
      this.modalService.hide(ModalConstant.CANCEL_PLAN_CONFIRMATION_MODAL);
      this.loaderService.hide(loaderIdentifier);
      this.accountService.getAccount(true);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

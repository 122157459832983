import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Account, ChargeSubscriptionRequest, InternalUser, PlanPricing, getMentorType } from '@mya/models';
import { v4 as uuid } from 'uuid';
import { StripeService } from 'ngx-stripe';
import { LoaderService } from '../../../services/loader.service';
import { SubscriptionService } from '../../../services/subscription.service';
import { PricingService } from '../../../services/pricing.service';
import { AccountService } from '../../../services/account.service';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-payment-redirect',
  templateUrl: './payment-redirect.component.html',
  styleUrls: ['./payment-redirect.component.scss'],
})
export class PaymentRedirectComponent implements OnInit, OnDestroy {
  linkedAccount: Account | null = null;
  mentor: InternalUser | null = null;
  subscriptions: Subs[] = [];

  planPricings: PlanPricing[] = [];
  isPaymentSuccessful = false;
  isChargingSubscription = false;

  get planPricing() {
    return this.planPricings.find(i => i.mentorType == getMentorType(this.mentor ?? null) && i.mentoringPlanId == this.linkedAccount?.mentoringPlanId) ?? null;
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    private stripeService: StripeService,
    private accountService: AccountService,
    private pricingService: PricingService,
    private loaderService: LoaderService,
    private subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(linkedAccount => {
      this.linkedAccount = linkedAccount;
      this.chargeSubscription();
    }));
    this.subscriptions.push(this.accountService.Mentor$.subscribe(user => {
      this.mentor = user;
    }));

    this.pricingService.fetchPlanPricings();
    this.subscriptions.push(this.pricingService.PlanPricing$.subscribe(i => {
      this.planPricings = i;
      this.chargeSubscription();
    }));

    if (this.route.snapshot.queryParams['setup_intent_client_secret']) {
      const clientSecret = this.route.snapshot.queryParams['setup_intent_client_secret'];
      this.subscriptions.push(this.stripeService.retrieveSetupIntent(clientSecret).subscribe({
        next: (setupIntentResult) => {
          if (setupIntentResult.error) {
            console.error(setupIntentResult.error);
            this.router.navigate(['/onboarding'], { queryParams: { payment_success: false } });
          }

          switch (setupIntentResult?.setupIntent?.status) {
            case 'succeeded':
              this.isPaymentSuccessful = true;
              this.chargeSubscription();
              break;

            default:
              this.router.navigate(['/onboarding'], { queryParams: { payment_success: false } });
              break;
          }
        },
        error: (err) => {
          console.error(err);
          this.router.navigate(['/onboarding'], { queryParams: { payment_success: false } });
        }
      }));
    } else {
      this.router.navigate(['/onboarding']);
    }

  }

  chargeSubscription() {
    if (this.linkedAccount?.id && this.planPricing?.id && this.isPaymentSuccessful && !this.isChargingSubscription) {
      this.isChargingSubscription = true;

      const request: ChargeSubscriptionRequest = {
        planPricingId: this.planPricing.id
      };

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.subscriptionService.chargeSubscription(request, loaderIdentifier).subscribe({
        next: () => {
          this.loaderService.hide(loaderIdentifier);
          this.router.navigate(['/onboarding'], { queryParams: { payment_success: true } });
        },
        error: (err) => {
          toastr.error(`${err.message || 'Unknown Error'}`);
          this.loaderService.hide(loaderIdentifier);
        },
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

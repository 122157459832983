export enum HistoryTypeEnum {
    System = 1,
    Consultant = 2,
    Parent = 4,
    Quote = 5,
    Gcs = 6,
    Som = 7,
    Col = 8,
    Office = 9
}

export const HistoryTypeLookup: Record<HistoryTypeEnum, string> = {
    [HistoryTypeEnum.System]: "System Notes",
    [HistoryTypeEnum.Consultant]: "Consultant Notes",
    [HistoryTypeEnum.Parent]: "Parent Notes",
    [HistoryTypeEnum.Quote]: "Quote",
    [HistoryTypeEnum.Gcs]: "GCS",
    [HistoryTypeEnum.Som]: "SOM",
    [HistoryTypeEnum.Col]: "COL",
    [HistoryTypeEnum.Office]: "Office Notes",
};
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { GetHistoryItemsResult, HistoryItem } from '@mya/models';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { LoaderService } from './loader.service';
import { v4 as uuid } from 'uuid';
import { Subscription as Subs } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoryService implements OnDestroy {
  baseApiUrl = '';
  subscriptions: Subs[] = [];
  private readonly historyItems = new BehaviorSubject<HistoryItem[]>([]);
  public readonly HistoryItems$: Observable<HistoryItem[]> = this.historyItems;

  constructor(private http: HttpClient, private loaderService: LoaderService,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.history}api/history`
  }

  getHistoryItems() {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetHistoryItemsResult>(`${this.baseApiUrl}/get-history-items`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe(data => {
        this.historyItems.next(data.historyItems);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  getHistoryAttachmentFile(historyAttachmentId: string) {
    return this.http.get(this.baseApiUrl + `/get-history-attachment-file/${historyAttachmentId}`, { responseType: 'blob' });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

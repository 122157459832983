import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { CreateParentTimeTokenRequest, CreateParentTimeTokenResult, CreateSetupIntentResult, DetachOtherPaymentMethodsRequest, GetPaymentMethodResult } from '@mya/models';
import { Observable, catchError } from 'rxjs';
import { LoaderService } from './loader.service';
import { v4 as uuid } from 'uuid';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Injectable({
  providedIn: 'root'
})
export class PaymentService implements OnDestroy {
  baseApiUrl = '';
  subscriptions: Subs[] = [];

  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) appConfig: any, private loaderService: LoaderService) {
    this.baseApiUrl = `${appConfig.apiUrls.payment}api/payment`
  }

  createSetupIntent(loaderIdentifier: string): Observable<CreateSetupIntentResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post<CreateSetupIntentResult>(
      `${this.baseApiUrl}/create-setup-intent`, {}
    ).pipe(catchError((error: HttpErrorResponse) => {
      this.loaderService.hide(loaderIdentifier);
      throw error;
    }));
  }

  detachOtherPaymentMethods(request: DetachOtherPaymentMethodsRequest) {
    const loaderIdentifier = uuid();
    this.subscriptions.push(this.http.post(`${this.baseApiUrl}/detach-other-payment-methods`, request
    ).pipe(catchError((error: HttpErrorResponse) => {
      toastr.error('An error has occured');
      this.loaderService.hide(loaderIdentifier);
      throw error;
    })).subscribe(() => {
      this.loaderService.hide(loaderIdentifier);
    }));
  }

  getPaymentMethod(loaderIdentifier: string): Observable<GetPaymentMethodResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.get<GetPaymentMethodResult>(`${this.baseApiUrl}/payment-method`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  createParentTimeToken(request: CreateParentTimeTokenRequest, loaderIdentifier: string): Observable<CreateParentTimeTokenResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post<CreateParentTimeTokenResult>(`${this.baseApiUrl}/pay-for-parent-time`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

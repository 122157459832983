import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class NgbDateFormatter extends NgbDateParserFormatter {
    readonly DELIMITER = '-';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                month: parseInt(date[0], 10),
                day: parseInt(date[1], 10),
                year: parseInt(date[2], 10),
            };
        }
        return null;
    }

    format(date: NgbDateStruct): string {
        return date ? `${this.padStart(date.month)}${this.DELIMITER}${this.padStart(date.day)}${this.DELIMITER}${date.year}` : '';
    }

    private padStart(value: number) {
        return String(value).padStart(2, '0');
    }
}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticateUserRequest, ValidationPattern } from '@mya/models';
import { AuthenticationService } from '../../../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  credentialError = false;
  tenant: string | null = null;
  plan: string | null = null;
  returnUrl: string | null = null;
  subscriptions: Subs[] = [];

  loginForm = this.formBuilder.group({
    username: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.pattern(ValidationPattern.PasswordPattern)]],
  });

  constructor(private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) {
      this.subscriptions.push(this.route.queryParams
      .pipe(filter((params) => params['returnUrl']))
      .subscribe((params) => {
        this.returnUrl = params['returnUrl'];
      }));
  }

  ngOnInit(): void {
    this.subscriptions.push(this.route.paramMap.subscribe(params => {
      this.plan = params.get('plan');
      this.tenant = params.get('tenant');

      if (this.plan && this.tenant) {
        localStorage.setItem('plan', this.plan);
        localStorage.setItem('tenant', this.tenant);
      }
    }));

    const isLoggedIn = this.authenticationService.isAuthenticated();
    if (isLoggedIn) {
      this.router.navigate(['/']);
    }

    this.subscriptions.push(this.authenticationService.CredentialError$.subscribe(i => {
      this.credentialError = i;
    }));
  }

  forgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  onSubmit() {
    if (this.loginForm.controls.username.value == null || this.loginForm.controls.password.value == null)
      return;

    const request: AuthenticateUserRequest = {
      userName: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value
    };

    this.authenticationService.login(request, this.returnUrl);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BookingAppointmentSlot } from '../../models';

@Component({
  selector: 'mya-appointment-datepicker-navigator',
  templateUrl: './appointment-datepicker-navigator.component.html',
  styleUrls: ['./appointment-datepicker-navigator.component.scss'],
})
export class AppointmentDatepickerNavigatorComponent {
  @Input() center = false;

  currentDate = new Date();
  serviceId = '__BOOKING_30_MIN_CONSULT_SERVICE_ID__';
  portalBaseUrl = '__APP_WWYM_BASE_URL__';

  form = this.formBuilder.group({
    appointment: [null as BookingAppointmentSlot | null, [Validators.required]],
    firstName: [null as string | null, [Validators.required, Validators.minLength(2)]],
    email: [null as string | null, [Validators.required, Validators.email]],
  });

  constructor(private formBuilder: FormBuilder) { }

  appointmentSlotSelected(date: BookingAppointmentSlot) {
    this.form.controls.appointment.setValue(date);
  }

  submit() {
    if (this.form.controls.appointment.value && this.form.controls.firstName.value && this.form.controls.email.value) {
      const redirectUrl = `${this.portalBaseUrl}/${encodeURIComponent(this.form.controls.appointment.value.date.toISOString())}/${encodeURIComponent(this.form.controls.appointment.value.staffId)}/${encodeURIComponent(this.form.controls.firstName.value)}/${encodeURIComponent(this.form.controls.email.value)}`;
      window.location.href = redirectUrl;
    }
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AccountStatus } from '@mya/models';
import { AccountService } from '../services/account.service';
declare const toastr: any;

@Injectable({
  providedIn: 'root'
})
export class PortalGuard implements CanActivate {
  subscription: Subscription | null = null;

  constructor(private accountService: AccountService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Observable<boolean>(observer => {
      this.accountService.getAccount();
      this.subscription = this.accountService.LinkedAccount$.subscribe(account => {
        if (account) {
          if (account.accountStatus == AccountStatus.ContractPending || account.accountStatus == AccountStatus.Onboarding) {
            this.router.navigate(['/onboarding']);
          } else {
            observer.next(true);
          }
        }
        this.subscription?.unsubscribe();
      });
    });
  }
}

<div class="mya-payment-method-card mt-3">
    <div class="card card-body mb-2">
        <span class="side-stick"></span>
        <h3 class="note-title text-truncate w-75 mb-2">
            **** **** **** {{last4}}
        </h3>
        <h4 class="note-date text-muted">
            {{brand | uppercase}} | {{expMonth}}/{{expYear}}
        </h4>
        <div class="note-content" *ngIf="readonly === false">
            <button type="button" (click)="update()"
                class="btn waves-effect waves-light btn-primary btn-rounded btn-view-details">Update</button>
        </div>
    </div>
</div>
<ng-container *ngIf="currentService !== null && (bookingStaffId === null || currentStaffMember !== null)">

    <div class="d-flex flex-wrap" [ngClass]="{ 'justify-content-center': center }">
        <div class="datepicker-container mb-3">
            <div class="w-100 card-container">
                <div class="progress" [ngClass]="{'invisible' : !isProgressBarVisible}">
                    <div class="progress-bar animated-progress-bar indeterminate">
                    </div>
                </div>
                <ngb-datepicker [(ngModel)]="model" (dateSelect)="onDateSelected($event)"
                    (navigate)="onMonthChange($event)" [markDisabled]="isDayDisabled" navigation="arrows"
                    outsideDays="collapsed"></ngb-datepicker>
            </div>
        </div>

        <div id="slots-container" class="slots-container" *ngIf="selectedDate !== null && selectedDateAppointmentSlots.length > 0">
            <ul class="list-group pe-0">
                <ng-container *ngFor="let appointmentSlot of selectedDateAppointmentSlots">
                    <button type="button" class="list-group-item list-group-item-action text-center "
                        [style]="selectedAppointmentSlot?.date === appointmentSlot.date ?  'background-color: var(--bs-primary) !important;color:white;' : ''"
                        [ngClass]="{ 'active': selectedAppointmentSlot?.date === appointmentSlot.date  }"
                        (click)="selectAppointmentSlot(appointmentSlot)">
                        {{appointmentSlot.date | date:'shortTime'}}</button>
                    <hr class="hr my-0 ms-2" />
                </ng-container>
            </ul>
        </div>

    </div>

</ng-container>
import { BookingServiceSchedulingPolicy } from "./booking-service-scheduling-policy";

export class BookingServiceDto {
    id = '';
    displayName = '';
    defaultDuration = '';
    preBuffer = '';
    postBuffer = '';
    staffMemberIds: string[] = [];
    schedulingPolicy: BookingServiceSchedulingPolicy | null = null;
}
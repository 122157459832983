<div class="main-wrapper mya-forget-password h-100" data-layout="horizontal" data-boxed-layout="boxed">
    <header class="topbar">
        <nav class="px-1 navbar top-navbar navbar-expand-md navbar-dark">
            <div>
                <!-- This is for the sidebar toggle which is visible on mobile only -->
                <!-- -------------------------------------------------------------- -->
                <!-- Logo -->
                <!-- -------------------------------------------------------------- -->
                <a href="index.html">
                    <!-- Logo icon -->
                    <b>
                        <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                        <!-- Light Logo icon -->
                        <img src="./assets/images/logos/mya-logo-colored.svg" alt="homepage" class="light-logo" />
                    </b>
                    <!--End Logo icon -->
                    <!-- Logo text -->

                </a>

                <!-- -------------------------------------------------------------- -->
                <!-- End Logo -->
                <!-- -------------------------------------------------------------- -->
                <!-- -------------------------------------------------------------- -->
                <!-- Toggle which is visible on mobile only -->
                <!-- -------------------------------------------------------------- -->

            </div>
            <!-- -------------------------------------------------------------- -->
            <!-- End Logo -->
            <!-- -------------------------------------------------------------- -->
            <div class="navbar-collapse" id="navbarSupportedContent">
                <!-- -------------------------------------------------------------- -->
                <!-- toggle and nav items -->
                <!-- -------------------------------------------------------------- -->
                <div class="me-auto"></div>
                <!-- -------------------------------------------------------------- -->
                <!-- Right side toggle and nav items -->
                <!-- -------------------------------------------------------------- -->
                <div>
                    <button type="button" class="btn waves-effect waves-light btn-primary w-100 btn-login"
                        (click)="login()"><b>Login</b></button>
                </div>
            </div>
        </nav>
    </header>
    <div class="background-img h-100">
        <div class="page-wrapper p-5 h-100 w-100 flex-wrap justify-content-center align-items-center">
            <div class="flex-grow-1">
                <h1>Forgot Your Password?</h1>
                <h3 class="mb-4 text-muted">Enter the email address associated with your account and we will
                    send you a link to reset your password</h3>
                <div class="form-box">
                    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
                        <div class="mb-3 mt-4 w-auto">
                            <label for="forgot-password-email"><b>Email Address</b></label>
                            <input type="email" id="forgot-password-email" name="forgot-password-email"
                                class="form-control mt-1 mb-3 forgot-password-email" formControlName="emailAddress"
                                placeholder="example@wwym.org" />
                        </div>
                        <button type="submit"
                            class="btn waves-effect waves-light btn-primary mt-3 mb-4 btn-send-link"><b>Send
                                Link</b></button>
                        <p>
                            Need help?
                            <a href="mailto:office@wwym.org"><b>Contact us</b></a>
                        </p>
                    </form>
                </div>

            </div>
            <div class="img-container">
                <img src="./assets/images/forgot-password-padlock.svg" alt="padlock" />
            </div>
        </div>

    </div>

</div>
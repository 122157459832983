import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { BookingAppointmentSlot, BookingService } from '@mya/booking-shared';
import { APP_CONFIG } from '@mya/configuration';
import { Account, adjustDateByTimeZone, Appointment, AppointmentTypeEnum, CreateFirstMentorAppointmentRequest, ESignatureContractDetails, ExternalUser, ExternalUserClaimTypes, InternalUser, ReferenceDataTypes, ReferenceItem, SmartCode, SmartTypes } from '@mya/models';
import { AccountService } from '../../../../services/account.service';
import { AppointmentService } from '../../../../services/appointment.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { ContractService } from '../../../../services/contract.service';
import { JwtService } from '../../../../services/jwt.service';
import { LoaderService } from '../../../../services/loader.service';
import { SmartTypesService } from '../../../../services/smart-types.service';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { DateTimeService } from 'apps/support/src/app/services/date-time.service';
import { ReferenceDataService } from 'apps/support/src/app/services/reference-data.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-schedule-appointment',
  templateUrl: './schedule-appointment.component.html',
  styleUrls: ['./schedule-appointment.component.scss'],
})
export class ScheduleAppointmentComponent implements OnInit, OnDestroy {
  @Output() previous = new EventEmitter();
  subscriptions: Subs[] = [];
  mentorAppointment: Appointment | null = null;
  contract: ESignatureContractDetails | null = null;
  appointmentSlot: BookingAppointmentSlot | null = null;
  linkedAccount: Account | null = null;
  accountCreator: ExternalUser | null = null;
  mentee: ExternalUser | null = null;
  mentor: InternalUser | null = null;
  plans: SmartCode[] = [];
  serviceId: string | null = null;
  email: string | null = null;
  meetingDuration = 0;
  overriddenDate: Date | null = null;
  timeZones: ReferenceItem[] = [];

  get selectedPlan() {
    return this.plans.find(i => i.id == this.linkedAccount?.mentoringPlanId) ?? null;
  }

  get parentFirstname() {
    return this.accountCreator ? this.accountCreator.firstName : '';
  }

  get menteeFirstname() {
    return this.mentee ? this.mentee.firstName : '';
  }

  get mentorName() {
    return this.mentor ? `${this.mentor.firstName} ${this.mentor.lastName}` : '';
  }

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(@Inject(APP_CONFIG) appConfig: any,
    private appointmentService: AppointmentService,
    private documentService: ContractService,
    private accountService: AccountService,
    private smartTypesService: SmartTypesService,
    private loaderService: LoaderService,
    private bookingService: BookingService,
    private authenticationService: AuthenticationService,
    private referenceDataService: ReferenceDataService,
    private jwtService: JwtService,
    dateTimeService: DateTimeService) {
      this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
    this.serviceId = appConfig.booking.services.mentor60mins;
  }

  ngOnInit(): void {
    this.subscriptions.push(this.accountService.AccountCreator$.subscribe(user => {
      this.accountCreator = user;
    }));
    this.subscriptions.push(this.accountService.Mentee$.subscribe(user => {
      this.mentee = user;
    }));
    this.subscriptions.push(this.accountService.Mentor$.subscribe(user => {
      this.mentor = user;
    }));
    if (this.serviceId) {
      this.bookingService.GetService(this.serviceId);
    }
    this.subscriptions.push(this.bookingService.CurrentBookingService$.subscribe(service => {
      if (service) {
        this.meetingDuration = moment.duration(service.defaultDuration).asMinutes();
      }
    }));
    const token = this.authenticationService.getToken();
    if (token) {
      const decodedToken: any = this.jwtService.DecodeToken(token);
      this.email = decodedToken[ExternalUserClaimTypes.Email];
    }
    this.subscriptions.push(this.documentService.Contract$.subscribe(contract => {
      this.contract = contract;
    }));
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(account => {
      this.linkedAccount = account;
      if (account != null) {
        const loaderIdentifier = uuid();
        this.loaderService.show(loaderIdentifier);
        setTimeout(() => {
          this.documentService.getContract(loaderIdentifier);
        }, 3000);
      }
    }));
    const loaderIdentifier = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.MentoringPlan, loaderIdentifier).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier);
      smartCodes?.forEach(smartCode => {
        if (smartCode.code !== 'OP') {
          this.plans.push(smartCode);
        }
      });
    }));
    this.subscriptions.push(this.referenceDataService.ReferenceData$.subscribe(referenceData => {
      if (referenceData != null) {
        const references = JSON.parse(referenceData);
        this.timeZones = references[ReferenceDataTypes.TimeZones];
      }
    }));
  }

  appointmentSlotSelected(date: BookingAppointmentSlot) {
    this.appointmentSlot = date;
  }

  bookAppointment() {
    if (this.mentor && this.appointmentSlot && this.linkedAccount && this.mentor.id && this.mentee && this.accountCreator) {
      const appointmentSlot = this.appointmentSlot;
      const timeZone = this.accountCreator.timeZone as string;

      const appointmentId = uuid();
      const endDateTime = new Date(appointmentSlot.date);
      endDateTime.setMinutes(endDateTime.getMinutes() + this.meetingDuration);

      const request: CreateFirstMentorAppointmentRequest = {
        appointment: <Appointment>{
          id: appointmentId,
          accountId: this.linkedAccount.id,
          durationInMinutes: this.meetingDuration,
          title: `1A`,
          startTime: appointmentSlot.date,
          appointmentTypeId: AppointmentTypeEnum.Mentor,
          meetingUrl: '',
          bookingAppointmentId: ''
        },
        appointmentExternalUsers: [{
          id: uuid(),
          appointmentId: appointmentId,
          externalUserId: this.accountCreator.id as string,
          externalUser: null
        },
        {
          id: uuid(),
          appointmentId: appointmentId,
          externalUserId: this.mentee.id as string,
          externalUser: null
        }],
        appointmentInternalUsers: [{
          id: uuid(),
          appointmentId: appointmentId,
          internalUserId: this.mentor.id as string,
          internalUser: null
        }],
        bookingAppointment: {
          startDateTime: {
            dateTime: adjustDateByTimeZone(appointmentSlot.date, timeZone, this.timeZones),
            timeZone: timeZone
          },
          endDateTime: {
            dateTime: adjustDateByTimeZone(endDateTime, timeZone, this.timeZones),
            timeZone: timeZone
          },
          serviceId: this.serviceId,
          staffMemberIds: [appointmentSlot.staffId]
        },
        customers: [
          {
            customerId: this.accountCreator.bookingCustomerId,
            emailAddress: this.accountCreator.emailAddress,
            name: this.accountCreator.firstName
          },
          {
            customerId: this.mentee.bookingCustomerId,
            emailAddress: this.mentee.emailAddress,
            name: this.mentee.firstName
          }
        ]
      }

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.appointmentService.createFirstMentorAppointment(request, loaderIdentifier).subscribe(result => {
        this.mentorAppointment = result.appointment;
        this.loaderService.hide(loaderIdentifier);
      }));
    }
  }

  redirectToPortal() {
    window.location.href = `${window.location.origin}/support`;
  }

  back() {
    this.previous.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

<div class="container-fluid px-5 pt-5 h-100 mya-subscription overflow-y-auto">
    <div class="row h-100">
        <div class="col-12 mb-3 d-flex">
            <h1>Subscription</h1>
        </div>
        <div class="col-12 p-5 subscription-box">
            <h3>Current Plan</h3>
            <div class="d-flex flex-wrap gap-3">
                <mya-plan-card [isCurrentPlanCard]="true" [plan]="currentPlan"></mya-plan-card>
            </div>


            <ng-container *ngIf="account?.accountStatus === AccountStatus.Active; else elseBlock">
                <button type="button" class="btn waves-effect waves-light btn-outline-primary mt-2 btn-cancel"
                    (click)="cancelPlan()"><b>Cancel Subscription</b></button>
            </ng-container>

            <ng-template #elseBlock>
                <button *ngIf="account" type="button" class="btn waves-effect waves-light btn-dark mt-2 btn-cancel" disabled="true">
                    <b>{{getAccountStatus(account.accountStatus)}}</b>
                </button>
            </ng-template>

            <h3 class="mt-4">Alternate Plans</h3>
            <div class="d-flex flex-wrap gap-3">
                <ng-container *ngFor="let plan of plans">
                    <mya-plan-card [plan]="plan" [currentPlanId]="account.mentoringPlanId"
                        *ngIf="account && (plan.code !== 'OH' || plan.id === account.mentoringPlanId)">
                    </mya-plan-card>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<mya-cancel-plan-confirmation-modal [account]="account"></mya-cancel-plan-confirmation-modal>
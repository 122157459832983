import { AfterContentChecked, AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Account, AppointmentTypeEnum, ExternalUser, InternalUser, SmartCode, SmartTypes, getUserType } from '@mya/models';
import { AccountService } from '../../services/account.service';
import { AuthenticationService } from '../../services/authentication.service';
import { NavigationEnd, Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { ModalConstant } from '../../common/constants/modal.constant';
import { ChangePasswordModalComponent } from '../../components/shared/modals/change-password-modal/change-password-modal.component';
import { SmartTypesService } from '../../services/smart-types.service';
import { v4 as uuid } from 'uuid';
import { LoaderService } from '../../services/loader.service';
import { AppointmentService } from '../../services/appointment.service';
import { initializeLightTheme } from '@mya/xtreme-template';
import { Subscription as Subs } from 'rxjs';
import { ReferenceDataService } from '../../services/reference-data.service';

@Component({
  selector: 'mya-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  @ViewChild(ChangePasswordModalComponent) ChangePasswordModalComponent!: ChangePasswordModalComponent;
  subscriptions: Subs[] = [];
  linkedAccount: Account | null = null;
  accountCreator: ExternalUser | null = null;
  mentor: InternalUser | null = null;
  url: string | null = null;
  userTypes: Record<string, SmartCode> = {};

  get initials() {
    return `${this.accountCreator?.firstName.charAt(0)}${this.accountCreator?.lastName.charAt(0)}`.toUpperCase();
  }

  constructor(public router: Router,
    private authenticationService: AuthenticationService,
    private accountService: AccountService,
    private referenceDataService: ReferenceDataService,
    private modalService: ModalService,
    private appointmentService: AppointmentService,
    private loaderService: LoaderService,
    private smartTypesService: SmartTypesService) {
    this.url = router.url;
    this.subscriptions.push(router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.url = value.url;
      }
    }));
  }

  ngAfterViewInit(): void {
    initializeLightTheme();
  }

  ngAfterContentChecked(): void {
    this.modalService.initialize(ModalConstant.CHANGE_PASSWORD_MODAL);
  }

  ngOnInit(): void {
    this.accountService.getAccount();
    this.accountService.getAccountCreator();
    this.accountService.getMentee();
    this.accountService.getMentor();
    this.referenceDataService.fetchReferenceData();
    this.appointmentService.getAppointmentsByAccount();
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(account => {
      this.linkedAccount = account;
    }));
    this.subscriptions.push(this.accountService.AccountCreator$.subscribe(user => {
      this.accountCreator = user;
    }));
    this.subscriptions.push(this.accountService.Mentor$.subscribe(user => {
      this.mentor = user;
    }));
    this.subscriptions.push(this.appointmentService.Appointments$.subscribe(appointments => {
      if (appointments && appointments.length > 0) {
        if ((appointments.find(i => i.appointmentTypeId == AppointmentTypeEnum.Mentor) ?? null) == null) {
          this.router.navigate(['/onboarding']);
        }
      }
    }));

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.UserType, loaderIdentifier).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier);
      smartCodes?.forEach(smartCode => {
        this.userTypes[smartCode.id] = smartCode;
      });
    }));
  }

  getUserType(user: ExternalUser | null) {
    return getUserType(user, this.userTypes);
  }

  changePassword() {
    this.modalService.show(ModalConstant.CHANGE_PASSWORD_MODAL);
    this.ChangePasswordModalComponent.passwordReset();
  }

  logout() {
    this.authenticationService.logout();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

<div class="container-fluid px-5 pt-5 h-100 mya-payment overflow-y-auto">
    <div class="row h-100">
        <div class="col-12 mb-3 d-flex">
            <h1>Payments</h1>
        </div>
        <div class="col-12 p-5 payment-container">
            <div class="d-flex">
                <div class="payment-box">
                    <h3>Payment Method</h3>
                    <div>
                        <mya-payment-method-card *ngIf="editMode === false"
                            (updatePaymentMethod)="updatePaymentMethod()"></mya-payment-method-card>
                        <ng-container *ngIf="editMode === true && elementsOptions.clientSecret">
                            <ngx-stripe-payment [appearance]="appearance"
                                [clientSecret]="elementsOptions.clientSecret"></ngx-stripe-payment>

                            <div class="row">
                                <div class="col-md-6">
                                    <button type="button"
                                        class="btn waves-effect waves-light btn-outline-primary w-100 mt-3 mb-4 btn-cancel"
                                        (click)="editMode = false">
                                        <b>Cancel</b>
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button type="button" (click)="onUpdateCard()"
                                        class="btn waves-effect waves-light btn-primary w-100 mt-3 mb-4 btn-update">
                                        <b>Update Payment Method</b>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="d-flex flex-wrap gap-3 ms-5">
                    <mya-plan-card [isCurrentPlanCard]="true" [plan]="currentPlan"></mya-plan-card>
                </div>
            </div>
        </div>
    </div>
</div>
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { LoaderService } from './loader.service';
import { ChargeSubscriptionRequest, AddSupportUserToAccountResult, GetActiveSubscriptionByAccountResult } from '@mya/models';
import { Observable, catchError } from 'rxjs';
declare const toastr: any;

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  baseApiUrl = '';

  constructor(private http: HttpClient, private loaderService: LoaderService,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.subscription}api/subscription`
  }

  chargeSubscription(request: ChargeSubscriptionRequest, loaderIdentifier: string): Observable<AddSupportUserToAccountResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post<AddSupportUserToAccountResult>(`${this.baseApiUrl}/charge-subscription`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        toastr.error('An error has occured');
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  getActiveSubscriptionByAccount(): Observable<GetActiveSubscriptionByAccountResult> {
    return this.http.get<GetActiveSubscriptionByAccountResult>(`${this.baseApiUrl}/get-active-subscription-by-account`);
  }
}

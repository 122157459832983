import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentDatepickerComponent } from './components/appointment-datepicker/appointment-datepicker.component';
import { HttpClientModule } from '@angular/common/http';
import {
  NgbDatepickerModule,
  NgbCollapseModule,
} from '@ng-bootstrap/ng-bootstrap';
import { AppointmentDatepickerNavigatorComponent } from './components/appointment-datepicker-navigator/appointment-datepicker-navigator.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgbDatepickerModule,
    NgbCollapseModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [
    AppointmentDatepickerComponent,
    AppointmentDatepickerNavigatorComponent,
  ],
  exports: [
    AppointmentDatepickerComponent,
    AppointmentDatepickerNavigatorComponent,
  ],
})
export class BookingSharedModule {}

import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Account, ChargeSubscriptionRequest, ExternalUser, ExternalUserClaimTypes, InternalUser, PlanPricing, SmartCode, SmartTypes, getMentorType } from '@mya/models';
import { Appearance, StripeElementsOptions } from '@stripe/stripe-js';
import { AccountService } from '../../../../services/account.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { JwtService } from '../../../../services/jwt.service';
import { LoaderService } from '../../../../services/loader.service';
import { PaymentService } from '../../../../services/payment.service';
import { PricingService } from '../../../../services/pricing.service';
import { SmartTypesService } from '../../../../services/smart-types.service';
import { SubscriptionService } from '../../../../services/subscription.service';
import { v4 as uuid } from 'uuid';
import { StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit, OnDestroy {
  @ViewChild(StripePaymentElementComponent) paymentElement: StripePaymentElementComponent | undefined;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  subscriptions: Subs[] = [];
  plans: SmartCode[] = [];
  planPricings: PlanPricing[] = [];
  linkedAccount: Account | null = null;
  mentor: InternalUser | null = null;
  email: string | null = null;
  paymentInProgress = false;
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };
  appearance: Appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      colorPrimary: '#673ab7',
    },
  };

  get selectedPlan() {
    return this.plans.find(i => i.id == this.linkedAccount?.mentoringPlanId) ?? null;
  }

  get planPricing() {
    return this.planPricings.find(i => i.mentorType == getMentorType(this.mentor ?? null) && i.mentoringPlanId == this.linkedAccount?.mentoringPlanId) ?? null;
  }

  form = this.formBuilder.group({
    name: ['', [Validators.required]]
  })

  constructor(private formBuilder: FormBuilder,
    private accountService: AccountService,
    private authenticationService: AuthenticationService,
    private pricingService: PricingService,
    private paymentService: PaymentService,
    private jwtService: JwtService,
    private stripeService: StripeService,
    private smartTypesService: SmartTypesService,
    private loaderService: LoaderService,
    private subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    const token = this.authenticationService.getToken();
    if (token) {
      const decodedToken: any = this.jwtService.DecodeToken(token);
      const name = `${decodedToken[ExternalUserClaimTypes.GivenName]} ${decodedToken[ExternalUserClaimTypes.Surname]}`
      this.email = decodedToken[ExternalUserClaimTypes.Email];

      this.form.patchValue({
        name: name
      });
    }
    this.subscriptions.push(this.accountService.LinkedAccount$.subscribe(i => {
      this.linkedAccount = i;
    }));
    this.subscriptions.push(this.accountService.Mentor$.subscribe(user => {
      this.mentor = user;
    }));
    this.subscriptions.push(this.pricingService.PlanPricing$.subscribe(i => {
      this.planPricings = i;
    }));
    const loaderIdentifier = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.MentoringPlan, loaderIdentifier).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier);
      smartCodes?.forEach(smartCode => {
        if (smartCode.code !== 'OP') {
          this.plans.push(smartCode);
        }
      });
    }));

    this.createSetupIntent();
  }

  createSetupIntent() {
    if (this.elementsOptions.clientSecret) return;

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.paymentService.createSetupIntent(loaderIdentifier).subscribe((response) => {
      if (this.elementsOptions && response.clientSecret) {
        this.elementsOptions.clientSecret = response.clientSecret;
      }
      this.loaderService.hide(loaderIdentifier);
    }));
  }

  back() {
    this.previous.emit();
  }

  submit() {
    if (this.paymentInProgress || this.email == null || this.paymentElement == undefined || !this.form.valid
      || this.form.controls.name.value == null || this.linkedAccount?.id == null || this.planPricing?.id == null)
      return;

    this.paymentInProgress = true;
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);

    this.subscriptions.push(this.stripeService
      .confirmSetup({
        elements: this.paymentElement.elements,
        confirmParams: {
          return_url: `${location.origin}/support/payment-redirect`,
          payment_method_data: {
            billing_details: {
              name: this.form.controls.name.value,
              email: this.email
            },
          },
        },
        redirect: 'if_required',
      })
      .subscribe({
        next: (result) => {
          this.paymentInProgress = false;
          this.loaderService.hide(loaderIdentifier);
          if (result.error) {
            toastr.error(`${result.error.message}`);
          } else if (result.setupIntent.status === 'succeeded') {
            this.chargeSubscription();
          }
        },
        error: (err) => {
          this.paymentInProgress = false;
          this.loaderService.hide(loaderIdentifier);
          toastr.error(`${err.message || 'Unknown Error'}`);
        },
      }));
  }

  chargeSubscription() {
    if (this.linkedAccount?.id && this.linkedAccount && this.planPricing?.id) {
      const request: ChargeSubscriptionRequest = {
        planPricingId: this.planPricing.id
      };

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.subscriptionService.chargeSubscription(request, loaderIdentifier).subscribe({
        next: () => {
          this.loaderService.hide(loaderIdentifier);
          toastr.success('Payment Successful');
          this.next.emit();
        },
        error: (err) => {
          toastr.error(`${err.message || 'Unknown Error'}`);
          this.loaderService.hide(loaderIdentifier);
        },
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentRedirectComponent } from '../components/pages/payment-redirect/payment-redirect.component';
import { AuthGuard } from '../guards/auth.guard';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { RoleGuard } from '../guards/role.guard';
import { LoginComponent } from '../components/pages/login/login.component';
import { NotesComponent } from '../components/pages/notes/notes.component';
import { ForgetPasswordComponent } from '../components/pages/forget-password/forget-password.component';
import { PasswordResetComponent } from '../components/pages/password-reset/password-reset.component';
import { AppointmentsComponent } from '../components/pages/appointments/appointments.component';
import { SubscriptionComponent } from '../components/pages/subscription/subscription.component';
import { PaymentComponent } from '../components/pages/payment/payment.component';
import { OnboardingWizardComponent } from '../components/pages/onboarding-wizard/onboarding-wizard.component';
import { SetPasswordComponent } from '../components/pages/set-password/set-password.component';
import { TenantSelectionComponent } from '../components/pages/tenant-selection/tenant-selection.component';
import { OnboardingGuard } from '../guards/onboarding.guard';
import { PortalGuard } from '../guards/portal.guard';
import { DateCheckerComponent } from '../components/pages/date-checker/date-checker.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/onboarding',
    pathMatch: 'full'
  },
  { path: 'date-checker', component: DateCheckerComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/:tenant/:plan', component: LoginComponent },
  { path: 'payment-redirect', component: PaymentRedirectComponent },
  { path: 'forgot-password', component: ForgetPasswordComponent },
  { path: 'password-reset/:id', component: PasswordResetComponent },
  { path: 'set-password/:id/:tenant/:plan', component: SetPasswordComponent },
  { path: 'tenant-selection', component: TenantSelectionComponent, canActivate: [AuthGuard, RoleGuard] },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: 'appointments', component: AppointmentsComponent, canActivate: [AuthGuard, RoleGuard, PortalGuard] },
      { path: 'subscription', component: SubscriptionComponent, canActivate: [AuthGuard, RoleGuard, PortalGuard] },
      { path: 'notes', component: NotesComponent, canActivate: [AuthGuard, RoleGuard, PortalGuard] },
      { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard, RoleGuard, PortalGuard] },
    ]
  },
  { path: 'onboarding', component: OnboardingWizardComponent, canActivate: [AuthGuard, RoleGuard, OnboardingGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }

import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { Account, CancelCurrentPlanByUserRequest, ChangePlanForAccountRequest, ExternalUser, GetAccountCreatorForAccountResult, GetAccountResult, GetMenteeForAccountResult, GetMenteeNameResult, GetMentorForAccountResult, InternalUser } from '@mya/models';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { LoaderService } from './loader.service';
import { v4 as uuid } from 'uuid';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Injectable({
  providedIn: 'root'
})
export class AccountService implements OnDestroy {
  baseApiUrl = '';
  fetchingAccount = false;
  subscriptions: Subs[] = [];

  private readonly linkedAccount = new BehaviorSubject<Account | null>(null);
  public readonly LinkedAccount$: Observable<Account | null> = this.linkedAccount;

  private readonly accountCreator = new BehaviorSubject<ExternalUser | null>(null);
  public readonly AccountCreator$: Observable<ExternalUser | null> = this.accountCreator;

  private readonly mentee = new BehaviorSubject<ExternalUser | null>(null);
  public readonly Mentee$: Observable<ExternalUser | null> = this.mentee;

  private readonly mentor = new BehaviorSubject<InternalUser | null>(null);
  public readonly Mentor$: Observable<InternalUser | null> = this.mentor;

  constructor(private http: HttpClient, private loaderService: LoaderService,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.account}api/account`
  }

  public getAccount(refresh: boolean = false) {

    if (this.linkedAccount.value != null && !refresh && this.fetchingAccount)
      return;

    this.fetchingAccount = true;
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetAccountResult>(`${this.baseApiUrl}/get-account`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        this.fetchingAccount = false;
        throw error;
      }))
      .subscribe((response) => {
        this.linkedAccount.next(response.account);
        this.loaderService.hide(loaderIdentifier);
        this.fetchingAccount = false;
      }));
  }

  public changePlanForAccount(request: ChangePlanForAccountRequest, loaderIdentifier: string): Observable<any> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post(`${this.baseApiUrl}/change-plan-for-account`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        if (error.status == HttpStatusCode.BadRequest) {
          toastr.error(error.message);
        }
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  cancelCurrentPlanByAccount(request: CancelCurrentPlanByUserRequest, loaderIdentifier: string) {
    this.loaderService.show(loaderIdentifier);
    return this.http.post(`${this.baseApiUrl}/cancel-current-plan-by-account`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        toastr.error('An error has occured');
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  getAccountCreator() {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetAccountCreatorForAccountResult>(`${this.baseApiUrl}/get-account-creator-for-account`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe((response) => {
        this.accountCreator.next(response.user);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  getMentee() {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetMenteeForAccountResult>(`${this.baseApiUrl}/get-mentee-for-account`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe((response) => {
        this.mentee.next(response.user);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  getMentor() {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetMentorForAccountResult>(`${this.baseApiUrl}/get-mentor-for-account`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe((response) => {
        this.mentor.next(response.user);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  getMenteeNameByAccount(accountId: string, loaderIdentifier: string) {
    this.loaderService.show(loaderIdentifier);
    return this.http.get<GetMenteeNameResult>(`${this.baseApiUrl}/get-mentee-name-by-account/${accountId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        toastr.error('An error has occured');
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

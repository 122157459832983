export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

export const DayOfWeekLookup: Record<DayOfWeek, string> = {
    [DayOfWeek.Sunday]: "sunday",
    [DayOfWeek.Monday]: "monday",
    [DayOfWeek.Tuesday]: "tuesday",
    [DayOfWeek.Wednesday]: "wednesday",
    [DayOfWeek.Thursday]: "thursday",
    [DayOfWeek.Friday]: "friday",
    [DayOfWeek.Saturday]: "saturday",
};